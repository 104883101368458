/* eslint-disable no-restricted-imports */

import Chip from "@material-ui/core/Chip";
import type { ChipProps } from "@material-ui/core/Chip";
import React from "react";
import { useProjectContext } from "~/areas/projects/context";
import type { GitReference } from "~/client/resources/versionControlledResource";
import { withTheme } from "~/components/Theme";
import { GitBranchIcon, GitCommitIcon } from "~/primitiveComponents/dataDisplay/Icon";
import GitRef, { GitCommit } from "../../GitRefDropDown/GitRef";

export const GitRefChip: React.FC<{ vcsRef: GitReference; showCommit?: boolean } & ChipProps> = ({ vcsRef, showCommit = true, ...chipProps }) => {
    const projectContext = useProjectContext();
    const isDefaultBranch = vcsRef.GitRef == projectContext?.state.branch?.Name;
    // Material Chips don't really expect an icon in the label, so we need to bend the styling to our will
    const iconInLabelStyles = {
        verticalAlign: "sub",
        marginRight: "4px",
    };

    const gitRefIcon = <GitBranchIcon />;
    const gitRefText = vcsRef.GitRef && <GitRef key="ref" value={vcsRef.GitRef} />;
    const commitIcon = <GitCommitIcon key="icon" className="MuiChip-icon MuiChip-iconSmall" style={iconInLabelStyles} />;
    const commitText = vcsRef.GitCommit && <GitCommit key="value" value={vcsRef.GitCommit} />;

    const label = showCommit ? [gitRefText, commitIcon, commitText] : gitRefText;

    return withTheme((theme) => {
        const chipStyles = {
            backgroundColor: theme.subtleButtonBackground,
        };
        // TODO: add background colours for default branch once we know what the dark theme colours are
        const defaultBranchChipStyles = chipStyles; // e.g. { backgroundColour: theme.whatAreWeCallingThisAndWhereElseDoWeUseIt }
        const styles = isDefaultBranch ? defaultBranchChipStyles : chipStyles;

        return (vcsRef && vcsRef.GitRef && <Chip icon={gitRefIcon} label={label} style={styles} {...chipProps} size="small"></Chip>) || null;
    });
};
