/* eslint-disable no-restricted-imports */

import { Drawer, makeStyles } from "@material-ui/core";
import React from "react";
import Environment from "~/environment";
import { client } from "../../clientInstance";
import type { DoBusyTask } from "../DataBaseComponent";
import { DataBaseComponent } from "../DataBaseComponent";
import { DevToolbar } from "./DevToolbar";
import { useDevToolsState } from "./DevToolsContext";
import { DirtyStateDetail } from "./DirtyStateTracking";
import { MiniProfilerPanel } from "./MiniProfiler";
import { useProfilerCaching } from "./MiniProfiler/ProfilerCache";
import { DevToolsOverview } from "./OverviewTab";
import { useDrawerResizableHeight } from "./useDrawerResizableHeight";

interface DevDrawerProps extends BusyTaskProps {
    open: boolean;
    height?: number;
    onRequestClose: () => void;
    onToggleFullscreen: () => void;
    fullscreen: boolean;
}

interface BusyTaskProps {
    doBusyTask: DoBusyTask;
    isBusy: boolean;
}

const useDrawerStyles = makeStyles({
    paper: (props: DevDrawerProps) => ({
        height: props.open ? (props.fullscreen ? "100%" : props.height ? `${props.height}px` : "500px") : "0",
    }),
});

const useTabPanelStyles = makeStyles({
    root: {
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    },
});

const TabPanel: React.FC<{ selectedTab: string; value: string }> = (props) => {
    const styles = useTabPanelStyles();
    if (props.selectedTab !== props.value) {
        return null;
    }
    return <div className={styles.root}>{props.selectedTab === props.value && props.children}</div>;
};

type DragHandleProps = {
    onMouseDown: React.MouseEventHandler<HTMLDivElement>;
};

const useDragHandleStyles = makeStyles({
    root: {
        height: "5px",
        cursor: "ns-resize",
    },
});

const DragHandle: React.FC<DragHandleProps> = (props) => {
    const styles = useDragHandleStyles(props);
    return <div onMouseDown={props.onMouseDown} className={styles.root} />;
};

class DevDrawerDataLoader extends DataBaseComponent<Omit<DevDrawerProps, keyof BusyTaskProps>> {
    state = {
        busy: undefined,
    };

    render() {
        return <DevDrawer {...this.props} doBusyTask={this.doBusyTask} isBusy={!!this.state.busy} />;
    }
}

const DevDrawer: React.FC<DevDrawerProps> = (props) => {
    const { height, handleMouseDown } = useDrawerResizableHeight(props.height);
    const state = useDevToolsState();
    const { pagedRequests, actions, isCapturing } = useProfilerCaching(client, props.doBusyTask);

    const drawerClasses = useDrawerStyles({ ...props, height });
    const tabs: Array<{ label: string; value: string }> = [];
    tabs.push({
        value: "Dev Tools",
        label: "Dev Tools",
    });
    if (Environment.isInDevelopmentMode()) {
        // at the moment, we've disabled it in production as we haven't yet done the thinking
        // around how to secure the endpoint(s) and whether this will expose any sensitive data
        // like what mini profiler gives us? want to see it in production?
        // help us solve these questions so we get the benefits on customer instances
        tabs.push({
            value: "Profiler",
            label: "Profiler",
        });
    }
    const [selectedTab, setTab] = React.useState("Dev Tools");

    const tabPanels: Array<React.ReactNode> = [];
    if (state) {
        for (const key of Array.from(Object.keys(state.dirty))) {
            tabs.push({
                value: key,
                label: `Dirty state: ${key}`,
            });
            tabPanels.push(
                <TabPanel key={key} value={key} selectedTab={selectedTab}>
                    <DirtyStateDetail name={key} />
                </TabPanel>
            );
        }

        for (const [key, value] of Array.from(state.devToolsTabs.tabs.entries())) {
            tabs.push({
                value: key,
                label: key,
            });
            tabPanels.push(
                <TabPanel key={key} value={key} selectedTab={selectedTab}>
                    <>{value}</>
                </TabPanel>
            );
        }
    }

    if (!tabs.some((p) => p.value === selectedTab)) {
        //prevent console warnings if the currently selected tab is not actually visible anymore
        setTab(tabs[0].value);
    }

    return (
        <Drawer open={props.open} anchor={"bottom"} variant={"permanent"} classes={drawerClasses}>
            {props.open && (
                <React.Fragment>
                    <DragHandle onMouseDown={handleMouseDown} />
                    <DevToolbar tabs={tabs} onSelectTab={setTab} selectedTab={selectedTab} onRequestClose={props.onRequestClose} onToggleFullscreen={props.onToggleFullscreen} isFullScreen={props.fullscreen} />
                    <TabPanel value={"Dev Tools"} selectedTab={selectedTab}>
                        <DevToolsOverview />
                    </TabPanel>
                    <TabPanel selectedTab={selectedTab} value={"Profiler"}>
                        <MiniProfilerPanel pageSessions={pagedRequests} actions={actions} enabled={isCapturing} />
                    </TabPanel>
                    {tabPanels}
                </React.Fragment>
            )}
        </Drawer>
    );
};

export { DevDrawerDataLoader as DevDrawer };
