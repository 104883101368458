import React from "react";
import { HostingEnvironment } from "~/client/resources";
import { repository } from "~/clientInstance";
import Environment from "~/environment";
import { exhaustiveCheck } from "~/utils/exhaustiveCheck";
import { AmplitudeSession } from "./Amplitude";
import { NullSession } from "./NullSession";
import { UndefinedSession } from "./UndefinedSession";

interface ActionField {
    "Action Type": string;
}

interface DocumentUrlField {
    "Document URL"?: string;
}

interface DurationField {
    "Duration (ms)": string;
}

interface ErrorCodeField {
    "Error Code"?: string;
}

interface ErrorMessageField {
    "Error Messages"?: string;
}

interface LinkLocationField {
    "Link Location": string;
}

interface LinkLabelField {
    "Link Label": string;
}

interface OnboardingField {
    "Onboarding Type": string;
}

interface PageAreaField {
    "Page Area": string;
}

interface PageField {
    "Page Name": string;
}

interface ResourceField {
    "Resource Type": string;
}

interface StatusField {
    Status: string;
}

interface StepCategoryField {
    "Step Category": string;
}

interface StepTemplateField {
    "Step Template"?: string;
}

interface TargetField {
    "Target Type"?: string;
}

interface isCaCenabledField {
    "Is CaC Enabled"?: string;
}

interface isDefaultBranchField {
    "Is Default Branch"?: string;
}
interface commitMessageField {
    "Commit Message"?: string;
}

interface ActionResultField {
    Status?: string;
}

export type ViewFields = ResourceField;

// Fields for an event performed
export interface ActionedFields extends ActionField, PageAreaField, PageField, ResourceField, TargetField, StepTemplateField, ActionResultField, isDefaultBranchField, commitMessageField {}

// Fields indicating an Action undertaken
export interface ActionFields extends ActionField, DurationField, PageAreaField, PageField, ResourceField, StatusField, TargetField, StepTemplateField, isCaCenabledField, isDefaultBranchField, commitMessageField {}

export interface ActionResultFields extends ActionField, ActionResultField {}

export interface LinkFields extends DocumentUrlField, LinkLabelField, LinkLocationField, PageAreaField, PageField {}

export interface StepFields extends ActionField, PageAreaField, PageField, ResourceField, StepCategoryField, StepTemplateField {}

export interface StatusFields extends ActionField, ErrorCodeField, ErrorMessageField, ResourceField, StatusField {}

export interface OnboardingFields extends OnboardingField, PageAreaField, PageField, LinkLabelField, LinkLocationField {}

export interface OtherActionFields extends PageAreaField, PageField {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignInFields {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignOutFields {}

export type AnalyticFields = ActionFields | ActionResultFields | ActionedFields | LinkFields | SignInFields | SignOutFields | StepFields | StatusFields | OnboardingFields | ViewFields | OtherActionFields;

export enum AnalyticsEnvironment {
    Undefined,
    ProductionCloud,
    Production,
    Development,
}

export interface AnalyticSession {
    track(name: string, fields: AnalyticFields): void;
    end(): void;
    readonly environment: AnalyticsEnvironment;
}

export const DefaultSession: AnalyticSession = new UndefinedSession();

export async function getAnalyticSession(installationId: string, userId: string, userName: string, emailAddress: string | undefined): Promise<AnalyticSession> {
    const mode = await calcEnvironment();

    switch (mode) {
        case AnalyticsEnvironment.Development:
        case AnalyticsEnvironment.ProductionCloud:
            return AmplitudeSession.Start(mode, installationId, userId, userName, emailAddress);

        case AnalyticsEnvironment.Production:
            return new NullSession(mode);

        case AnalyticsEnvironment.Undefined:
            return DefaultSession;
    }
    exhaustiveCheck(mode, "AnalyticsEnvironment was not handled");
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Analytic Session Context
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const AnalyticSessionContext = React.createContext<AnalyticSession>(DefaultSession);

interface AnalyticSessionProps {
    session: AnalyticSession;
}

export function AnalyticSessionProvider(props: React.PropsWithChildren<AnalyticSessionProps>) {
    return <AnalyticSessionContext.Provider value={props.session}>{props.children}</AnalyticSessionContext.Provider>;
}

export function useAnalyticSession(): AnalyticSession {
    const session = React.useContext(AnalyticSessionContext);

    return session;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Helper functions
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const calcEnvironment = async (): Promise<AnalyticsEnvironment> => {
    if (Environment.isInDevelopmentMode()) {
        return AnalyticsEnvironment.Development;
    }

    const licenseStatus = await repository.Licenses.getCurrentStatus();
    return licenseStatus.HostingEnvironment === HostingEnvironment.OctopusCloud ? AnalyticsEnvironment.ProductionCloud : AnalyticsEnvironment.Production;
};
