import * as React from "react";
import type { AzureWebAppEndpointResource, AccountResource } from "~/client/resources";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { AzureWebApp as AzureWebAppImage } from "~/components/Images/MachineSettings/AzureWebApp";
import { ExpandableFormSection, Summary } from "~/components/form";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import Select from "~/primitiveComponents/form/Select/Select";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
import type { WorkerPoolResource } from "../../../../../client/resources/index";
import { AccountType } from "../../../../../client/resources/index";
import AzureCategory from "./AzureCategoryDefinition";
import AzureWebAppAndResourceGroupSelector from "./AzureWebAppAndResourceGroupSelector";
import AzureWebAppSlotSelector from "./AzureWebAppSlotSelector";
import EndpointCard from "./EndpointCard";
import type { BuiltInEndpointRegistration } from "./endpointRegistry";
import { CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
const styles = require("./styles.less");

interface AzureWebAppEndpointProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | boolean;
    endpoint: AzureWebAppEndpointResource;
    accounts: AccountResource[];
    workerPools: WorkerPoolResource[];
    refreshAccounts: () => Promise<{}>;
    onChange(newValue: AzureWebAppEndpointResource): void;
    getFieldError(field: string): string;
}

//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AzureWebAppEndpointState {}

class AzureWebAppEndpoint extends React.Component<AzureWebAppEndpointProps, AzureWebAppEndpointState> {
    constructor(props: AzureWebAppEndpointProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <ExpandableFormSection
                    errorKey="Account"
                    title="Account"
                    focusOnExpandAll
                    summary={CommonSummaryHelper.resourceSummary(this.props.endpoint.AccountId, this.props.accounts, "account")}
                    help="Select the account to use for the connection."
                >
                    <AccountSelect
                        onRequestRefresh={this.props.refreshAccounts}
                        value={this.props.endpoint.AccountId}
                        type={[AccountType.AzureServicePrincipal]}
                        allowClear={true}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.AccountId = x;
                            this.props.onChange(endpoint);
                        }}
                        items={this.props.accounts}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey={"WebAppName"}
                    title="Azure Web App"
                    summary={this.props.endpoint.WebAppName ? Summary.summary(this.props.endpoint.WebAppName) : Summary.placeholder("No Web App selected")}
                    help="Select the Azure Web App."
                >
                    <AzureWebAppAndResourceGroupSelector
                        accountId={this.props.endpoint.AccountId}
                        doBusyTask={this.props.doBusyTask}
                        webAppName={this.props.endpoint.WebAppName}
                        webAppNameError=""
                        onWebAppNameChanged={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.WebAppName = x;
                            this.props.onChange(endpoint);
                        }}
                        onResourceGroupChanged={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.ResourceGroupName = x;
                            this.props.onChange(endpoint);
                        }}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey={"WebAppSlotName"}
                    title={"Azure Web App Slot"}
                    summary={this.props.endpoint.WebAppSlotName ? Summary.summary(this.props.endpoint.WebAppSlotName) : Summary.placeholder("No Web App Slot selected or none available")}
                    help={"Select the Azure Web App Slot (optional)."}
                >
                    <AzureWebAppSlotSelector
                        accountId={this.props.endpoint.AccountId}
                        webAppName={this.props.endpoint.WebAppName}
                        resourceGroupName={this.props.endpoint.ResourceGroupName}
                        webAppSlotName={this.props.endpoint.WebAppSlotName}
                        doBusyTask={this.props.doBusyTask}
                        webAppSlotNameError=""
                        onWebAppSlotNameChanged={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.WebAppSlotName = x;
                            this.props.onChange(endpoint);
                        }}
                    />
                </ExpandableFormSection>

                {this.props.workerPools.length > 1 && (
                    <ExpandableFormSection
                        errorKey={"DefaultWorkerPool"}
                        title="Worker Pool"
                        summary={this.props.endpoint.DefaultWorkerPoolId ? CommonSummaryHelper.resourceSummary(this.props.endpoint.DefaultWorkerPoolId, this.props.workerPools, "worker pool") : Summary.placeholder("No pool selected - default pool")}
                        help="Select a default pool for this target (optional)."
                    >
                        <Select
                            label={"Select a default pool"}
                            items={this.props.workerPools.map((e) => ({ value: e.Id, text: e.Name }))}
                            value={this.props.endpoint.DefaultWorkerPoolId}
                            allowFilter={true}
                            allowClear={true}
                            onChange={(x) => this.props.onChange({ ...this.props.endpoint, DefaultWorkerPoolId: x })}
                            sortItems={false}
                        />
                    </ExpandableFormSection>
                )}
            </div>
        );
    }
}

export default AzureWebAppEndpoint;

const azureWebAppEndpointRegistration: BuiltInEndpointRegistration = {
    key: EndpointRegistrationKey.AzureWebApp,
    displayOrder: 10,
    categories: [AzureCategory],
    name: "Azure Web App",
    communicationStyle: CommunicationStyle.AzureWebApp,
    renderCard: ({ registration, category, onNavigate }) => (
        <EndpointCard logo={<AzureWebAppImage className={styles.centreThumbnail} title={registration.name} />} registrationName={registration.name} description="Connect to an existing Azure Web App." onNavigate={onNavigate} />
    ),
};

export { azureWebAppEndpointRegistration };
