import { MenuItem } from "@material-ui/core";
import cn from "classnames";
import type { PropsWithChildren } from "react";
import React from "react";
import styles from "./MenuItemInformation.less";

interface MenuItemInformationProps {
    compact?: boolean;
}

export function MenuItemInformation({ children, compact }: PropsWithChildren<MenuItemInformationProps>) {
    const classes = cn(styles.information, compact ? styles.compact : styles.overrideMinHeightMediaQueryFromMaterialUI);
    return (
        <MenuItem component={"div"} role={"none"} disabled={true} className={classes}>
            {children}
        </MenuItem>
    );
}
