/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { range } from "lodash";
import * as React from "react";
import type { TeamResource, InvitationResource } from "~/client/resources";
import Permission from "~/client/resources/permission";
import { client, repository } from "~/clientInstance";
import { ActionButton, ActionButtonType } from "~/components/Button";
import ComponentRow from "~/components/ComponentRow/ComponentRow";
import CopyToClipboard from "~/components/CopyToClipboardButton/CopyToClipboardButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { SimpleList } from "~/components/List";
import { TeamMultiSelect } from "~/components/MultiSelect/TeamMultiSelect";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import SectionNote from "~/components/SectionNote/SectionNote";
import { Text } from "~/components/form";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
const styles = require("./style.less");

interface UserInviteState extends DataBaseComponentState {
    availableTeams: TeamResource[];
    teams: string[];
    quantity: number;
    invites?: InvitationResource[];
    anyAuthenticationProvidersSupportPasswordManagement?: boolean;
}

class InviteList extends SimpleList<InvitationResource> {}

export default class UserInvite extends DataBaseComponent<{}, UserInviteState> {
    tenantId: string = null!;
    constructor(props: {}) {
        super(props);
        this.state = {
            availableTeams: [],
            teams: [],
            quantity: 1,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [availableTeams, document] = await Promise.all([repository.Teams.all(), repository.Authentication.get()]);

            this.setState({
                availableTeams,
                anyAuthenticationProvidersSupportPasswordManagement: document.AnyAuthenticationProvidersSupportPasswordManagement,
            });
        });
    }

    createInvites = async () => {
        await this.doBusyTask(async () => {
            // TODO: Need to add a bulk API (comment from old portal, yes we do, but not now)
            const hasSpaceTeam = this.state.teams.map((t) => this.state.availableTeams.find((x) => x.Id === t)).some((t) => t!.SpaceId !== null);
            const invites = await Promise.all(
                range(0, this.state.quantity)
                    //implied rule: any selected teams must all be in the same space or system
                    .map((_) => repository.Invitations.invite(this.state.teams, hasSpaceTeam ? repository.spaceId : null))
            );
            this.setState({
                invites,
            });
        });
    };

    inviteMore = async () => {
        this.setState({
            invites: null!,
            teams: [],
            quantity: 1,
        });
    };

    render() {
        const sectionControl = [
            !this.state.invites && this.state.anyAuthenticationProvidersSupportPasswordManagement && (
                <ActionButton key="CreateAction" type={ActionButtonType.Primary} label={"Create"} busyLabel={"Creating..."} disabled={this.state.busy} onClick={() => this.createInvites()} />
            ),
            this.state.invites && this.state.invites.length > 0 && (
                <ActionButton key="InviteAction" type={ActionButtonType.Primary} label={"Invite more users"} busyLabel={"Setting up..."} disabled={this.state.busy} onClick={() => this.inviteMore()} />
            ),
        ];

        const createInvites = (
            <div>
                <SectionNote>Create invitation codes to allow users to register their own accounts on the Octopus Deploy server. Each code can be used at most once, and all codes will expire 48 hours after creation.</SectionNote>
                <Section>
                    <ComponentRow className={styles.filter}>
                        <TeamMultiSelect items={this.state.availableTeams} onChange={(teams) => this.setState({ teams })} value={this.state.teams} />
                        <Text label="Quantity" value={this.state.quantity.toString()} onChange={(quantity) => this.setState({ quantity: parseInt(quantity, 10) })} min={1} max={1000} type="number" />
                    </ComponentRow>
                </Section>
            </div>
        );

        const inviteRequestWithPermissionChecks = !this.state.invites && (
            <div>
                {/* Yes you need a lot of permissions */}
                <PermissionCheck
                    key="mainTeamCreate"
                    permission={Permission.TeamCreate}
                    alternate={
                        <Section>
                            <Callout type={CalloutType.Information} title={"Permission required"}>
                                The {Permission.TeamCreate} permission is required to invite users
                            </Callout>
                        </Section>
                    }
                >
                    <PermissionCheck
                        key="mainTeamEdit"
                        permission={Permission.TeamEdit}
                        alternate={
                            <Section>
                                <Callout type={CalloutType.Information} title={"Permission required"}>
                                    The {Permission.TeamEdit} permission is required to invite users
                                </Callout>
                            </Section>
                        }
                    >
                        <PermissionCheck
                            key="mainUserInvite"
                            permission={Permission.UserInvite}
                            alternate={
                                <Section>
                                    <Callout type={CalloutType.Information} title={"Permission required"}>
                                        The {Permission.UserInvite} permission is required to invite users
                                    </Callout>
                                </Section>
                            }
                        >
                            {createInvites}
                        </PermissionCheck>
                    </PermissionCheck>
                </PermissionCheck>
            </div>
        );

        const resolveLink = (invite: InvitationResource) => `${client.resolve(invite.Links.Register)}`;
        const anchor = (link: string) => (
            <a href={link} target="_blank" rel={"noopener noreferrer"} onClick={(e) => e.stopPropagation()}>
                {link}
            </a>
        );
        const createLink = (invite: InvitationResource) => (
            <div key={invite.InvitationCode} className={styles.linkWithCopy}>
                <div className={styles.copyAction}>
                    <CopyToClipboard value={resolveLink(invite)} />
                </div>
                {anchor(resolveLink(invite))}
            </div>
        );

        const inviteListing = this.state.invites && this.state.invites.length > 0 && (
            <div>
                <Section> {this.state.invites.length > 1 ? "Please provide the links below to the invited users." : "Please provide the link below to the invited user."}</Section>
                <InviteList items={this.state.invites} onRow={createLink} />
            </div>
        );

        const body =
            this.state.availableTeams.length > 0 &&
            (this.state.anyAuthenticationProvidersSupportPasswordManagement ? (
                <div>
                    {inviteRequestWithPermissionChecks}
                    {inviteListing}
                </div>
            ) : (
                <Section>
                    <Callout title="No Suitable Authentication Providers" type={CalloutType.Warning}>
                        There are no Authentication Providers that support password management.
                    </Callout>
                </Section>
            ));

        const hasTeamEditAndUserInvite = isAllowed({ permission: Permission.TeamEdit }) && isAllowed({ permission: Permission.TeamCreate }) && isAllowed({ permission: Permission.UserInvite });

        return (
            <PaperLayout sectionControl={hasTeamEditAndUserInvite && sectionControl} busy={this.state.busy} errors={this.errors} title="Invite Users">
                {body}
            </PaperLayout>
        );
    }
}
