/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { flatten } from "lodash";
import * as React from "react";
import type { AnalyticStepDispatcher, StepEvent } from "~/analytics/Analytics";
import { Action, useAnalyticStepDispatch } from "~/analytics/Analytics";
import { useOptionalProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import type { ActionTemplateCategoryResource } from "~/client/resources";
import { ActionHandlerCategory, ProcessType } from "~/client/resources";
import type ActionTemplateSearchResource from "~/client/resources/actionTemplateSearchResource";
import { Permission } from "~/client/resources/permission";
import ActionTemplateCard from "~/components/ActionTemplates/ActionTemplateCard";
import ActionTemplateCardList from "~/components/ActionTemplates/ActionTemplateCardList";
import ActionTemplateCategory from "~/components/ActionTemplates/ActionTemplateCategory";
import matchesFilter from "~/components/ActionTemplates/matchesFilter";
import { NavigationButton, NavigationButtonType } from "~/components/Button/NavigationButton";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { hidePrereleaseStepPackages } from "~/utils/FeatureFlags/hidePrereleaseStepPackages";
const styles = require("./InstalledActionTemplateList.less");

interface InstalledActionTemplateListProps {
    templates: ActionTemplateSearchResource[];
    communityTemplates: ActionTemplateSearchResource[];
    categories: ActionTemplateCategoryResource[];
    filter?: string;
    onPostSelectionUrlRequested?: (template: { Type: string; Id: string }) => string;
    onDetailsUrlRequested?: (template: ActionTemplateSearchResource) => string;
    onCategorySelected?: (category: ActionHandlerCategory) => void;
}

interface InstalledActionTemplateListPropsInternal extends InstalledActionTemplateListProps {
    dispatchStep: AnalyticStepDispatcher;
    resource: string;
}

interface InstalledActionTemplateListState {
    categoriesLookup?: Array<{ name: string; templates: ActionTemplateSearchResource[] }>;
    categoryTemplates?: ActionTemplateSearchResource[];
    activeCategory?: ActionHandlerCategory;
}

//eslint-disable-next-line react/no-unsafe
export class InstalledActionTemplateListInternal extends React.Component<InstalledActionTemplateListPropsInternal, InstalledActionTemplateListState> {
    constructor(props: InstalledActionTemplateListPropsInternal) {
        super(props);
        this.state = {
            categoriesLookup: this.categoriseTemplates(props.templates),
            categoryTemplates: [],
            activeCategory: null!,
        };
    }

    categoriseTemplates(templates: ActionTemplateSearchResource[]): Array<{ name: string; templates: ActionTemplateSearchResource[] }> {
        const sortedCategories = this.props.categories.sort((a, b) => {
            return a.DisplayOrder - b.DisplayOrder;
        });
        return sortedCategories
            .filter((x) => !!x) // In case there's any bad data from the API / null categories.
            .map((category) => ({ name: category.Id, templates: templates.filter((t) => t.Categories.includes(category.Id)) }));
    }

    showCategoryTemplates(category: ActionHandlerCategory, templates: ActionTemplateSearchResource[]) {
        this.setState({ activeCategory: category, categoryTemplates: templates }, () => {
            if (this.props.onCategorySelected) {
                this.props.onCategorySelected(category);
            }
        });
    }

    UNSAFE_componentWillUpdate(nextProps: InstalledActionTemplateListProps) {
        if (nextProps.filter !== this.props.filter) {
            // Clear any active selection if we receive new props.
            this.showCategoryTemplates(null!, null!);
            if (this.props.onCategorySelected) {
                this.props.onCategorySelected(null!);
            }
        }
    }

    render() {
        const isFiltering = !!this.props.filter;
        const filteredResults = this.props.templates.filter((at) => matchesFilter(at, this.props.filter!)).filter(hidePrereleaseStepPackages);
        return (
            <div className={styles.container}>
                <React.Fragment>
                    {!isFiltering && (
                        <>
                            <Section sectionHeader={"What type of step do you want to add?"} />
                            <Section className={styles.sectionDivider}>
                                {!isFiltering && this.state.categoriesLookup && this.state.categoriesLookup.length > 0 && (
                                    <ol className={styles.categories}>
                                        <div>
                                            {this.state.categoriesLookup.map((categoryLookup) => {
                                                if (categoryLookup.templates.length === 0 && categoryLookup.name !== ActionHandlerCategory.Community) {
                                                    // For step templates (or bad API data) that may not have any templates installed yet.
                                                    return;
                                                }
                                                const category = this.props.categories.find((c) => c.Id === categoryLookup.name);
                                                const categoryEnum = categoryLookup.name as ActionHandlerCategory;
                                                let isBlurred =
                                                    isFiltering &&
                                                    flatten(filteredResults.map((x) => x.Categories))
                                                        .map((x) => x.toLowerCase())
                                                        .filter((x) => x === category!.Id.toLowerCase()).length === 0;
                                                if (category!.Id === ActionHandlerCategory.Community) {
                                                    // Special case to cater for the Community category (it filters on a different set of templates).
                                                    const filteredCommunityResults = this.props.communityTemplates.filter((at) => matchesFilter(at, this.props.filter!));
                                                    isBlurred =
                                                        isFiltering &&
                                                        flatten(filteredCommunityResults.map((x) => x.Categories))
                                                            .map((x) => x.toLowerCase())
                                                            .filter((x) => x === category!.Id.toLowerCase()).length === 0;
                                                }
                                                return (
                                                    <div key={category!.Id}>
                                                        <ActionTemplateCategory
                                                            category={categoryEnum}
                                                            name={category!.Name}
                                                            active={this.state.activeCategory ? this.state.activeCategory === categoryLookup.name : false}
                                                            isBlurred={isBlurred}
                                                            isSelectable={isFiltering}
                                                            templates={categoryLookup.templates}
                                                            onCategorySelected={() => {
                                                                if (this.state.activeCategory && this.state.activeCategory === categoryLookup.name) {
                                                                    // De-select.
                                                                    this.showCategoryTemplates(null!, null!);
                                                                } else {
                                                                    const ev: StepEvent = {
                                                                        action: Action.Select,
                                                                        resource: this.props.resource,
                                                                        stepCategory: categoryLookup.name,
                                                                        stepTemplate: undefined,
                                                                    };

                                                                    this.showCategoryTemplates(categoryEnum, categoryLookup.templates);
                                                                    // DO NOT SEND THIS EVENT
                                                                    // this.props.dispatchStep("Select Step Category", ev);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </ol>
                                )}
                            </Section>
                        </>
                    )}
                    {!isAllowed({ permission: Permission.ActionTemplateView }) && (
                        <Section className={styles.sectionDivider}>
                            <Callout type={CalloutType.Information} title={"Permission required"}>
                                You can't see custom installed step templates because you don't have <ExternalLink href="UserRoles">{Permission.ActionTemplateView}</ExternalLink> permission.
                            </Callout>
                        </Section>
                    )}
                    {this.state.categoryTemplates && this.state.categoryTemplates.length > 0 && (
                        <TransitionAnimation key={this.state.activeCategory}>
                            <Section sectionHeader={`Installed Step Templates (${this.state.categoryTemplates.length})`} />
                            <ActionTemplateCardList>{this.state.categoryTemplates.map((template) => this.renderSingle(template))}</ActionTemplateCardList>
                        </TransitionAnimation>
                    )}
                </React.Fragment>
                {isFiltering && this.renderFilteredTemplates(filteredResults)}
            </div>
        );
    }

    private renderFilteredTemplates(filteredResults: ActionTemplateSearchResource[]) {
        if (filteredResults.length === 0 && this.props.filter) {
            return <Section sectionHeader={`Installed Step Templates (${filteredResults.length})`}>There are no step templates that match your filter criteria.</Section>;
        }

        return (
            <TransitionAnimation key="filteredSteps">
                <Section sectionHeader={`Installed Step Templates (${filteredResults.length})`} />
                <ActionTemplateCardList>{filteredResults.map((template) => this.renderSingle(template))}</ActionTemplateCardList>
            </TransitionAnimation>
        );
    }

    private renderSingle(template: ActionTemplateSearchResource) {
        const ev: StepEvent = {
            action: Action.Add,
            stepCategory: template.Categories[1] ?? template.Category,
            stepTemplate: template.Name,
            resource: this.props.resource,
        };

        return (
            <ActionTemplateCard
                key={template.Type + template.Id}
                template={template}
                primaryAction={<NavigationButton label="Add" type={NavigationButtonType.Primary} href={this.props.onPostSelectionUrlRequested!(template)} onClick={() => this.props.dispatchStep("Add step template", ev)} />}
                primaryRedirect={this.props.onPostSelectionUrlRequested!(template)}
                secondaryAction={
                    template.HasUpdate && (
                        <InternalLink size={0.75} to={this.props.onDetailsUrlRequested!(template)}>
                            Update available
                        </InternalLink>
                    )
                }
            />
        );
    }
}

export function InstalledActionTemplateList(props: InstalledActionTemplateListProps) {
    const optionalProcessContext = useOptionalProcessContext();
    const processType = optionalProcessContext?.state.processType;
    const resource = !processType ? "Step Template" : processType === ProcessType.Runbook ? "Runbook" : "Deployment Process";
    const dispatchStep = useAnalyticStepDispatch();

    return <InstalledActionTemplateListInternal {...props} resource={resource} dispatchStep={dispatchStep} />;
}
