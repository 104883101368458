/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import * as React from "react";
import type { OnboardingEvent } from "~/analytics/Analytics";
import { OnboardingType, useAnalyticOnboardingDispatch } from "~/analytics/Analytics";
import { SimpleMenu } from "~/primitiveComponents/navigation/Menu/SimpleMenu";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
import type { InternalLinkMenuItem } from "~/primitiveComponents/navigation/MenuItems/SimpleMenuItems";
import type { FontWeight } from "../Navigation/InternalLink/InternalLink";
import InternalLink from "../Navigation/InternalLink/InternalLink";
const styles = require("./SectionStepLink.less");

interface SectionStepLinkProps {
    text: string;
    path: string | undefined | null;
    items: Array<SectionStepChildLink>;
    weight?: FontWeight;
    onNavigating?: () => void;
}

export interface SectionStepChildLink {
    label: string;
    link: string;
}

export function SectionStepLink(props: SectionStepLinkProps) {
    const dispatchOnboard = useAnalyticOnboardingDispatch();

    const handleClick = () => {
        const ev: OnboardingEvent = {
            linkLabel: props.text.toLowerCase(),
            onboardingType: OnboardingType.step,
        };
        dispatchOnboard(`Click ${ev.linkLabel}`, ev);

        props.onNavigating?.();
    };

    if (props.items.length) {
        return <DropdownLink text={props.text} items={props.items} weight={props.weight} onClick={handleClick} />;
    }

    if (props.path) {
        return (
            <InternalLink to={props.path} weight={props.weight} onClick={handleClick}>
                {props.text}
            </InternalLink>
        );
    }

    return <span>{props.text}</span>;
}

interface DropdownLinkProps {
    text: string;
    items: SectionStepChildLink[];
    weight?: FontWeight;
    onClick: () => void;
}

function DropdownLink(props: DropdownLinkProps) {
    const { onClick } = props;
    const [openMenu, menuState, triggerAriaAttributes] = useMenuState();

    const items: InternalLinkMenuItem[] = props.items.map((item) => ({ type: "internal-link", path: item.link, label: item.label, onClick }));
    const linkStyles = props.weight ? { fontWeight: props.weight } : {};

    return (
        <div>
            <SimpleMenu menuState={menuState} accessibleName={props.text} items={items} />
            <span className={styles.lookLikeALink} onClick={openMenu} style={linkStyles} {...triggerAriaAttributes}>
                {props.text}
                {props.items && <em style={{ paddingLeft: "5px" }} className="fa fa-caret-down" />}
            </span>
        </div>
    );
}
