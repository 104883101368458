/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { isEqual } from "lodash";
import * as React from "react";
import PackageRequirementExpander from "~/areas/projects/components/Process/Common/PackageRequirementExpander";
import { PackageRequirement } from "~/client/resources";
import type ActionProperties from "~/client/resources/actionProperties";
import ActionList from "~/components/ActionList";
import { RoleChip } from "~/components/Chips";
import type { Errors } from "~/components/DataBaseComponent";
import { ExpandableContainer } from "~/components/Expandable";
import { useExpandExpanders } from "~/components/Expandable/useExpandExpanders";
import { StepRolling } from "~/components/Images/Process/StepRolling";
import { RoleMultiSelect } from "~/components/MultiSelect/RoleMultiSelect";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout";
import { ExpandableFormSection, Summary, FormSectionHeading, ExpansionButtons } from "~/components/form";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";
import { useProjectContext } from "../../context";
import RunTriggerExpander from "../Process/Common/RunTriggerExpander";
import StartTriggerExpander from "../Process/Common/StartTriggerExpander";
import StepName from "../Process/Common/StepName";
import { processScopedEditPermission, deleteActionAndRedirect, getDeleteProcessMenuItem } from "./Common/CommonProcessHelpers";
import { ErrorsForAction } from "./Common/ErrorsForAction";
import { MaxParallelismOptions } from "./Common/MaxParallelismOptions";
import { WarningsForAction } from "./Common/WarningsForAction";
import { useProcessContext } from "./Contexts/ProcessContext";
import { useProcessErrorSelectors } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessQueryStringContext } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessWarningSelectors } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import type { StoredStep } from "./types";
const styles = require("./ProcessParentStepDetails.less");

export interface ProcessParentStepDetailsProps {
    stepNumber: string;
    step: StoredStep;
    machineRoles: string[];
    isFirstStep: boolean;
    isNew: boolean;
    errors: Errors | undefined;
    currentStepName: string;
    setCurrentStepName: (name: string) => void;
}

const AutoExpandStepErrors: React.FC<{ stepId: string }> = (props) => {
    const expandExpanders = useExpandExpanders();
    const selectors = useProcessErrorSelectors();
    const errors = Object.keys(selectors.getStepFieldErrors(props.stepId));

    React.useEffect(() => {
        expandExpanders(errors);
    }, [expandExpanders, errors]);

    return null;
};

const AutoExpandStepWarnings: React.FC<{ stepId: string }> = (props) => {
    const expandExpanders = useExpandExpanders();
    const selectors = useProcessWarningSelectors();

    const warnings = Object.keys(selectors.getStepFieldWarnings(props.stepId));

    React.useEffect(() => {
        expandExpanders(warnings);
    }, [expandExpanders, warnings]);

    return null;
};

const ProcessParentStepDetails: React.FC<ProcessParentStepDetailsProps> = ({ stepNumber, step, machineRoles, isFirstStep, isNew, errors, currentStepName, setCurrentStepName }) => {
    const processContext = useProcessContext();
    const projectContext = useProjectContext();
    const { actions: contextActions, selectors: contextSelectors } = processContext;
    const { actions: queryStringActions } = useProcessQueryStringContext();
    const processType = processContext.selectors.getProcessType();

    const processEditPermission = { permission: processScopedEditPermission(processType), project: projectContext.state.model.Id, wildcard: true };
    const menuActions = [getDeleteProcessMenuItem("parent step", () => deleteActionAndRedirect(step, undefined, true, contextActions, contextSelectors, queryStringActions), processEditPermission, projectContext.state.model, step, undefined)];

    const processErrorSelectors = useProcessErrorSelectors();
    const actionErrors = processErrorSelectors.getStepErrors(step.Id);

    const processWarningSelectors = useProcessWarningSelectors();
    const actionWarnings = processWarningSelectors.getStepWarnings(step.Id);

    const actions = [];
    actions.push(<OverflowMenu menuItems={menuActions} />);

    React.useEffect(() => {
        // We need to keep these properties in sync for routing purposes.
        if (!isEqual(currentStepName, step.Name)) {
            setCurrentStepName(step.Name);
        }
    }, [setCurrentStepName, currentStepName, step.Name]);

    const getFieldError = React.useCallback(
        (value: string) => {
            const error = processErrorSelectors.getStepFieldError(step.Id, value);
            return error;
        },
        [processErrorSelectors, step.Id]
    );

    const getFieldWarning = React.useCallback(
        (value: string) => {
            const warning = processWarningSelectors.getStepFieldWarning(step.Id, value);
            return warning;
        },
        [processWarningSelectors, step.Id]
    );

    const setStepProperties = React.useCallback(
        (properties: Partial<ActionProperties>) => {
            processContext.actions.setStepProperties(step.Id, properties);
        },
        [step.Id, processContext.actions]
    );

    const setStepMetaProperties = <K extends keyof StoredStep>(state: Pick<StoredStep, K>, callback?: () => void) => {
        processContext.actions.setStepMetaProperties(step.Id, (prev) => ({ ...prev, ...state }));
        if (callback) {
            callback();
        }
    };

    const executionPlanSummary = () => {
        const summary = [<span>This step will run</span>];

        const roles = ParseHelper.parseCSV(step.Properties["Octopus.Action.TargetRoles"] as string);
        const parallelism = step.Properties["Octopus.Action.MaxParallelism"];
        if (roles.length > 0) {
            summary.push(roles.length > 1 ? <span> in roles</span> : <span> in role</span>);
            roles.forEach((r) => {
                summary.push(<RoleChip role={r} key={"role-" + r} />);
            });
        }

        if (parallelism) {
            summary.push(
                <span>
                    {" "}
                    as <strong>rolling step</strong> that will run on <strong>{parallelism}</strong> target{parallelism !== "1" ? "s" : ""} at a time
                </span>
            );
        } else {
            summary.push(
                <span>
                    {" "}
                    with all targets deployed in <strong>parallel</strong>
                </span>
            );
        }

        return Summary.summary(React.Children.toArray(summary));
    };

    return (
        <ExpandableContainer containerKey={step.Id}>
            <ExpansionButtons containerKey={step.Id} errors={errors} expandAllOnMount={isNew} />
            <PaperLayout
                title={<StepName name={step.Name} number={stepNumber} stepType="Parent Step" />}
                titleLogo={
                    <div className={styles.parentStepIcon}>
                        <StepRolling width={"3.1rem"} height={"3.1rem"} />
                    </div>
                }
                busy={undefined} // Handled by parent.
                errors={undefined} // Handled by parent.
                sectionControl={<ActionList actions={actions} />}
                fullWidth={true}
                flatStyle={true}
                hideHelpIcon={true}
                disableScrollToActiveError={true} // We have custom error handling for our process form.
                disableStickyHeader={true} // We have a custom layout above this which is already sticky.
            >
                <AutoExpandStepErrors stepId={step.Id} />
                <AutoExpandStepWarnings stepId={step.Id} />
                <ErrorsForAction actionErrors={actionErrors} />
                <WarningsForAction actionWarnings={actionWarnings} />

                <ExpandableFormSection
                    errorKey="Name"
                    title="Step Name"
                    focusOnExpandAll
                    summary={step.Name ? Summary.summary(step.Name) : Summary.placeholder("Please enter a name for your step")}
                    help="A short, memorable, unique name for this step."
                >
                    <DebounceText value={step.Name} onChange={(name) => setStepMetaProperties({ Name: name })} label="Step name" autoFocus={true} />
                </ExpandableFormSection>

                <ExpandableFormSection isExpandedByDefault={!step.Name} errorKey="Octopus.Action.TargetRoles" title="Execution Plan" summary={executionPlanSummary()} help="Where should this step run?">
                    <RoleMultiSelect
                        label="Runs on targets in roles"
                        onChange={(roles) => setStepProperties({ ["Octopus.Action.TargetRoles"]: ParseHelper.encodeCSV(roles) })}
                        value={ParseHelper.parseCSV(step.Properties["Octopus.Action.TargetRoles"] as string)}
                        validate={(roles) => (roles.length === 0 ? "Please enter one or more roles" : "")}
                        error={getFieldError("Octopus.Action.TargetRoles")}
                        items={machineRoles}
                    />
                    <MaxParallelismOptions projectId={projectContext.state.model.Id} maxParallelism={step.Properties["Octopus.Action.MaxParallelism"] as string} setStepProperties={setStepProperties} />
                </ExpandableFormSection>

                <FormSectionHeading title="Conditions" />
                <RunTriggerExpander
                    isFirstStep={isFirstStep}
                    condition={step.Condition}
                    onConditionChange={(condition) => setStepMetaProperties({ Condition: condition })}
                    variableExpression={step.Properties["Octopus.Step.ConditionVariableExpression"] as string}
                    onVariableExpressionChange={(x) => setStepProperties({ ["Octopus.Step.ConditionVariableExpression"]: x })}
                    projectId={projectContext.state.model.Id}
                    variableExpressionError={getFieldError("ConditionVariableExpression")}
                />

                {!isFirstStep && <StartTriggerExpander startTrigger={step.StartTrigger} onChange={(startTrigger) => setStepMetaProperties({ StartTrigger: startTrigger })} />}
                {contextSelectors.shouldShowPackageRequirementOptionForStep(step.Id) && (
                    <PackageRequirementExpander
                        packageRequirement={step.PackageRequirement}
                        onChange={(val) => {
                            setStepMetaProperties({ PackageRequirement: val });
                            if (val === PackageRequirement.AfterPackageAcquisition) processContext.actions.resetPackageRequirementAfterPackageAcquisitionStep();
                        }}
                    />
                )}
            </PaperLayout>
        </ExpandableContainer>
    );
};

export default ProcessParentStepDetails;
