import * as React from "react";
const styles = require("../../../components/form/Sections/style.less");

interface SectionHeadingProps {
    title: string | JSX.Element;
}

class SectionHeading extends React.Component<SectionHeadingProps, {}> {
    render() {
        return (
            <div className={styles.sectionHeading}>
                <div className={styles.sectionHeadingTitle}>{this.props.title}</div>
            </div>
        );
    }
}

export default SectionHeading;
