import type { History } from "history";
import * as React from "react";
import { ActionButtonType } from "~/components/Button";
import ActionButton, { IconPosition } from "~/components/Button/ActionButton";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import type { OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import { SimpleMenu } from "~/primitiveComponents/navigation/Menu/SimpleMenu";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
const styles = require("./style.less");

type LocationDescriptor = History.LocationDescriptor;

interface BreadcrumbProps {
    title?: string; // If specified, this text will display above the title.
    path?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    overflow?: OverflowMenuNavLink[]; // If specified, this will create a Dropdown button with ternary theme and the specified overflow items
    isAreaLevelBreadcrumb?: boolean;
}

const Breadcrumb: React.StatelessComponent<BreadcrumbProps> = (props) => {
    return props.title ? (
        <div className={styles.breadcrumb}>
            <BreadcrumbContent {...props} />
        </div>
    ) : null;
};

const BreadcrumbContent = (props: BreadcrumbProps) => {
    if (props.overflow && props.title) {
        return <BreadcrumbContentMenu title={props.title} overflow={props.overflow} />;
    } else if (props.path) {
        return (
            <InternalLink className={props.isAreaLevelBreadcrumb ? styles.breadcrumbLink : null} to={props.path}>
                {props.title}
            </InternalLink>
        );
    }

    return <div>{props.title}</div>;
};

interface BreadcrumbContentMenuProps {
    title: string;
    overflow: OverflowMenuNavLink[];
}

function BreadcrumbContentMenu({ overflow, title }: BreadcrumbContentMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();

    return (
        <>
            <ActionButton type={ActionButtonType.Ternary} icon={<DropDownIcon />} iconPosition={IconPosition.Right} label={title} onClick={openMenu} menuButtonAttributes={buttonAriaAttributes} />
            <SimpleMenu compact={true} menuState={menuState} items={overflow.map((i) => ({ type: "internal-link", label: i.text, path: i.path, showAsActive: "never" }))} accessibleName={title} />
        </>
    );
}

export default Breadcrumb;
