/* eslint-disable no-restricted-imports */
import * as React from "react";
import { useState } from "react";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { ProjectResource, VcsBranchResource } from "~/client/resources";
import { HasVersionControlledPersistenceSettings } from "~/client/resources";
import BusyFromPromise from "~/components/BusyFromPromise";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import DialogTrigger from "~/components/Dialog/DialogTrigger";
import type { PrimaryActionProps } from "~/components/FormPaperLayout/FormPaperLayout";
import { GitCommitIcon } from "~/primitiveComponents/dataDisplay/Icon";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/ToolTip";
import CommitDialog from "./CommitDialog";
const styles = require("./styles.less");

interface CommitButtonProps {
    busyLabel: string;
    label: string;
    disabled: boolean | undefined;
    branchName: string;
    defaultSummary: string;
    commit(): Promise<unknown>;
    commitMessage: CommitMessageWithDetails;
    onCommitMessageChanged: (commitMessage: CommitMessageWithDetails) => void;
    commitButtonAccessibleName: string;
    commitMessageAccessibleName: string;
    commitDetailsAccessibleName: string;
    onNewBranchCreating?: (branchName: string) => Promise<void>;
    onInitializing?: (openDialog: () => void) => void;
    projectId: string;
}

const CommitButton: React.FC<CommitButtonProps> = (props) => {
    const [commitPromise, setCommitPromise] = useState<Promise<unknown> | undefined>(undefined);
    const iconStyle = {
        margin: "0 10px",
        width: "20px",
        height: "100%",
    };

    const commit = () => {
        // We need both buttons to de disabled together.
        // Currently, the disabled state is encapsulated in a single ActionButton, so we can't reuse our existing logic and have to re-invent the wheel a little bit here
        const promise = props.commit();
        setCommitPromise(promise);
    };

    return (
        <DialogTrigger
            render={({ open, openDialog, closeDialog }) => {
                if (props.onInitializing) {
                    props.onInitializing(openDialog);
                }

                return (
                    <>
                        <BusyFromPromise promise={commitPromise}>
                            {(busy: boolean) => {
                                const disabled = props.disabled || busy;
                                const label = busy ? props.busyLabel : props.label;
                                return (
                                    <div role="group" className={styles.buttonContainer}>
                                        <ActionButton label={label} type={ActionButtonType.Save} disabled={disabled} onClick={openDialog} />
                                        {/*While it would be nicer to use IconButton here, the styling is all wrong here - this is more like an ActionButton with an Icon as its content */}
                                        {/*We should revisit our components to see if we can make this less awkward*/}
                                        <ToolTip
                                            content={
                                                <>
                                                    Quick commit to <strong>{props.branchName}</strong>
                                                </>
                                            }
                                        >
                                            <ActionButton label={"Quick commit"} hideTitle={true} accessibleName={"Quick commit"} type={ActionButtonType.Save} disabled={disabled} className={styles.openDialogButton} onClick={commit}>
                                                <GitCommitIcon key="icon" className="MuiChip-icon MuiChip-iconSmall" style={iconStyle} />
                                            </ActionButton>
                                        </ToolTip>
                                    </div>
                                );
                            }}
                        </BusyFromPromise>

                        <CommitDialog
                            branchName={props.branchName}
                            defaultSummary={props.defaultSummary}
                            commitMessage={props.commitMessage}
                            onCloseWithoutCommit={closeDialog}
                            open={open}
                            onCommit={() => {
                                commit();
                                closeDialog();
                            }}
                            onCommitMessageChanged={props.onCommitMessageChanged}
                            commitMessageAccessibleName={props.commitMessageAccessibleName}
                            commitDetailsAccessibleName={props.commitDetailsAccessibleName}
                            onNewBranchCreating={async (branchName: string) => {
                                if (props.onNewBranchCreating) {
                                    await props.onNewBranchCreating(branchName);
                                    closeDialog();
                                }
                            }}
                            projectId={props.projectId}
                        />
                    </>
                );
            }}
        />
    );
};

export default CommitButton;

export interface GetCommitButtonProps {
    project: ProjectResource;
    branch: VcsBranchResource | undefined;
    defaultCommitMessage: string;
    commitMessage: CommitMessageWithDetails;
    updateCommitMessage: (commitMessage: CommitMessageWithDetails) => void;
    commitMessageAccessibleName: string;
    commitDetailsAccessibleName: string;
    commitButtonAccessibleName: string;
    actionButtonProps: PrimaryActionProps;
    onNewBranchCreating?: (branchName: string) => Promise<void>;
    onInitializing?: (openDialog: () => void) => void;
}

export const GetCommitButton: React.FC<GetCommitButtonProps> = (props) => {
    if (!HasVersionControlledPersistenceSettings(props.project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");

    return (
        <CommitButton
            branchName={props.branch?.Name ?? props.project.PersistenceSettings.DefaultBranch}
            label={props.actionButtonProps.label}
            disabled={props.actionButtonProps.disabled}
            busyLabel={props.actionButtonProps.busyLabel}
            defaultSummary={props.defaultCommitMessage}
            commitMessage={props.commitMessage}
            onCommitMessageChanged={(commitMessage) => props.updateCommitMessage(commitMessage)}
            commit={props.actionButtonProps.onClick}
            commitMessageAccessibleName={props.commitMessageAccessibleName}
            commitDetailsAccessibleName={props.commitDetailsAccessibleName}
            commitButtonAccessibleName={props.commitButtonAccessibleName}
            onNewBranchCreating={props.onNewBranchCreating}
            onInitializing={props.onInitializing}
            projectId={props.project.Id}
        />
    );
};
