/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
const styles = require("./style.less");

interface MeasureOutOfFlowProps<TMeasurement> {
    children: any;
    onMeasured(measurement: TMeasurement): void;
}

interface MeasureOutOfFlowState {
    hasMeasured: boolean;
}

// Measures an element by positioning it out of the flow of the document's body.
// This means that measuring it won't force a reflow of unrelated elements, which could be expensive.
// The downside is that in order to measure it out of the flow, it needs to mount the component in a container div
// and once it has finished measuring, it unmounts the element, then remounts it in its original place
function MeasureOutOfFlow<TMeasurement>(getMeasurement: (element: HTMLElement) => TMeasurement) {
    return class MeasureOutOfFlowInner extends BaseComponent<MeasureOutOfFlowProps<TMeasurement>, MeasureOutOfFlowState> {
        private container: HTMLElement | null = null;

        constructor(props: MeasureOutOfFlowProps<TMeasurement>) {
            super(props);
            this.state = {
                hasMeasured: false,
            };
        }

        componentDidMount() {
            if (!this.state.hasMeasured && this.container) {
                const measurement = getMeasurement(this.container);
                this.props.onMeasured(measurement);
                this.setState({ hasMeasured: true });
            }
        }

        render() {
            if (this.state.hasMeasured) {
                return this.props.children;
            }
            return (
                <div className={styles.outOfFlowContainer} ref={(container) => (this.container = container)}>
                    {this.props.children}
                </div>
            );
        }
    };
}

export const MeasureWidthOutOfFlow = MeasureOutOfFlow((el) => el.getBoundingClientRect().width);
