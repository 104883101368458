/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import Paper from "material-ui/Paper";
import * as React from "react";
import { withTheme } from "~/components/Theme";
import { zIndexPopovers } from "~/theme";
const styles = require("./style.less");

interface PopoverWhenFocusedProps {
    isFocused: boolean;
    onClickOutside?: (event: MouseEvent) => void;
    position?: PopoverPosition;
    dynamicPosition?: GetPopoverPosition;
}

export type GetPopoverPosition = (contentHeight: number, contentWidth: number, relativeParent: HTMLElement) => PopoverPosition;

export interface PopoverPosition {
    top?: number;
    bottom?: number;
    right?: number;
    left?: number;
}

const blurredPaperStyle = { boxShadow: "none", height: "100%", backgroundColor: "inherit" };
const focusedContainerStyle = { zIndex: zIndexPopovers };
//eslint-disable-next-line react/no-unsafe
export class PopoverWhenFocused extends React.PureComponent<PopoverWhenFocusedProps, {}> {
    private onClickAway: ((event: MouseEvent) => void) | null = undefined!;
    private container: HTMLElement | null = undefined!;
    private contentContainer: HTMLElement | null = undefined!;
    private changeSubscription: boolean = false;
    private subscriptionTimeout: ReturnType<typeof setTimeout> | null = undefined!;

    componentDidMount() {
        this.subscribe(this.props.onClickOutside);
        this.updatePosition();
    }

    componentDidUpdate() {
        if (this.changeSubscription) {
            this.subscribe(this.props.onClickOutside);
            this.updatePosition();
            this.changeSubscription = false;
        }
    }

    UNSAFE_componentWillUpdate() {
        if (this.changeSubscription) {
            this.unsubscribe();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: PopoverWhenFocusedProps) {
        if (nextProps.onClickOutside !== this.props.onClickOutside) {
            this.changeSubscription = true;
        }
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {
        const popoverPositionStyles = {
            ...(this.props.position ? this.props.position : {}),
            ...(this.props.dynamicPosition ? { opacity: 0 } : {}),
        };

        return withTheme((theme) => (
            <div className={styles.popoverPositionContainer} ref={(container) => (this.container = container)}>
                <div
                    ref={(contentContainer) => (this.contentContainer = contentContainer)}
                    style={
                        this.props.isFocused
                            ? { ...focusedContainerStyle, ...popoverPositionStyles }
                            : {
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                              }
                    }
                    className={styles.popover}
                >
                    <Paper style={this.props.isFocused ? { backgroundColor: theme.paper3 } : blurredPaperStyle}>{this.props.children}</Paper>
                </div>
            </div>
        ));
    }

    private updatePosition() {
        if (this.props.isFocused && this.props.dynamicPosition && this.contentContainer && this.container) {
            const contentHeight = this.contentContainer.offsetHeight;
            const contentWidth = this.contentContainer.offsetWidth;
            // Set the display to none so that other properties (like the container size) can be measured without the content affecting them
            const originalDisplayStyle = this.contentContainer.style.display;
            this.contentContainer.style.display = "none";
            const position = this.props.dynamicPosition(contentHeight, contentWidth, this.container);
            this.contentContainer.style.display = originalDisplayStyle;

            if (position.top || position.top === 0) {
                this.contentContainer.style.top = toPx(position.top);
            }
            if (position.bottom || position.bottom === 0) {
                this.contentContainer.style.bottom = toPx(position.bottom);
            }
            if (position.left || position.left === 0) {
                this.contentContainer.style.left = toPx(position.left);
            }
            if (position.right || position.right === 0) {
                this.contentContainer.style.right = toPx(position.right);
            }
        }

        if (this.contentContainer) {
            this.contentContainer.style.opacity = "1";
        }

        function toPx(position: number) {
            return position.toString() + "px";
        }
    }

    private subscribe(onClickOutside?: (event: MouseEvent) => void) {
        if (onClickOutside) {
            // This subscription might be setup as part of a click event handler,
            // which means it could handle the click event that set itself up
            // A simple timeout allows the click event to finish bubbling up to window before we subscribe
            // material-ui does the same thing in RenderToLayer.js
            this.subscriptionTimeout = setTimeout(() => {
                this.onClickAway = (event) => {
                    if (this.props.isFocused && this.container && !this.container.contains(event.target as Node)) {
                        // The following checks are a bit of a hack and warrants some explanation...
                        // There may be content in the popover component tree that is not in the corresponding DOM tree
                        // For example, a popup menu that uses material-ui's Popover component will be attached directly underneath the body
                        // Checking that the event.target is a descendant of this component is insufficient in this case
                        //
                        // It is difficult to come up with a general way of handling this case, especially because a lot
                        // of the components that end up creating these detached DOM subtrees are in third party
                        // libraries (i.e. material-ui and maybe others)
                        //
                        // So the goal is that we don't want to count clicks as "clickoutside"
                        // events if they originated from detached DOM sub-trees that are part of the current react
                        // component sub-tree. Since there is no reliable way to determine this, the best we can do is
                        // assume that
                        // a) Any react roots (any children of body which are divs) are either the main app or are
                        // detached DOM sub-trees (This is how material-ui handles popovers and dialogs)
                        // b) If the event came from the main app's react root, then it did not occur in a popover/dialog
                        // c) If the event came from any other react root, it came from a popover/dialog
                        //
                        // So simply check that the event came from the main app's react root before calling the
                        // "clickoutside" handler is sufficient in most cases
                        //
                        // There are some potential edge cases which come down to whether the popover/dialog components
                        // are using a separate layer to prevent click-away clicks from passing through to the elements
                        // shown beneath them. We are currently using this behavior in a lot of places, which makes this
                        // work as expected. We could end up with problems if that changes.
                        //
                        // For example, if the you have a popover component that is not a descendant of this component,
                        // and it does not use a click-away layer (think of something like a tooltip on some other part
                        // of the page) and you clicked on it, you would expect it to be a 'clickaway' event, but
                        // in fact since the click is in another react-root it would not be counted.
                        const reactRoots = Array.from(document.querySelectorAll("body > div"));
                        const containerHostReactRoot = reactRoots.find((r) => r.contains(this.container));
                        if (!containerHostReactRoot || containerHostReactRoot.contains(event.target as Node)) {
                            onClickOutside(event);
                        }
                    }
                };
                // This event must be click, not mousedown, so that it matches the behaviour of the material-ui popup.
                // When you have a material-ui popup nested in a PopoverWhenFocused, the material-ui popup click event handler should fire first
                // If this was mousedown, then this would fire first
                window.addEventListener("click", this.onClickAway);
                this.subscriptionTimeout = null;
            }, 0);
        }
    }

    private unsubscribe() {
        if (this.subscriptionTimeout) {
            clearTimeout(this.subscriptionTimeout);
            this.subscriptionTimeout = null;
        } else if (this.onClickAway) {
            window.removeEventListener("click", this.onClickAway);
            this.onClickAway = null;
        }
    }
}
