/* eslint-disable @typescript-eslint/no-explicit-any */

import classnames = require("classnames");
import * as React from "react";
import BusyIndicator from "~/components/BusyIndicator";
const styles = require("./ImageWithPlaceholder.less");

interface State {
    hasLoaded: boolean;
}

interface Props {
    src: any;
    alt?: string;
    className?: string;
    minHeight?: string;
}

export default class ImageWithPlaceholder extends React.Component<Props, State> {
    private static defaultProps: Partial<Props> = {
        minHeight: "2rem", // To give the busy indicator some room, as this is an absolute positioned div.
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            hasLoaded: false,
        };
    }

    render() {
        return (
            <div className={styles.imageContainer} style={{ minHeight: this.props.minHeight }}>
                {!this.state.hasLoaded && (
                    <div className={styles.loadingPlaceholder} style={{ minHeight: this.props.minHeight }}>
                        <BusyIndicator show={true} inline={true} />
                    </div>
                )}
                <img className={classnames(styles.image, this.props.className)} src={this.props.src} alt={this.props.alt} onLoad={() => this.onImageLoad()} onError={() => this.onImageError()} />
            </div>
        );
    }

    private onImageLoad() {
        this.setState({ hasLoaded: true });
    }

    private onImageError() {
        // Hide the spinner on error, pretend this has loaded.
        this.setState({ hasLoaded: true });
    }
}
