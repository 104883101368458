import type { PathToInput } from "@octopusdeploy/step-runtime-inputs";
import { asResourceInputs, convertFromJsonSchemaToInputSchema } from "@octopusdeploy/step-runtime-inputs";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { ActionExecutionLocation } from "~/client/resources";
import { ExecutionLocation } from "~/client/resources/stepPackage";
import type { ActionEditProps, ActionPlugin } from "~/components/Actions/pluginRegistry";
import { getPathAsString } from "~/components/StepPackageEditor/Inputs/getPathAsString";
import { isProjectStepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { UnknownStepPackage } from "~/components/StepPackageEditor/StepPackage/StepPackage";
import { StepPackageEditor } from "~/components/StepPackageEditor/StepPackageEditor";
import { FormSectionHeading } from "~/components/form/Sections";
import type { UnstructuredStepResourceInputs } from "../Inputs/UnstructuredStepResourceInputs";
import { getPackagesFromInputs } from "../Inputs/getPackagesFromInputs";
import { mapRootInitialInputs } from "../Inputs/mapInitialInputs";

// We want to move away from using the name "Actions" and move towards using the name "Steps" instead.
// We have used the name Steps within our new step UI Framework, which begins here.
// We continue to use the name "Actions" in our old implementation.
// This function forms the boundary between the two,
// hence the naming is inconsistent here and both Step and Action refer to the same thing
export function createStepPackagePluginAdapter(stepPackage: UnknownStepPackage, actionType: string): ActionPlugin {
    const EditStepPackageInputs: React.FC<ActionEditProps> = (props) => {
        const { getFieldError } = props;

        const getFieldErrorFromInputPath = React.useCallback(
            (pathToInput: PathToInput) => {
                return getFieldError(`inputs.${getPathAsString(pathToInput)}`);
            },
            [getFieldError]
        );

        if (!props.inputDependencies) {
            throw new Error("The required step input dependencies have not been provided to the Step Package Editor.");
        }

        const setInputs = stepPackage.isLegacyStep ? props.setProperties : props.setInputs;
        if (!setInputs) {
            throw new Error("The Step Package Editor requires either setInputs or setProperties to be supplied.");
        }

        const stepPackageEditor = (
            <StepPackageEditor
                stepPackage={stepPackage}
                setInputs={setInputs}
                /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
                inputs={stepPackage.isLegacyStep ? props.properties : (props.inputs as UnstructuredStepResourceInputs)}
                isExpandedByDefault={props.expandedByDefault}
                inputDependencies={props.inputDependencies}
                getFieldError={getFieldErrorFromInputPath}
            />
        );
        if (isProjectStepInputDependencies(props.inputDependencies)) {
            return (
                <>
                    <FormSectionHeading title="Configuration" />
                    {stepPackageEditor}
                </>
            );
        }
        return stepPackageEditor;
    };

    const getInitialInputsAndPackages = () => {
        const initialInputs = stepPackage.stepUI.createInitialInputs();
        const inputSchema = convertFromJsonSchemaToInputSchema(stepPackage.inputSchema, initialInputs);
        const mappedInputs = mapRootInitialInputs(inputSchema, initialInputs);
        return {
            inputs: asResourceInputs(inputSchema.properties, mappedInputs),
            packages: getPackagesFromInputs(mappedInputs, inputSchema, []),
        };
    };

    // AndrewB 24/3/2022
    //
    // We are introducing new abstractions that make Server the source of truth for how steps can be executed
    // Ultimately these new abstractions will allow us to simplify clients that want to define new deployment processes for Server, and simplify our execution pipeline
    //
    // At the moment these new abstractions are limited to step packages, but will eventually be adapted to Sashimi-based and server-based steps
    // Once server can express execution semantics for all steps, we will stop mapping from Server's view to Portal's view of these, and simply drive portal from the information supplied by Server.
    //
    // For now, we map the new ExecutionLocation Server model from the incoming step package to the existing ActionExecutionLocation client model
    return {
        actionType,
        canBeChild: true,
        canHaveChildren: () => true,
        // TODO: Currently, all step package steps run on SDKs and do not require an execution container.
        // TODO: Once we have a need for this (example: Terraform, K8s, or Azure steps coming into step packages), have server express whether a step uses exec containers, and map that to here
        canRunInContainer: false,
        canRunOnWorker: true,
        disableAddTargetRoles: false,
        edit: EditStepPackageInputs,
        executionLocation: stepPackage.executionLocation === ExecutionLocation.ServerOrWorker ? ActionExecutionLocation.AlwaysOnServer : ActionExecutionLocation.TargetOrServer,
        features: undefined,
        hasPackages: () => {
            return false; //todo-step-ui, inspect the schema to determine this
        },
        summary: () => {
            return <>{stepPackage.description}</>;
        },
        targetRoleOption: () => {
            return stepPackage.requiresTargetRole ? TargetRoles.Required : TargetRoles.Optional;
        },
        getInitialInputsAndPackages,
        version: stepPackage.version,
        stepPackage: stepPackage,
        releaseNotesUrl: stepPackage.releaseNotesUrl,
    };
}
