import * as React from "react";
import type { MachineTenantSummaryResource, TenantResource } from "~/client/resources";
import InternalLink from "~/components/Navigation/InternalLink";
import { useOctopusTheme } from "~/components/Theme";
import { OctopusIcon, OctopusIconType } from "~/primitiveComponents/dataDisplay/Icon";
import routeLinks from "~/routeLinks";
import styles from "../style.less";

interface TenantContentRowProps {
    tenant: TenantResource;
    tenantSummaries: MachineTenantSummaryResource;
}

export const TenantContentRow = ({ tenant, tenantSummaries }: TenantContentRowProps) => {
    const theme = useOctopusTheme();
    const totalMachines = (tenantSummaries && tenantSummaries[tenant.Id]) || 0;

    const ariaLabel = `Tenant ${tenant.Name} has ${totalMachines.toLocaleString()} deployment target${totalMachines === 1 ? "" : "s"}`;

    return (
        <div className={styles.tenantRowsContainer} key={tenant.Id} tabIndex={0} aria-label={ariaLabel}>
            <div className={styles.tenantIcon}>
                <OctopusIcon iconType={OctopusIconType.Tenant} color={theme.iconDark} />
            </div>
            <div className={styles.tenantName}>
                <InternalLink to={routeLinks.infrastructure.machines.filtered({ tenantIds: tenant.Id })}>{tenant.Name}</InternalLink>
            </div>
            <div className={styles.tenantMachinesCount}>{totalMachines.toLocaleString()}</div>
        </div>
    );
};
