// copied from material-ui so that we can set tabIndex
import cn from "classnames";
import * as PropTypes from "prop-types";
import * as React from "react";
import IconButton from "~/components/IconButton";
import { Icon } from "~/components/IconButton/IconButton";
const styles = require("./style.less");

interface CardExpandableProps {
    expanded?: boolean;
    tabIndex?: number;
    position?: boolean;
    onExpanding?(event: React.MouseEvent<{}, MouseEvent>): void;
}

class CardExpandable extends React.Component<CardExpandableProps> {
    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };

    render() {
        return <IconButton onClick={this.props.onExpanding} tabIndex={this.props.tabIndex} icon={Icon.Expand} className={cn(this.props.expanded ? styles.expandOpen : styles.expandClosed)} />;
    }
}

export default CardExpandable;
