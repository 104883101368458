/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { uniqueId } from "lodash";
import RadioButtonImpl from "material-ui/RadioButton";
import * as React from "react";
import { withTheme } from "~/components/Theme";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
const styles = require("./style.less");

interface RadioButtonProps {
    className?: string;
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
    label?: React.ReactNode;
    isDefault?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    accessibleName?: string;
}

interface DefaultRadioButtonProps extends RadioButtonProps {
    checked: boolean;
}

export class RadioButton extends React.Component<RadioButtonProps & DefaultRadioButtonProps, {}> {
    private uniqueLabelId: string;
    constructor(props: RadioButtonProps & DefaultRadioButtonProps) {
        super(props);
        this.uniqueLabelId = uniqueId("radio-label-");
    }

    static defaultProps: DefaultRadioButtonProps = {
        checked: false,
        isDefault: false,
    };
    radioButtonImpl: RadioButtonImpl | null = undefined!;

    componentDidMount() {
        if (this.props.autoFocus) {
            // this digs into the RadioButton implementation to set focus
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const internalSwitch = this.radioButtonImpl!.refs.enhancedSwitch as any;
            internalSwitch.refs.checkbox.focus();
            internalSwitch.tabPressed = true;
            internalSwitch.handleFocus();
        }
    }

    render() {
        const uniqueLabelId = this.uniqueLabelId;
        return withTheme((theme) => {
            const { isDefault, label, disabled, accessibleName, ...otherProps } = this.props;
            const newlabel = isDefault ? <span className={styles.isDefault}>{label}</span> : label;

            return (
                <>
                    <RadioButtonImpl
                        disabled={disabled}
                        ref={(ref) => (this.radioButtonImpl = ref)}
                        label={newlabel}
                        labelStyle={this.props.disabled ? { color: theme.secondaryText } : { color: theme.primaryText }}
                        checkedIcon={<ThirdPartyIcon iconType={ThirdPartyIconType.RadioButtonChecked} style={{ fill: theme.focus }} />}
                        iconStyle={{ marginRight: "0.5rem", fill: theme.primaryText }}
                        aria-checked={this.props.checked}
                        aria-labelledby={uniqueLabelId}
                        {...otherProps}
                    />
                    <div id={uniqueLabelId} className={styles.visuallyHidden}>
                        {accessibleName ? <>{accessibleName}</> : <>{label}</>}
                    </div>
                </>
            );
        });
    }
}

export default RadioButton;
