/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import type { StepPackageDeploymentTargetRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { client } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink";
const styles = require("../style.less");

interface StepPackageDeploymentTargetRowProps {
    deploymentTargetRegistration: StepPackageDeploymentTargetRegistration;
    summaries: Record<string, number>;
    link: (filter: object) => string;
}

export const StepPackageDeploymentTargetRow = ({ deploymentTargetRegistration, summaries, link }: StepPackageDeploymentTargetRowProps) => {
    const machinesCount = summaries[deploymentTargetRegistration.key] || 0;
    if (!machinesCount || machinesCount === 0) {
        return null;
    }

    const endpointName = deploymentTargetRegistration.name;
    const endpointLogo = deploymentTargetRegistration.links.Logo && <img src={client.resolve(deploymentTargetRegistration.links.Logo)} alt={endpointName} />;

    return (
        <div className={styles.endpointRowsContainer} key={deploymentTargetRegistration.key}>
            <div className={styles.endpointIcon}>{endpointLogo}</div>
            <div className={styles.endpointName}>
                <InternalLink to={link({ deploymentTargetTypes: deploymentTargetRegistration.key })}>{endpointName}</InternalLink>
            </div>
            <div className={styles.endpointMachinesCount}>{machinesCount.toLocaleString()}</div>
        </div>
    );
};
