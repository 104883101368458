import { sortBy, uniq } from "lodash";
import * as React from "react";
import type { AccountResource, AccountType } from "~/client/resources/accountResource";
import type { EnvironmentResource } from "~/client/resources/environmentResource";
import Permission from "~/client/resources/permission";
import { repository } from "~/clientInstance";
import { ActionButtonType } from "~/components/Button";
import ActionButton, { IconPosition } from "~/components/Button/ActionButton";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { SimpleList } from "~/components/List/SimpleList";
import MarkdownDescription from "~/components/MarkdownDescription";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { convertQueryToQueryString } from "~/components/QueryStringFilters/QueryStringFilters";
import { FormSectionHeading } from "~/components/form";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { SimpleMenu } from "~/primitiveComponents/navigation/Menu/SimpleMenu";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
import type { SimpleMenuItem } from "~/primitiveComponents/navigation/MenuItems/SimpleMenuItems";
import type { TenantResource } from "../../../../client/resources/tenantResource";
import { environmentChipListIncludingMissing, tenantChipListIncludingMissing } from "../../../../components/Chips/index";
import routeLinks from "../../../../routeLinks";
import { RawAccountTypeDetailsMap } from "../../InfrastructureDetails";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import Onboarding from "./Onboarding";
const styles = require("./style.less");

class AccountList extends SimpleList<AccountResource> {}

interface AccountsParams {
    initialData: InitialData;
}

interface InitialData {
    accounts: AccountResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
}

const Title = "Accounts";
const AccountsLayoutFormPage = FormPage<InitialData>();

const AccountsLayoutPage: React.FC = () => {
    return (
        <AccountsLayoutFormPage
            title={Title}
            load={async () => {
                const accounts = await repository.Accounts.all();
                const envIds = uniq(accounts.reduce<string[]>((list, acc) => list.concat(acc.EnvironmentIds), []));
                const tenantIds = uniq(accounts.reduce<string[]>((list, acc) => list.concat(acc.TenantIds), []));

                return {
                    accounts,
                    environments: await repository.Environments.all({ ids: envIds }),
                    tenants: await repository.Tenants.all({ ids: tenantIds }),
                };
            }}
            renderWhenLoaded={(initialData) => <AccountsLayoutInternal initialData={initialData} />}
            renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args} />}
        />
    );
};

class AccountsLayoutInternal extends DataBaseComponent<AccountsParams> {
    constructor(props: AccountsParams) {
        super(props);
    }

    renderEnvironments = (account: AccountResource) => {
        if (account.EnvironmentIds.length === 0) {
            return null;
        }

        return <div>{environmentChipListIncludingMissing(this.props.initialData.environments, account.EnvironmentIds)}</div>;
    };

    renderAccountTypes(types: AccountType[], name: string, description: JSX.Element) {
        let accounts = this.props.initialData.accounts.filter((account) => types.indexOf(account.AccountType) !== -1);
        accounts = sortBy(accounts, (account) => account.Name);
        if (!accounts || accounts.length === 0) {
            return null;
        }

        const title = (
            <div className={styles.typeHeader}>
                <div className={styles.typeHeaderTitleContainer}>{name}</div>
            </div>
        );
        return (
            <React.Fragment>
                <FormSectionHeading title={title} />
                <div className={styles.typeBody}>
                    <AccountList items={accounts} onRow={this.renderRow} onRowRedirectUrl={(account: AccountResource) => routeLinks.infrastructure.account(account.Id)} />
                </div>
            </React.Fragment>
        );
    }

    renderRow = (account: AccountResource) => {
        return (
            <div key={account.Id} className={styles.account}>
                <ListTitle>{account.Name}</ListTitle>
                {environmentChipListIncludingMissing(this.props.initialData.environments, account.EnvironmentIds)}
                {tenantChipListIncludingMissing(this.props.initialData.tenants, account.TenantIds)}
                <MarkdownDescription markup={account.Description} />
            </div>
        );
    };

    renderContent = () => {
        const accountTypeDetailsMap = RawAccountTypeDetailsMap;
        return <div>{accountTypeDetailsMap.map((account) => this.renderAccountTypes(account.types, account.namePlural, account.description))}</div>;
    };

    render() {
        const addAccountButton = <AddAccountButton />;

        return (
            <InfrastructureLayout>
                <PaperLayout title="Accounts" sectionControl={addAccountButton}>
                    {this.props.initialData.accounts.length === 0 && <Onboarding />}
                    {this.props.initialData.accounts.length > 0 && <React.Fragment>{this.renderContent()}</React.Fragment>}
                </PaperLayout>
            </InfrastructureLayout>
        );
    }
}

function AddAccountButton() {
    const [openMenu, menuState, buttonArriaAttributes] = useMenuState();
    const addAccountItems: SimpleMenuItem[] = RawAccountTypeDetailsMap.map((accountTypeDetails) => ({
        type: "internal-link",
        label: accountTypeDetails.name,
        path: `${routeLinks.infrastructure.accounts.create}${convertQueryToQueryString({ accountType: accountTypeDetails.types[0] })}`,
    }));

    return (
        <PermissionCheck permission={Permission.AccountCreate} wildcard={true}>
            <ActionButton type={ActionButtonType.Primary} icon={<DropDownIcon />} iconPosition={IconPosition.Right} label="Add Account" onClick={openMenu} menuButtonAttributes={buttonArriaAttributes} />
            <SimpleMenu menuState={menuState} items={addAccountItems} accessibleName={"Add account"} compact={true} />
        </PermissionCheck>
    );
}

export default AccountsLayoutPage;
