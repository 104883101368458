import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const PollingTarget = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 200 200" title="Polling Target" {...props}>
        <path
            className={styles.imgCyan}
            d="M164.3,0H36.2C32.8,0,30,2.7,30,6.1v82.8c0,3.4,2.8,6.1,6.2,6.1h128.2c3.4,0,6.2-2.7,6.2-6.1V6.1
	C170.5,2.7,167.7,0,164.3,0z"
        />
        <path
            className={styles.imgWhite}
            d="M130.4,29.2c-0.6,0.6-1.5,0.9-2.3,0.8c-1.8-0.1-3.4-0.9-4.6-2.3c-2.2-2.4-2.6-5.3-0.9-6.8
	c0.6-0.6,1.5-0.9,2.3-0.8c1.8,0.1,3.5,0.9,4.7,2.3c0.9,0.9,1.5,2.1,1.8,3.3c0.2,0.6,0.2,1.3,0,1.9C131.1,28.2,130.8,28.8,130.4,29.2
	L130.4,29.2z M119.9,18.3c-0.1,0.5-0.8,1.8-3.3,1.8c-0.8,0-1.5-0.1-2.2-0.3c-2.8-0.7-4.5-2.3-4.1-3.9c0.1-0.5,0.8-1.8,3.3-1.8
	c0.8,0,1.5,0.1,2.2,0.3C118.6,15.2,120.3,16.8,119.9,18.3z M102.7,19.8c-0.8,0.4-1.7,0.6-2.6,0.6c-0.4,0-0.9,0-1.2-0.2
	c-0.4-0.2-0.7-0.5-0.9-0.9c-0.6-1.3,0.4-2.8,2.6-3.8c0.8-0.4,1.7-0.6,2.6-0.6c0.4,0,0.9,0,1.2,0.2c0.4,0.2,0.7,0.5,0.9,0.9
	C105.9,17.3,104.9,18.8,102.7,19.8z M93,63.9c-0.5,0.1-1,0.2-1.5,0.2c-1.6,0-2.7-0.6-2.9-1.6c-0.1-0.4,0-0.9,0.2-1.3
	s0.5-0.7,0.9-0.9c0.7-0.4,1.4-0.7,2.2-0.9c0.5-0.1,1-0.1,1.5-0.1c1.6,0,2.7,0.6,2.9,1.6C96.7,62.2,95.3,63.5,93,63.9z M80.7,59.4
	c-0.2,1.3-1.6,2.1-3.9,2.1h-1.1c-1.5,0-2.9-0.6-4-1.6c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.1-0.5-0.1-0.8c0.2-1.3,1.6-2.1,4-2.1h1.1
	c1.5,0,2.9,0.6,4,1.6c0.2,0.2,0.3,0.4,0.4,0.7C80.7,58.9,80.7,59.1,80.7,59.4L80.7,59.4z M61.5,52.6c-0.3,0.4-0.6,0.7-1.1,1
	c-0.4,0.2-0.9,0.3-1.4,0.2c-1.3-0.1-2.6-0.4-3.8-1c-2.8-1.4-4.2-3.5-3.4-5.1c0.3-0.4,0.6-0.8,1.1-1c0.4-0.2,0.9-0.3,1.4-0.2
	c1.3,0.1,2.6,0.4,3.8,1C60.9,49,62.3,51.1,61.5,52.6z M43.4,43.7c-0.3,0.3-0.6,0.5-0.9,0.6c-0.3,0.1-0.7,0.2-1.1,0.2
	c-2-0.2-3.8-1.1-5.2-2.5c-3.3-2.9-3.6-5.5-2.4-6.7c0.3-0.3,0.6-0.5,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c2,0.2,3.8,1.1,5.2,2.5
	C44.2,39.8,44.6,42.4,43.4,43.7z M139,32.7C125.4-3,84.1,13,78.1,32.1c-3.4,10.8,5.1,8.9,12.7,3.7c12.9-9.2,27.8-6,25.6,7.5
	c-2.5,14.3-22.9,14.9-44.1,6.7c-15.6-6.1-30-15.1-42.2-26.6v47.5c0,0,51.4,28,91.4,2.1c12.1-7.8,22.9-26.1,17.5-40.4"
        />
        <path className={styles.imgWhite} d="M175.6,85.6l0.1,0.2L175.6,85.6z" />
        <g>
            <path
                className={styles.imgNavy}
                d="M117.2,121.6L100,151.1l-17.2-29.4C68.8,128.2,59,142.4,59,158.9c0,22.7,18.4,41.1,41.1,41.1
		c22.7,0,41.1-18.4,41.1-41.1C141.1,142.4,131.3,128.1,117.2,121.6z"
            />
            <path
                className={styles.imgWhite}
                d="M117.6,169c-4.5-4.1-3.6-8.1-1.6-13.4c2.9-7.6-0.7-16.1-6.8-20.3l-9.2,15.8L90.6,135c-3.5,2.2-6.3,5.6-7.8,9.7
		c-0.9,2.5-1.2,5.1-1.1,7.7c0.4,2.9,1.2,5.7,2.4,8.4c1.7,5.2-2.8,9.7-6.5,12.3c-1.9,1.3-5.5,4-0.9,4.4c2.2,0.1,4.4-0.4,6.4-1.6
		c0.9-0.5,4-2,4.4-3c-1.2,2.8-3.6,7.6-2.1,10.7c1.9,3.8,6.6-3.5,7.5-4.8c0.8-1.3,4.2-8.5,6.2-4.9c1.9,3.3,0.9,8,3.5,10.9
		c3.2,3.6,5-1.9,5-4.6c0-1.7-0.6-10,2.9-6.3c2.1,2.2,5.1,7.4,8.6,7c3.8-0.4-2.2-7.1-2.8-8.2c0.6,0.4,5.3,3.6,5.3,1
		C121.8,171.7,118.8,170.1,117.6,169z"
            />
        </g>
        <path className={styles.imgCyan} d="M96,127V95h9v32H96z" />
        <polygon className={styles.imgCyan} points="77.9,106.3 100,144.1 122,106.3 " />
    </SvgImage>
);
export { PollingTarget };
