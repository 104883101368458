import { TrackJS } from "trackjs";
import { v4 as generateGuid } from "uuid";
import Provenance from "~/provenance";
import { ResourceLoadError, ShouldTrackErrors } from "~/utils/TrackJS";

/*
 * We are enabling TrackJS for *.octopushq.com and *.tentaclearmy sites
 * as an experiment to prove the viability of TrackJS for our purposes.
 * We acknowledge the risk of having the token embedded in the client.
 * We shall mitigate this by using a new token when we are ready to go
 * ahead with TrackJS.
 */

export function installTrackJsIfRequired() {
    if (ShouldTrackErrors()) {
        ResourceLoadError.monitorResourceLoad();
        TrackJS.install({
            token: "c47ae8afe467485f9f9f828957ce610d",
            application: "test",
            network: { error: false },
            onError: (payload: { metadata: { key: string; value: string }[] }) => {
                const errorId = generateGuid();
                console.log("Unique Error Id: ", errorId);
                payload.metadata.push({
                    key: "ErrorId",
                    value: errorId,
                });
                return true;
            },
        });
        TrackJS.addMetadata("commitHash", Provenance.getGitCommitHash());
        TrackJS.addMetadata("commitBranch", Provenance.getGitCommitBranch());
        TrackJS.addMetadata("buildDate", Provenance.getBuildDate());
        TrackJS.addMetadata("buildMode", Provenance.getBuildMode());
    }
}
