import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const ExecuteOctopusServer = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 82 36" title="Execute Octopus Server" {...props}>
        <g id="execute-octopus-server" fillRule="evenodd" strokeWidth="1">
            <path
                className={styles.imgDark}
                d="M25 13.972h14.755v-4.18c-.027-.366.102-.613.386-.742.283-.129.624-.008 1.02.362l4.625 4.56c.375.317.563.621.563.912 0 .291-.115.55-.344.774L41.161 20.4c-.413.3-.753.391-1.02.275-.268-.116-.396-.401-.386-.856v-4.544H25v-1.302z"
            />
            <path className={styles.imgDark} fillRule="nonzero" d="M63.54.37H48.48a.88.88 0 0 0-.88.88v26.92c0 .486.394.88.88.88h32.36a.89.89 0 0 0 .88-.88V1.25a.89.89 0 0 0-.88-.88H65.77" />
            <path
                className={styles.imgLight}
                fillRule="nonzero"
                d="M56.64 20.3c1.47-.95 3.2-2.58 2.54-4.5a11.7 11.7 0 0 1-.92-3.07 6.92 6.92 0 0 1 .42-2.81 7.12 7.12 0 0 1 8.8-4.17c3.29 1 5.55 4.81 4.19 8.14-.79 1.92-1.14 3.4.62 4.89.47.41 1.63 1 1.61 1.73 0 .94-1.85-.21-2.05-.37.23.41 2.56 2.84 1.08 3-1.48.16-2.56-1.74-3.38-2.56-1.38-1.38-1.14 1.66-1.14 2.29 0 1-.71 3-2 1.69-1-1.07-.64-2.8-1.36-4-.72-1.2-2.05 1.32-2.4 1.8-.35.48-2.2 3.14-2.93 1.75-.6-1.12.35-2.88.82-3.9-.17.36-1.38.91-1.72 1.09a4.64 4.64 0 0 1-2.5.58c-1.85-.11-.44-1.1.32-1.58z"
            />
            <circle className={styles.imgCyan} cx="13.6" cy="14.6" r="12.6" fillRule="nonzero" />
            <path
                className={styles.imgWhite}
                fillRule="nonzero"
                d="M7.01 18.554c1.14-.73 2.48-2 2-3.49a9.06 9.06 0 0 1-.71-2.38 5.4 5.4 0 0 1 .32-2.18 5.53 5.53 0 0 1 6.83-3.24 4.93 4.93 0 0 1 3.26 6.32c-.62 1.49-.89 2.64.48 3.8.36.31 1.26.78 1.25 1.34 0 .73-1.44-.16-1.6-.28.19.31 2 2.2.85 2.33-1.15.13-2-1.35-2.63-2-1.07-1.07-.88 1.29-.89 1.78 0 .77-.55 2.33-1.52 1.31-.8-.83-.5-2.17-1.06-3.1-.56-.93-1.63 1-1.89 1.39-.26.39-1.79 2.44-2.35 1.37-.47-.87.27-2.24.64-3-.14.28-1.07.71-1.34.85a3.65 3.65 0 0 1-1.94.44c-1.44-.08-.34-.85.25-1.23l.05-.03z"
            />
            <path
                className={styles.imgDark}
                fillRule="nonzero"
                d="M64.446 26v4.948a3.357 3.357 0 0 0-1.21.769 2.53 2.53 0 0 0-.664 1.12h-5.086c-.324.143-.486.356-.486.637s.162.49.486.628h5.086C63.047 35.367 63.856 36 65 36s1.937-.633 2.38-1.898h5.007c.409-.112.613-.32.613-.628 0-.307-.204-.52-.613-.638H67.38c-.111-.462-.3-.835-.568-1.12-.267-.284-.654-.523-1.159-.716v-5h-1.207z"
            />
        </g>
    </SvgImage>
);
export { ExecuteOctopusServer };
