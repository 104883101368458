import amplitude from "amplitude-js";
import jsSHA from "jssha";
import { getServerHost } from "~/client/utils";
import type { AnalyticFields, AnalyticSession } from "./AnalyticSession";
import { AnalyticsEnvironment } from "./AnalyticSession";
import { NullSession } from "./NullSession";

interface EnvironmentInfo {
    endpoint: string | null;
    forceHttps: boolean;
}

const getEnvironmentInfo = (mode: AnalyticsEnvironment): EnvironmentInfo | null => {
    switch (mode) {
        case AnalyticsEnvironment.ProductionCloud:
            return { endpoint: `${getServerHost()}/api/telemetry/process`, forceHttps: true };
        case AnalyticsEnvironment.Development:
            // By default, telemetry data is proxied through Server to allow it to be centrally audited, controlled, and respect Opt-Out settings, etc.
            // Server-side settings will need to be changed to override the defaults where telemetry is only sent from Octopus Cloud instances.
            return { endpoint: `${getServerHost()}/api/telemetry/process`, forceHttps: false };
    }

    return null;
};

export class AmplitudeSession implements AnalyticSession {
    private api: amplitude.AmplitudeClient;

    private constructor(public readonly environment: AnalyticsEnvironment, info: EnvironmentInfo, installationId: string, userId: string, userName: string, emailAddress?: string) {
        const sha = new jsSHA("SHA-1", "TEXT", { encoding: "UTF8" });
        sha.update(emailAddress ?? `${installationId}-${userId}`);
        const analyticsUserId = sha.getHash("HEX");

        this.api = amplitude.getInstance();
        if (info.endpoint !== null) {
            this.api.options.apiEndpoint = info.endpoint;
            this.api.options.forceHttps = info.forceHttps;
        }
        // The real API token is injected in the Telemetry Proxy before forwarding to Amplitude, but Amplitude.JS requires one anyway.
        this.api.init("dummy-token");
        this.api.setUserId(analyticsUserId);
    }

    public static Start(environment: AnalyticsEnvironment, installationId: string, userId: string, userName: string, emailAddress?: string): AnalyticSession {
        const info = getEnvironmentInfo(environment);
        if (!info) {
            throw new Error("Invalid AnalyticsEnvironment for Amplitude");
        }

        // Must supply an endpoint or token to use amplitude
        if (info.endpoint === null) {
            return new NullSession(environment);
        }

        return new AmplitudeSession(environment, info, installationId, userId, userName, emailAddress);
    }

    track(name: string, event: AnalyticFields): void {
        this.api.logEvent(name, { ...event });
    }

    end() {
        try {
            this.api.setUserId(null);
            this.api.regenerateDeviceId();
        } catch (err) {
            console.error(`(Amplitude) error in 'reset()'`);
            console.error(err);
        }
    }
}
