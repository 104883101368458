/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import type { UserResource, ResourceCollection, ApiKeyResource } from "~/client/resources";
import { repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent/index";
import { ApiKeyExpiryChip } from "~/components/Chips";
import type { DataBaseComponentState, DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import CustomDialog from "~/components/Dialog/CustomDialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { NothingToSeeHere } from "~/components/NothingToSeeHere";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { Section } from "~/components/Section/Section";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import AddApiKeyDialogLayout from "./AddApiKeyDialogLayout";
import DeleteApiKeyDialog from "./DeleteApiKeyDialog";
const styles = require("./style.less");

interface UserApiKeysListProps {
    user: UserResource;
    doBusyTask: DoBusyTask;
}

interface UserApiKeysState extends DataBaseComponentState {
    apiKeyCollection: ResourceCollection<ApiKeyResource>;
    purpose: string;
    lastApiKey: string;
    apiKeyToDelete: ApiKeyResource;
}

class ApiKeyList extends RemoveItemsList<ApiKeyResource> {}

export default class UserApiKeysList extends BaseComponent<UserApiKeysListProps, UserApiKeysState> {
    constructor(props: UserApiKeysListProps) {
        super(props);

        this.state = {
            apiKeyCollection: null!,
            purpose: null!,
            lastApiKey: null!,
            apiKeyToDelete: null!,
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const apiKeyCollection = await repository.Users.listApiKeys(this.props.user);

            this.setState({
                apiKeyCollection,
            });
        });
    }

    render() {
        return (
            <Section>
                {this.state.apiKeyCollection && this.renderApiKeys()}
                {this.state.apiKeyCollection && this.state.apiKeyCollection.Items.length === 0 && <NothingToSeeHere content="There aren't currently any API keys to show." />}
            </Section>
        );
    }

    renderApiKeyDeleteDialog = () => {
        return <DeleteApiKeyDialog open={!!this.state.apiKeyToDelete} onConfirm={() => this.handleKeyRemoved()} onClose={() => this.setState({ apiKeyToDelete: null! })} apiKeyPurpose={this.state.apiKeyToDelete.Purpose} />;
    };

    renderApiKeys = () => {
        const isEnabled = this.props.user.IsRequestor || this.props.user.IsService;
        const actions = [
            <OpenDialogButton
                key="newKey"
                label="New API Key"
                title={isEnabled ? "New API Key" : "API Keys can only be created for yourself or a Service Account"}
                disabled={!isEnabled}
                renderDialog={(renderProps) => (
                    <CustomDialog
                        open={renderProps.open}
                        close={renderProps.closeDialog}
                        render={(dialogProps) => (
                            <AddApiKeyDialogLayout
                                {...dialogProps}
                                apiKey={this.state.lastApiKey}
                                onAdd={(purpose, expires) => this.handleKeyAdded(purpose, expires)}
                                onClose={() => {
                                    renderProps.closeDialog();
                                    this.setState({ lastApiKey: null! });
                                }}
                            />
                        )}
                    />
                )}
            />,
        ];

        return (
            <>
                <ApiKeyList
                    listActions={actions}
                    data={this.state.apiKeyCollection.Items}
                    onRow={(apiKey) => (
                        <div key={apiKey.Id}>
                            <div className={styles.titleContainer}>
                                <h3 className={styles.apiKeyEntry}>{`${apiKey.Purpose?.trim() || "General-purpose API key"} ${this.maskKey(apiKey)}`.trim()}</h3>
                                <div className={styles.apiKeyExpiry}>
                                    <ApiKeyExpiryChip apiKey={apiKey} />
                                </div>
                            </div>
                            <div className={styles.apiKeyCreated}>
                                Created <TimeFromNowLabel time={apiKey.Created} />
                            </div>
                        </div>
                    )}
                    onRemoveRow={(apiKey) => this.setState({ apiKeyToDelete: apiKey })}
                />
                {this.state.apiKeyToDelete && this.renderApiKeyDeleteDialog()}
            </>
        );
    };

    async handleKeyAdded(purpose: string, expires: Date | null): Promise<any> {
        const newKey = await repository.Users.createApiKey(this.props.user, purpose, expires);
        const apiKeyCollection = await repository.Users.listApiKeys(this.props.user);

        this.setState((state) => ({
            apiKeyCollection,
            lastApiKey: newKey.ApiKey,
        }));
    }

    async handleKeyRemoved() {
        const result = await repository.Users.revokeApiKey(this.state.apiKeyToDelete);
        this.setState((state) => ({
            apiKeyCollection: {
                ...state!.apiKeyCollection,
                Items: [...state!.apiKeyCollection.Items.filter((i) => i.Id !== this.state.apiKeyToDelete.Id)],
            },
            apiKeyToDelete: null,
        }));
    }

    static mask = "\u2022".repeat(8); // Unicode of '•' char
    maskKey(apiKey: ApiKeyResource) {
        return apiKey.ApiKey.Hint?.trim() ? `(${apiKey.ApiKey.Hint}${UserApiKeysList.mask})` : "";
    }
}
