/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-restricted-imports */

import { flatten, compact } from "lodash";
import * as React from "react";
import IconButton from "~/components/IconButton/index";
import OpenDialogMenuItem from "~/components/OverflowMenu/OpenDialogMenuItem";
import Divider from "~/primitiveComponents/dataDisplay/Divider/Divider";
import { CustomMenu } from "~/primitiveComponents/navigation/Menu/CustomMenu";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
import { MenuItemButton } from "~/primitiveComponents/navigation/MenuItems/MenuItemButton/MenuItemButton";
import { MenuItemDownloadLink } from "~/primitiveComponents/navigation/MenuItems/MenuItemDownloadLink/MenuItemDownloadLink";
import { MenuItemInformation } from "~/primitiveComponents/navigation/MenuItems/MenuItemInformation/MenuItemInformation";
import { MenuItemInternalLink } from "~/primitiveComponents/navigation/MenuItems/MenuItemInternalLink/MenuItemInternalLink";
import { noOp } from "~/utils/noOp";
import type { DoBusyTask } from "../DataBaseComponent/DataBaseComponent";
import { Icon } from "../IconButton/IconButton";
import type { PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import { isAllowed } from "../PermissionCheck/PermissionCheck";
import OpenDeleteDialogMenuItem from ".//OpenDeleteDialogMenuItem";
import OpenConfirmUpgradeDialogMenuItem from "./OpenConfirmUpgradeDialogMenuItem";
const styles = require("./style.less");

//Please note that we have some props like tabIndex, className and colorOverride which have been ignored for quite some time now.
export const OverflowMenu = ({ menuItems, accessibleName }: OverflowMenuProps) => {
    const [onOpen, menuState, buttonAriaAttributes] = useMenuState();

    const onIconButtonClick: React.MouseEventHandler = React.useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();
            onOpen(event);
        },
        [onOpen]
    );

    return (
        <OverflowMenuItemsRenderer menuItems={menuItems} onClose={menuState.onClose}>
            {({ convertedMenuItems, dialogs }) => (
                <>
                    {dialogs}
                    {convertedMenuItems && convertedMenuItems.length > 0 && (
                        <>
                            <IconButton className={styles.overflowButton} icon={Icon.OverflowMenu} {...buttonAriaAttributes} onClick={onIconButtonClick} accessibleName={accessibleName} />
                            <CustomMenu menuState={menuState} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} accessibleName={accessibleName ?? "Show Overflow Menu"}>
                                {convertedMenuItems}
                            </CustomMenu>
                        </>
                    )}
                </>
            )}
        </OverflowMenuItemsRenderer>
    );
};

enum OverflowMenuItemKind {
    Delete,
    Dialog,
    Generic,
    Disabled,
    Navigation,
    Download,
    Remove,
    ConfirmUpgrade,
}

interface Item {
    kind: OverflowMenuItemKind;
}

export interface OverflowMenuDialogItem extends Item {
    text: string;
    child: JSX.Element;
    permission?: PermissionCheckProps;
}

export interface OverflowMenuDeleteItem extends Item {
    text: string;
    title: string;
    onClick: () => Promise<boolean> | void;
    content: React.ReactNode;
    permission?: PermissionCheckProps;
    deleteButtonDisabled: boolean;
}

export interface OverflowMenuRemoveItem extends Item {
    text: string;
    title: string;
    onClick: () => Promise<boolean> | void;
    content: React.ReactNode;
    permission?: PermissionCheckProps;
    removeButtonDisabled: boolean;
}

export interface OverflowMenuConfirmUpgradeItem extends Item {
    text: string;
    title: string;
    content: React.ReactNode;
    permission?: PermissionCheckProps;
    confirmButtonDisabled: boolean;
    onClick: () => Promise<boolean> | void;
}

export interface OverflowMenuNavLink extends Item {
    text: string;
    path: string;
    permission?: PermissionCheckProps;
}

export interface OverflowMenuDownloadItem extends Item {
    text: string;
    link: string;
    filename: string;
    permission?: PermissionCheckProps;
}

export interface OverflowMenuDisabledItem extends Item {
    text: string;
    reason: string;
}

export interface OverflowMenuGenericItem extends Item {
    text: string;
    title?: string;
    permission?: PermissionCheckProps;
    onClick: () => void;
}

function isGroup(item: MenuItem | MenuItem[] | null | undefined): item is MenuItem[] {
    return Array.isArray(item as MenuItem[]);
}

function isOverflowMenuDialogItem(item: MenuItem): item is OverflowMenuDialogItem {
    return (item as OverflowMenuDialogItem).kind === OverflowMenuItemKind.Dialog;
}

function isOverflowMenuDeleteItem(item: MenuItem): item is OverflowMenuDeleteItem {
    return (item as OverflowMenuDeleteItem).kind === OverflowMenuItemKind.Delete;
}

function isOverflowMenuRemoveItem(item: MenuItem): item is OverflowMenuRemoveItem {
    return (item as OverflowMenuRemoveItem).kind === OverflowMenuItemKind.Remove;
}

function isOverflowMenuConfirmUpgradeItem(item: MenuItem): item is OverflowMenuConfirmUpgradeItem {
    return (item as OverflowMenuConfirmUpgradeItem).kind === OverflowMenuItemKind.ConfirmUpgrade;
}

function isOverflowMenuDownloadItem(item: MenuItem): item is OverflowMenuDownloadItem {
    return (item as OverflowMenuDownloadItem).kind === OverflowMenuItemKind.Download;
}

function isOverflowMenuNavLink(item: MenuItem): item is OverflowMenuNavLink {
    return (item as OverflowMenuNavLink).kind === OverflowMenuItemKind.Navigation;
}

function isOverflowMenuDisabledItem(item: MenuItem): item is OverflowMenuDisabledItem {
    return (item as OverflowMenuDisabledItem).kind === OverflowMenuItemKind.Disabled;
}

function isOverflowMenuGenericItem(item: MenuItem): item is OverflowMenuGenericItem {
    return (item as OverflowMenuGenericItem).kind === OverflowMenuItemKind.Generic;
}

export type MenuItem = OverflowMenuDialogItem | OverflowMenuDeleteItem | OverflowMenuNavLink | OverflowMenuDownloadItem | OverflowMenuGenericItem | OverflowMenuDisabledItem | OverflowMenuRemoveItem | OverflowMenuConfirmUpgradeItem;

interface ConversionResult {
    menuItem: React.ReactNode;
    dialog?: React.ReactNode;
}

interface OverflowMenuProps {
    menuItems: Array<MenuItem | MenuItem[] | undefined | null>;
    tabIndex?: number;
    colorOverride?: string;
    className?: string;
    accessibleName?: string;
}

export class OverflowMenuItems {
    static defaultConfirmUpgradeText = "Please note: This is a blocking task and will prevent deployments during the upgrade.";

    static dialogItem(text: string, child: JSX.Element, permission?: PermissionCheckProps): OverflowMenuDialogItem {
        return { text, child, kind: OverflowMenuItemKind.Dialog, permission };
    }

    static removeItem(text: string, title: string, onClick: () => Promise<boolean> | void, content: React.ReactNode, permission?: PermissionCheckProps, removeButtonDisabled: boolean = false): OverflowMenuRemoveItem {
        return { text, title, onClick, content, permission, kind: OverflowMenuItemKind.Remove, removeButtonDisabled };
    }

    static deleteItem(
        text: string,
        title: string,
        onClick: () => Promise<boolean> | void,
        content: ((doBusyTask: DoBusyTask) => React.ReactNode) | React.ReactNode,
        permission?: PermissionCheckProps,
        deleteButtonDisabled: boolean = false
    ): OverflowMenuDeleteItem {
        return { text, title, onClick, content, permission, kind: OverflowMenuItemKind.Delete, deleteButtonDisabled };
    }

    static deleteItemDefault(name: string, onClick: () => Promise<boolean> | void, permission?: PermissionCheckProps, customMessage?: string, customContent?: JSX.Element, deleteButtonDisabled: boolean = false): OverflowMenuDeleteItem {
        return {
            text: "Delete",
            title: `Are you sure you want to delete this ${name}?`,
            onClick,
            permission,
            kind: OverflowMenuItemKind.Delete,
            deleteButtonDisabled,
            content: (
                <div>
                    {customMessage && <p>{customMessage}</p>}
                    {customContent}
                    {!deleteButtonDisabled && <p>Do you wish to continue?</p>}
                </div>
            ),
        };
    }

    static confirmUpgrade(title: string, onClick: () => Promise<boolean> | void, permission: PermissionCheckProps, customMessage: React.ReactNode = OverflowMenuItems.defaultConfirmUpgradeText): OverflowMenuConfirmUpgradeItem {
        return {
            text: title,
            title,
            onClick,
            permission,
            kind: OverflowMenuItemKind.ConfirmUpgrade,
            confirmButtonDisabled: false,
            content: (
                <div>
                    {customMessage && <p>{customMessage}</p>}
                    <p>Are you sure?</p>
                </div>
            ),
        };
    }

    static navItem(text: string, path: string | undefined, permission?: PermissionCheckProps): OverflowMenuNavLink {
        return { text, path: path!, permission, kind: OverflowMenuItemKind.Navigation };
    }

    static disabledItem(text: string, reason: string): OverflowMenuDisabledItem {
        return { text, reason, kind: OverflowMenuItemKind.Disabled };
    }

    static item(text: string, onClick: () => void, permission?: PermissionCheckProps, title?: string): OverflowMenuGenericItem {
        return { text, onClick, permission, kind: OverflowMenuItemKind.Generic, title };
    }

    static downloadItem(text: string, filename: string, link: string) {
        return { text, link, filename, kind: OverflowMenuItemKind.Download };
    }
}

export interface OverflowMenuItemsRendererProps {
    menuItems: Array<MenuItem | MenuItem[] | undefined | null>;
    onClose: () => void;
    children: (renderProps: { convertedMenuItems: React.ReactNode[]; dialogs: React.ReactNode }) => React.ReactElement | null;
}

export const OverflowMenuItemsRenderer: React.FC<OverflowMenuItemsRendererProps> = (props) => {
    const renderProps = OverflowConversions.convertAll(props.menuItems, props.onClose);
    return props.children(renderProps);
};

class OverflowConversions {
    static convert(item: MenuItem | null | undefined, onClose: () => void): ConversionResult | null {
        if (!item) {
            return null;
        }

        if (isOverflowMenuDialogItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return OverflowConversions.convertDialogMenuItem(item);
        }

        if (isOverflowMenuDeleteItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return OverflowConversions.convertDeleteMenuItem(item);
        }

        if (isOverflowMenuRemoveItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return OverflowConversions.convertRemoveMenuItem(item);
        }

        if (isOverflowMenuConfirmUpgradeItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return OverflowConversions.convertConfirmUpgradeMenuItem(item);
        }

        if (isOverflowMenuDownloadItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return {
                menuItem: <MenuItemDownloadLink key={item.text} downloadFileName={item.filename} href={item.link} label={item.text} />,
            };
        }

        if (isOverflowMenuNavLink(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowConversions.alternate(item.permission.alternate);
            }
            return {
                menuItem: <MenuItemInternalLink key={item.text} path={item.path} label={item.text} />,
            };
        }

        if (isOverflowMenuDisabledItem(item)) {
            return {
                menuItem: (
                    <MenuItemButton key={item.text} disabled={true} disableReason={item.reason}>
                        {item.text}
                    </MenuItemButton>
                ),
            };
        }

        if (isOverflowMenuGenericItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
        }

        return {
            menuItem: (
                <MenuItemButton
                    key={item.text}
                    onClick={(e) => {
                        //Generic menu items could possibly keep focus so we explicitly close these.
                        onClose();
                        if (item.onClick) {
                            //If explicitlyhandling click, we really should prevent default behaviors such as links triggering
                            e.preventDefault();
                            item.onClick();
                        }
                    }}
                    title={item.title}
                >
                    {item.text}
                </MenuItemButton>
            ),
        };
    }

    static convertAll(sourceMenuItems: Array<MenuItem | MenuItem[] | undefined | null>, onClose: () => void) {
        if (!sourceMenuItems) {
            return {
                dialogs: [],
                convertedMenuItems: [],
            };
        }

        const result =
            sourceMenuItems &&
            sourceMenuItems
                .filter((item) => !!item)
                .map((item, index) => {
                    if (isGroup(item)) {
                        const results = item.filter((subItem) => !!subItem).map((groupItem) => OverflowConversions.convert(groupItem, onClose));
                        if (results.length === 0) {
                            return null;
                        }
                        // This should be smart enough to know if it needs to add a divider at the start or end of a grouping/array.
                        if (index > 0 && !isGroup(sourceMenuItems[index - 1])) {
                            // Show the divider at the start of this grouping.
                            // I.e. The last thing wasn't a group, so we're good to create one here to indicate the start of a grouping.
                            results.splice(0, 0, { menuItem: <Divider key={index} /> });
                        } else if (index < sourceMenuItems.length - 1) {
                            // Show the divider at the end of this grouping.
                            results.push({ menuItem: <Divider key={index} /> });
                        }
                        return results;
                    }
                    return [OverflowConversions.convert(item, onClose)];
                });
        const dialogs: React.ReactNode[] = [];
        const convertedMenuItems = compact(flatten(result)).map((r) => {
            if (r.dialog) {
                dialogs.push(r.dialog);
            }
            return r.menuItem;
        });

        return { dialogs, convertedMenuItems };
    }

    static convertDeleteMenuItem(item: OverflowMenuDeleteItem) {
        let openDialog: () => void;
        const dialogMenuItem = (
            <OpenDeleteDialogMenuItem
                acceptOnClick={(click) => (openDialog = click)}
                label={item.text}
                disabled={false}
                deleteButtonDisabled={item.deleteButtonDisabled}
                dialogTitle={item.title}
                key={item.text}
                onDeleteClick={item.onClick}
                renderContent={(doBusyTask: DoBusyTask) => {
                    if (typeof item.content === "function") {
                        return item.content(doBusyTask);
                    }
                    return item.content;
                }}
            />
        );

        return {
            menuItem: (
                <MenuItemButton key={item.text} onClick={() => (openDialog ? openDialog() : noOp())}>
                    {item.text}
                </MenuItemButton>
            ),
            dialog: dialogMenuItem,
        };
    }

    static convertRemoveMenuItem(item: OverflowMenuRemoveItem) {
        let openDialog: () => void;
        const dialogMenuItem = (
            <OpenDeleteDialogMenuItem
                acceptOnClick={(click) => (openDialog = click)}
                label={item.text}
                disabled={false}
                deleteButtonDisabled={item.removeButtonDisabled}
                dialogTitle={item.title}
                deleteButtonLabel="Remove"
                deleteButtonBusyLabel="Removing"
                key={item.text}
                onDeleteClick={item.onClick}
                renderContent={() => item.content}
            />
        );

        return {
            menuItem: (
                <MenuItemButton key={item.text} onClick={() => (openDialog ? openDialog() : noOp())}>
                    {item.text}
                </MenuItemButton>
            ),
            dialog: dialogMenuItem,
        };
    }

    static convertConfirmUpgradeMenuItem(item: OverflowMenuConfirmUpgradeItem) {
        let openDialog: () => void;
        const dialogMenuItem = (
            <OpenConfirmUpgradeDialogMenuItem
                acceptOnClick={(click) => (openDialog = click)}
                label={item.text}
                disabled={false}
                dialogTitle={item.title}
                confirmButtonDisabled={item.confirmButtonDisabled}
                confirmButtonLabel="Continue"
                confirmButtonBusyLabel="Continuing"
                key={item.text}
                onConfirmUpgradeClick={() => item.onClick()}
                renderContent={() => item.content}
            />
        );

        return {
            menuItem: (
                <MenuItemButton key={item.text} onClick={() => (openDialog ? openDialog() : noOp())}>
                    {item.text}
                </MenuItemButton>
            ),
            dialog: dialogMenuItem,
        };
    }

    static convertDialogMenuItem(item: OverflowMenuDialogItem) {
        let openDialog: () => void;
        //TODO: Find an alternative to this callback to get the click handler which doesn't result in overflow menus remaining open etc.
        const dialogMenuItem = (
            <OpenDialogMenuItem
                acceptOnClick={(click) => {
                    openDialog = click;
                }}
                label={item.text}
                key={item.text}
            >
                {item.child}
            </OpenDialogMenuItem>
        );

        return {
            menuItem: (
                <MenuItemButton key={item.text} onClick={() => (openDialog ? openDialog() : noOp())}>
                    {item.text}
                </MenuItemButton>
            ),
            dialog: dialogMenuItem,
        };
    }

    static alternate(alternate: string) {
        return alternate ? { menuItem: <MenuItemInformation key={alternate}>{alternate}</MenuItemInformation> } : null;
    }
}
