import * as React from "react";
import { useEffect, useState } from "react";
import { MachinesContentRow } from "~/areas/infrastructure/components/InfrastructureDashboard/MachinesCard/MachinesContentRow";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import type { SummaryResource } from "~/client/resources";
import { CommunicationStyle } from "~/client/resources";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import styles from "../style.less";

interface MachinesContentProps {
    summaryResource: SummaryResource;
    link: (obj: object) => string;
    doBusyTask: DoBusyTask;
}

export const WorkerContent = ({ summaryResource, link, doBusyTask }: MachinesContentProps) => {
    const [registrations, setRegistrations] = useState<EndpointRegistration[]>([]);
    useEffect(() => {
        (async () => {
            await doBusyTask(async () => {
                const loadedRegistrations = await endpointRegistry.getAllRegistrations();
                setRegistrations(loadedRegistrations);
            });
        })();
    }, [doBusyTask]);
    return (
        <>
            {registrations?.length > 0 && (
                <div className={styles.cardContentContainerRows}>
                    {Object.keys(summaryResource.MachineEndpointSummaries).map((keyString) => {
                        const registration = registrations.find((r) => r.key === keyString);
                        if (registration?.communicationStyle !== CommunicationStyle.StepPackage) return <MachinesContentRow key={keyString} deploymentTargetType={keyString} link={link} summaries={summaryResource.MachineEndpointSummaries} />;
                    })}
                </div>
            )}
        </>
    );
};
