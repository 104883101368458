import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const ExecuteOctopusServerRoles = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 151 47" title="Execute Octopus Server Roles" {...props}>
        <g id="execute-octopus-server-roles" fillRule="evenodd" strokeWidth="1">
            <rect className={styles.imgBlueGrey} width="107.38" height="45.87" x="43" y="0" fillRule="nonzero" rx="1.87" />
            <path
                className={styles.imgDark}
                id="Path-2"
                d="M25.77 19.34h14.756v-4.18c-.027-.365.102-.612.385-.741.284-.13.625-.009 1.021.362l4.625 4.56c.375.317.563.62.563.912 0 .291-.115.55-.344.774l-4.844 4.741c-.413.3-.753.39-1.02.275-.268-.116-.396-.401-.386-.856v-4.544H25.771v-1.302z"
            />
            <rect className={styles.imgLight} width="12.75" height="11.75" x="110.5" y="14" fillRule="nonzero" />
            <rect className={styles.imgLight} width="13" height="11" x="90.5" y="14.75" fillRule="nonzero" />
            <rect className={styles.imgWhite} width="28.25" height="23.75" x="50.75" y="8" fillRule="nonzero" />
            <rect className={styles.imgLight} width="13.25" height="12.25" x="130" y="14.25" fillRule="nonzero" />
            <path
                className={styles.imgDark}
                fillRule="nonzero"
                d="M98.75 19.07h.73a3 3 0 0 0-2-2v.73a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.72a.43.43 0 0 1 .43.43v.85a.43.43 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2h-.73a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43z"
            />
            <path
                className={styles.imgDark}
                fillRule="nonzero"
                d="M97.16 26.82h7.24a.42.42 0 0 0 .42-.42V13.47a.42.42 0 0 0-.42-.42H88.85a.42.42 0 0 0-.42.42V26.4c0 .232.188.42.42.42h7.24m5.64-6.47a.43.43 0 0 1-.43.42h-1a3.83 3.83 0 0 1-2.87 2.88v1a.43.43 0 0 1-.43.42h-.8a.42.42 0 0 1-.42-.42v-1a3.83 3.83 0 0 1-2.88-2.88H92a.43.43 0 0 1-.43-.42v-.85a.43.43 0 0 1 .43-.43h1a3.83 3.83 0 0 1 2.88-2.88v-1a.42.42 0 0 1 .42-.42h.85a.43.43 0 0 1 .43.42v1a3.83 3.83 0 0 1 2.87 2.88h1a.43.43 0 0 1 .43.43l-.15.85zm17.02-1.28h.73a3 3 0 0 0-2-2v.73a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.72a.43.43 0 0 1 .43.43v.85a.43.43 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2h-.73a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43z"
            />
            <path
                className={styles.imgDark}
                fillRule="nonzero"
                d="M117.16 26.82h7.24a.42.42 0 0 0 .42-.42V13.47a.42.42 0 0 0-.42-.42h-15.55a.42.42 0 0 0-.42.42V26.4c0 .232.188.42.42.42h7.24m5.64-6.47a.43.43 0 0 1-.43.42h-1a3.83 3.83 0 0 1-2.87 2.88v1a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-1a3.83 3.83 0 0 1-2.88-2.88h-1a.43.43 0 0 1-.43-.42v-.85a.43.43 0 0 1 .43-.43h1a3.83 3.83 0 0 1 2.88-2.88v-1a.42.42 0 0 1 .42-.42h.85a.43.43 0 0 1 .43.42v1a3.83 3.83 0 0 1 2.87 2.88h1a.43.43 0 0 1 .43.43v.85zm17.02-1.28h.73a3 3 0 0 0-2-2v.73a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.72a.43.43 0 0 1 .43.43v.85a.43.43 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2h-.73a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43z"
            />
            <path
                className={styles.imgDark}
                fillRule="nonzero"
                d="M137.16 26.82h7.24a.42.42 0 0 0 .42-.42V13.47a.42.42 0 0 0-.42-.42h-15.55a.42.42 0 0 0-.42.42V26.4c0 .232.188.42.42.42h7.24m5.64-6.47a.43.43 0 0 1-.43.42h-1a3.83 3.83 0 0 1-2.87 2.88v1a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-1a3.83 3.83 0 0 1-2.88-2.88h-1a.43.43 0 0 1-.43-.42v-.85a.43.43 0 0 1 .43-.43h1a3.83 3.83 0 0 1 2.88-2.88v-1a.42.42 0 0 1 .42-.42h.85a.43.43 0 0 1 .43.42v1a3.83 3.83 0 0 1 2.87 2.88h1a.43.43 0 0 1 .43.43v.85z"
            />
            <path className={styles.imgDark} fillRule="nonzero" d="M63.57 5.37H48.51a.88.88 0 0 0-.88.88v26.92c0 .486.394.88.88.88h32.36a.88.88 0 0 0 .88-.88V6.25a.88.88 0 0 0-.88-.88H65.8" />
            <circle className={styles.imgCyan} cx="13.42" cy="19.93" r="12.6" fillRule="nonzero" />
            <path
                className={styles.imgWhite}
                fillRule="nonzero"
                d="M7.2 23.87c1.14-.74 2.48-2 2-3.49A9.06 9.06 0 0 1 8.46 18a5.45 5.45 0 0 1 .32-2.19 5.53 5.53 0 0 1 6.83-3.23 4.92 4.92 0 0 1 3.26 6.31c-.62 1.5-.89 2.64.48 3.8.36.32 1.26.79 1.25 1.34 0 .74-1.44-.16-1.6-.28.19.32 2 2.2.85 2.33-1.15.13-2-1.35-2.63-2-1.07-1.07-.88 1.28-.89 1.77 0 .78-.55 2.33-1.52 1.32-.8-.84-.5-2.17-1.06-3.11s-1.63 1-1.89 1.39c-.26.39-1.71 2.44-2.27 1.36-.47-.87.27-2.24.64-3-.14.29-1.07.71-1.34.85A3.56 3.56 0 0 1 7 25.1c-1.44-.09-.34-.85.25-1.23H7.2z"
            />
            <path
                className={styles.imgLight}
                fillRule="nonzero"
                d="M56.15 24.8c1.48-.95 3.2-2.58 2.55-4.5a11.33 11.33 0 0 1-.92-3.07 7.09 7.09 0 0 1 .41-2.81A7.13 7.13 0 0 1 67 10.25c3.29 1 5.55 4.81 4.19 8.14-.79 1.92-1.13 3.4.62 4.89.48.41 1.63 1 1.62 1.73 0 .94-1.85-.21-2.06-.37.24.41 2.57 2.84 1.09 3-1.48.16-2.56-1.74-3.38-2.56-1.38-1.38-1.14 1.66-1.15 2.29 0 1-.71 3-2 1.69-1-1.07-.65-2.8-1.37-4-.72-1.2-2.1 1.31-2.43 1.79-.33.48-2.13 3.15-2.9 1.76-.59-1.12.36-2.88.83-3.9-.18.36-1.38.91-1.73 1.09a4.63 4.63 0 0 1-2.49.58c-1.85-.11-.44-1.1.31-1.58z"
            />
            <path
                className={styles.imgDark}
                fillRule="nonzero"
                d="M63.446 32v4.948a3.357 3.357 0 0 0-1.21.769 2.53 2.53 0 0 0-.664 1.12h-5.086c-.324.143-.486.356-.486.637s.162.49.486.628h5.086C62.047 41.367 62.856 42 64 42s1.937-.633 2.38-1.898h5.007c.409-.112.613-.32.613-.628 0-.307-.204-.52-.613-.638H66.38c-.111-.462-.3-.835-.568-1.12-.267-.284-.654-.523-1.159-.716v-5h-1.207z"
            />
            <path
                className={styles.imgDark}
                fillRule="nonzero"
                d="M96.121 26v2.799a1.119 1.119 0 0 0-.526.334c-.158.17-.282.404-.37.703H90.51c-.29.097-.436.261-.436.491 0 .23.146.394.436.49h4.813c.322.574.774.86 1.357.86s1.032-.286 1.346-.86h17.283c.347.574.787.86 1.321.86.636 0 1.105-.286 1.405-.86h17.298c.292.574.724.86 1.297.86 1.05 0 1.382-.802 1.384-.813l4.28-.047c.297-.059.446-.222.446-.49 0-.26-.149-.424-.446-.491l-4.175-.043c-.07-.32-.21-.557-.42-.711-.21-.155-.4-.27-.571-.346V26.57h-1c.024 1.485.024 2.228 0 2.228-.015 0-.259.07-.564.377-.102.102-.216.322-.343.66h-17.087a1.718 1.718 0 0 0-.38-.703 1.449 1.449 0 0 0-.607-.397v-2.214h-.995l-.045 2.277c-.255.131-.438.257-.55.377-.112.12-.215.326-.308.617l-17.114.043a1.455 1.455 0 0 0-.398-.703 1.534 1.534 0 0 0-.621-.397V26h-.995z"
            />
        </g>
    </SvgImage>
);
export { ExecuteOctopusServerRoles };
