import cn from "classnames";
import * as React from "react";
import { withTheme } from "~/components/Theme";
const styles = require("./style.less");

interface WarningIconProps {
    color?: string;
    iconOverride?: string;
}

export default class WarningIcon extends React.Component<WarningIconProps> {
    render() {
        return withTheme((theme) => {
            const color = this.props.color ? this.props.color : theme.alert;
            return <em className={cn(`fa ${this.props.iconOverride ? this.props.iconOverride : "fa-warning"}`, styles.warningIcon)} style={{ color }} />;
        });
    }
}
