import * as React from "react";
import styles from "./MachineIcon.less";

type MachineIconProps = {
    imageUrl: string;
};

export function MachineIcon(props: MachineIconProps) {
    return (
        <div className={styles.machineImage}>
            <img src={props.imageUrl} className={styles.machineIcon} alt="Machine" />
        </div>
    );
}
