import React from "react";
import CopyToClipboard from "~/components/CopyToClipboardButton/CopyToClipboardButton";
const styles = require("./style.less");

const CopyValueToClipboard: React.FC<{ value: string }> = ({ value }) => {
    return (
        <div className={styles.copyValue}>
            <strong>{value}</strong>
            <CopyToClipboard value={value} />
        </div>
    );
};

export default CopyValueToClipboard;
