import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const ShipmentOk = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 178 148" title="Package successful" {...props}>
        <g id="package" transform="translate(1 1)">
            <g id="success" transform="translate(95 80.333)">
                <path
                    d="M59.158 47.515c5.763-9.884 5.763-22.131.015-31.99A29.743 29.743 0 0 0 48.01 4.35 31.141 31.141 0 0 0 32.02.012h-.052a31.519 31.519 0 0 0-15.909 4.314C1.07 13.096-3.988 32.426 4.784 47.415a30.356 30.356 0 0 0 11.185 11.27c9.867 5.752 22.114 5.753 31.973.006a29.725 29.725 0 0 0 11.216-11.176"
                    className={styles.imgGreen}
                    mask="url(#mask-2)"
                />
            </g>
            <polygon points="146.128 92.031 120.031 118.207 107.812 105.909 101.883 111.838 120.027 130.165 152.042 98.138" className={styles.imgWhite} />
            <path
                d="M36 0a26.51 26.51 0 0 0-9.75 2.25L2.47 13.83a.21.21 0 0 0 .09.41h56.98L72.516 0H36zm116.9 13.79L129.23 2.25A26.51 26.51 0 0 0 119.48 0H82.924L96.04 14.24h56.75a.239.239 0 0 0 .234-.246.242.242 0 0 0-.124-.204M97 21.11v38.11H58.24V21.11H0v112.94a3.44 3.44 0 0 0 3.44 3.44h96.519a36.022 36.022 0 0 1-5.12-6.886c-5.002-8.548-6.381-18.566-3.866-28.18 2.515-9.615 8.625-17.673 17.201-22.693a37.24 37.24 0 0 1 18.793-5.096c.082-.002.165 0 .248 0a36.638 36.638 0 0 1 18.659 5.109 35.289 35.289 0 0 1 9.316 7.693V21.11H97z"
                className={styles.imgDark}
            />
        </g>
    </SvgImage>
);
export { ShipmentOk };
