import * as React from "react";
import { Provider } from "react-redux";
import DynamicExtensionsLoader from "~/areas/dynamicExtensions/dynamicExtensionsLoader";
import { ClientConnector } from "~/areas/infrastructure/components/ClientConnector/ClientConnector";
import { ConsoleErrorCapturer } from "~/components/ConsoleErrors/ConsoleErrorCapturer";
import { ConsoleErrorDisplayer } from "~/components/ConsoleErrors/ConsoleErrorDisplayer";
import { DevToolsContextProvider } from "~/components/DevTools/DevToolsContext";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary";
import { ErrorContextProvider } from "~/components/ErrorContext/ErrorContext";
import { OctopusRouter } from "~/components/OctopusRouter/OctopusRouter";
import store from "~/store";
import type { CapturedClientMetricsProvider } from "./captureClientMetrics";
import { ThemeFromLocalStorage } from "./components/Theme/ThemeFromLocalStorage";

const App: React.FC<{ capturedMetrics: CapturedClientMetricsProvider }> = ({ capturedMetrics }) => {
    return (
        <ConsoleErrorCapturer>
            <ErrorBoundary>
                <ErrorContextProvider>
                    <Provider store={store}>
                        <ThemeFromLocalStorage>
                            <ConsoleErrorDisplayer />
                            <ClientConnector
                                renderWhenConnected={() => (
                                    <DevToolsContextProvider clientMetricsProvider={capturedMetrics}>
                                        <DynamicExtensionsLoader />
                                        <OctopusRouter />
                                    </DevToolsContextProvider>
                                )}
                            />
                        </ThemeFromLocalStorage>
                    </Provider>
                </ErrorContextProvider>
            </ErrorBoundary>
        </ConsoleErrorCapturer>
    );
};

export default App;
