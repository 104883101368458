import * as React from "react";
import PaperLayout from "~/components/PaperLayout";
import Section from "~/components/Section";
import { FormSectionHeading } from "~/components/form";
import Divider from "~/primitiveComponents/dataDisplay/Divider";
const styles = require("./UxGuidelinesCheatSheet.less");

const UxGuidelines: React.FC = () => {
    return (
        <PaperLayout title={"Guidelines"}>
            <Section>
                <h4>
                    From multiple workshops with our People we produced three UX Guidelines we think contribute to Octopus having a good user experience. These guidelines can be used when developing new features or updating existing features to
                    ensure a good standard of UX is maintained and reduce future usability debt.
                </h4>
            </Section>
            <FormSectionHeading title={"Be familiar and consistent"} />
            <Divider />
            <Section>
                <p>
                    We want users to have a sense of familiarity when using Octopus which will make the product easy to use and intuitive. We achieve this by reducing the user’s cognitive load, matching a user’s mental model, using existing
                    conventions and being consistent.
                </p>
                <h4>Consider</h4>
                <ul className={styles.list}>
                    <li>Reducing the number of decisions and information available to a user (reducing noise). </li>
                    <li>Using existing conventions and terminology familiar to the user, requiring less effort and reducing the learning curve.</li>
                    <li>Using consistent patterns and labels.</li>
                </ul>
                <h4>Ask</h4>
                <ul className={styles.list}>
                    <li>Is the action I want the user to take obvious? Are there competing objectives?</li>
                    <li>Will the user be familiar with the terminology? Is there a learning curve?</li>
                    <li>Is the functionality matching a user's mental model?</li>
                    <li>Is the UI explaining the functionality that the user would expect?</li>
                    <li>Is there an existing pattern or terminology in the product I can use?</li>
                </ul>
            </Section>
            <FormSectionHeading title={"Be responsive and helpful"} />
            <Divider />
            <Section>
                <p>
                    We want to avoid surprises and not keep our users in the dark. We can help our users by making sure they have context, know the expected result of their action, receive feedback for their action, and have access to information
                    that will help them complete their task.
                </p>
                <h4>Consider</h4>
                <ul className={styles.list}>
                    <li>Being responsive by giving the user visual feedback for actions.</li>
                    <li>Providing concise help text in the form of a Note (for one-liners) or in the contextual help bar (when more help may be required).</li>
                    <li>Informing the user of the system status.</li>
                    <li>Providing context and hierarchy to help the user know where they are.</li>
                </ul>
                <h4>Ask</h4>
                <ul className={styles.list}>
                    <li>Does the user need feedback for the action they just performed? Either warning, confirmation or error?</li>
                    <li>Is there information the user needs to help them complete the task?</li>
                    <li>Does the user have the context of where they are in the product?</li>
                    <li>Is there a way for the user to get back to where they were?</li>
                    <li>Is the user waiting for something to happen?</li>
                </ul>
            </Section>
            <FormSectionHeading title={"Build trust and credibility"} />
            <Divider />
            <Section>
                <p>
                    We want our users to confidently explore, experiment and recommend our product. Our system needs to appear reliable and credible. Performance, error states and unintended consequences of actions can make the product appear
                    unreliable and degrade a user’s trust.
                </p>
                <h4>Consider</h4>
                <ul className={styles.list}>
                    <li>Preventing error states the user doesn’t need to be in.</li>
                    <li>Helping the user out of error states.</li>
                    <li>Allowing users to explore the product without fear of penalty or consequences.</li>
                    <li>Improving the performance (and perceived performance) of the product will help provide a positive experience for customers at scale.</li>
                </ul>
                <h4>Ask</h4>
                <ul className={styles.list}>
                    <li>If the user starts to explore away from the critical path, will information be lost and will the user be able to find their way back?</li>
                    <li>Does the user need to be in this error state? How can I prevent the user from getting into this error state? How can I help the user get out of the error state?</li>
                    <li>At scale, is the performance going to be compromised? Will the user think negatively of our brand as a result of slow performance, opening the door to competitors?</li>
                </ul>
            </Section>
        </PaperLayout>
    );
};

export default UxGuidelines;
