import MaterialMenuItem from "@material-ui/core/MenuItem";
import cn from "classnames";
import type { MouseEventHandler, PropsWithChildren } from "react";
import React, { forwardRef } from "react";
import type { MenuTargetAriaAttributes } from "~/primitiveComponents/navigation/Menu/useMenuState";
import styles from "./styles.less";

export type MenuItemProps = EnabledMenuItemProps | DisabledMenuItemProps;

interface CommonMenuItemProps {
    isSelected?: boolean;
    compact?: boolean;
}

interface EnabledMenuItemProps extends CommonMenuItemProps {
    onClick: MouseEventHandler;
    autoFocus?: boolean;
    disabled?: false;
    title?: string;
    menuButtonAttributes?: MenuTargetAriaAttributes;
}

interface DisabledMenuItemProps extends CommonMenuItemProps {
    // Note: we also don't need autoFocus here
    disabled: true;
    disableReason?: string;
}

function isDisabledMenuItemProps(props: MenuItemProps): props is DisabledMenuItemProps {
    return props.disabled === true;
}

// The forwardRef here is not important, but needed to prevent console errors that would otherwise say a ref hasn't been appropriately forwarded.
// This requirement has been removed in later versions of material-ui (v5 onwards).
// See https://github.com/mui-org/material-ui/pull/25691/files#diff-ad4b8459eb1cd3c5e6882eb705e3e341e551bd7bf4ab9a6941ef12017eb1cb06L177-L188
export const MenuItemButton = forwardRef<HTMLButtonElement, PropsWithChildren<MenuItemProps>>((props, forwardedRef) => {
    const onClick = "onClick" in props ? props.onClick : undefined;
    const classes = cn(styles.menuItem, props.isSelected ? styles.selected : "", props.compact ? styles.compact : styles.overrideMinHeightMediaQueryFromMaterialUI, styles.overrideColorsFromMaterialUI, props.disabled ? styles.disabled : undefined);

    if (isDisabledMenuItemProps(props)) {
        //We need to wrap the menu item in a ToolTip when it's disabled to show the reason as a disabled menu item will also not
        //show the tooltip from the title
        return (
            <MaterialMenuItem classes={{ root: styles.disabled }} component={"button"} button={true} ref={forwardedRef} className={classes} disabled={props.disabled} title={props.disableReason}>
                {props.children}
            </MaterialMenuItem>
        );
    }

    const { autoFocus, children, disabled, menuButtonAttributes } = props;
    return (
        <MaterialMenuItem component={"button"} button={true} autoFocus={autoFocus} disabled={disabled} className={classes} onClick={onClick} ref={forwardedRef} title={props.title} {...menuButtonAttributes}>
            {children}
        </MaterialMenuItem>
    );
});
