import * as React from "react";
import { CloseButton } from "~/components/Button";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import SmoothScroll from "~/components/SmoothScroll/SmoothScroll";
import Note from "~/primitiveComponents/form/Note/Note";
const styles = require("./style.less");

type WarningPanelProps = WarningListProps &
    WarningTitleProps &
    WarningHelpProps & {
        fullWidth?: boolean;
        scrollToPanel?: boolean;
    };

type WarningDetailProps = {
    warning: string;
    index: number;
    parsedHelpLinks?: string[];
};

const WarningDetail: React.FC<WarningDetailProps> = ({ warning, index, parsedHelpLinks }) => {
    const detailLinks = parsedHelpLinks;
    const detailLink = detailLinks && detailLinks.length > index && detailLinks[index];
    return (
        <li key={index}>
            {warning}
            {detailLink && (
                <div className={styles.detailLink}>
                    <ExternalLink href={detailLink}>More information...</ExternalLink>
                </div>
            )}
        </li>
    );
};

type WarningListProps = {
    warnings: string[];
    parsedHelpLinks?: string[];
};

const WarningsList: React.FC<WarningListProps> = (props) => {
    return (
        <React.Fragment>
            {props.warnings.length > 0 && (
                <ul>
                    {props.warnings.map((e, index) => (
                        <WarningDetail index={index} warning={e} parsedHelpLinks={props.parsedHelpLinks} />
                    ))}
                </ul>
            )}
        </React.Fragment>
    );
};

type WarningTitleProps = {
    canClose?: boolean;
    onWarningClose?: () => void;
    message: string;
};

const WarningTitle: React.FC<WarningTitleProps> = ({ onWarningClose, canClose, message }) => {
    return (
        <div className={styles.titleContainer}>
            <span className={styles.title}>{message}</span>
            <div>{canClose && <CloseButton size="1.5rem" onClose={onWarningClose} />}</div>
        </div>
    );
};

type WarningHelpProps = {
    helpText?: string;
    helpLink?: string;
};

const WarningHelp: React.FC<WarningHelpProps> = ({ helpLink, helpText }) => {
    return (
        <React.Fragment>
            {helpText && (
                <div className={styles.note}>
                    <Note>{helpText}</Note>
                </div>
            )}
            {helpLink && <ExternalLink href={helpLink}>More information...</ExternalLink>}
        </React.Fragment>
    );
};

const WarningPanel: React.FC<WarningPanelProps> = ({ fullWidth = true, scrollToPanel = true, ...props }) => {
    const panelRef = React.createRef<HTMLDivElement>();

    React.useEffect(() => {
        if (scrollToPanel && panelRef.current) {
            SmoothScroll.scrollTo(panelRef.current);
        }
    }, [panelRef, scrollToPanel]);

    return (
        <div
            ref={panelRef}
            className={styles.warning}
            style={{
                display: fullWidth ? "block" : "inline-block",
                width: fullWidth ? "100%" : "auto",
            }}
        >
            <WarningTitle message={props.message} canClose={props.canClose} onWarningClose={props.onWarningClose} />
            {props.warnings && <WarningsList warnings={props.warnings} />}
            <WarningHelp helpLink={props.helpLink} helpText={props.helpText} />
        </div>
    );
};

export default WarningPanel;
