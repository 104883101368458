import * as React from "react";
import Divider from "~/primitiveComponents/dataDisplay/Divider/Divider";
const styles = require("./style.less");

export enum SidebarSide {
    Right,
    Left,
}

interface SidebarLayoutProps {
    sideBar?: React.ReactNode;
    side?: SidebarSide; // Defaults to right
    // className is applied to the full panel, useful if you want to do something that applies outside of the padding, like a background color
    extendContentToEdges?: boolean; // If true, disables padding on the body
    // If true, disables padding on the sidebar
    // I think ideally, we always extend to the edges. The content of the sidebar can be inside <Section>s if they want padding
    extendSidebarToEdges?: boolean;
    hideTopDivider?: boolean;
    hideSidebarDivider?: boolean;
    overflowXHidden?: boolean; // We don't want this to apply generally, because it can break things in places like the variable editor
    flexBasis?: string;
}

const SidebarLayout: React.SFC<SidebarLayoutProps> = (props) => {
    const sidebarSide = props.side ? props.side : SidebarSide.Right;

    return (
        <div className={styles.bodyWithSides}>
            {sidebarSide === SidebarSide.Left && renderSidebar(styles.sideBarLeft, props)}
            <div className={`${styles.main} ${props.overflowXHidden ? styles.overflowXHidden : ""}`}>
                {props.sideBar && !props.hideTopDivider ? <Divider fullHeight={true} /> : null}
                <div className={!props.extendContentToEdges && props.sideBar ? styles.mainContent : ""}>{props.children}</div>
            </div>
            {sidebarSide === SidebarSide.Right && renderSidebar(styles.sideBarRight, props)}
        </div>
    );
};

function renderSidebar(dividerClassName: string, props: SidebarLayoutProps) {
    if (!props.sideBar) {
        return null;
    }
    const className = `${styles.sidebarContainer} ${props.hideSidebarDivider ? "" : dividerClassName} ${styles.sideBar}`;
    const containerStyle: React.CSSProperties = {};
    if (props.flexBasis) {
        containerStyle.flexBasis = props.flexBasis;
    }
    return (
        <div className={className} style={containerStyle}>
            {!props.hideTopDivider ? <Divider fullHeight={true} /> : null}
            <div className={`${props.extendSidebarToEdges ? "" : styles.sideBarPadding}`}>{props.sideBar}</div>
        </div>
    );
}

export default SidebarLayout;
