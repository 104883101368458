/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as _ from "lodash";
import { cloneDeep, debounce, flatten, groupBy, union } from "lodash";
import * as React from "react";
import RulesTester from "~/areas/projects/components/Channels/RulesTester/RulesTester";
import type { ChannelVersionRuleResource, DeploymentActionResource, VersionRuleTestResponse } from "~/client/resources";
import { PackageReferenceNamesMatch } from "~/client/resources";
import type { FeedResource } from "~/client/resources/feedResource";
import { FeedType } from "~/client/resources/feedResource";
import type ProjectResource from "~/client/resources/projectResource";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Section } from "~/components/Section/Section";
import { Note, Text } from "~/components/form";
import isBound from "~/components/form/BoundField/isBound";
import type { ResourcesById } from "../../../../client/repositories/basicRepository";
import { FeedTypeSelect } from "../../../../components/FeedTypeSelect/FeedTypeSelect";
const styles = require("./style.less");

interface DesignRuleProps {
    model: ChannelVersionRuleResource;
    project: ProjectResource;
    deploymentActions: DeploymentActionResource[];
    onOkClick: (newModel: ChannelVersionRuleResource) => void;
}

interface DesignRuleState extends DataBaseComponentState {
    previewText: string;
    versionRange: string;
    tag: string;
    feedType: FeedType;
    feedTypes: FeedType[];
    showFeedSelector: boolean;
}

export default class DesignRule extends DataBaseComponent<DesignRuleProps, DesignRuleState> {
    private rulesTester: RulesTester | null = undefined!;
    private cachedRuleTests = new Map<string, VersionRuleTestResponse>();

    constructor(props: DesignRuleProps) {
        super(props);

        this.state = {
            previewText: "1.0\n1.1.0\n1.1.0-bugfix",
            versionRange: props.model.VersionRange,
            tag: props.model.Tag,
            feedType: null!,
            showFeedSelector: false,
            feedTypes: [],
        };

        this.testRules = debounce(this.testRules, 1000);
    }

    async componentDidMount() {
        let feedType: FeedType = null!;
        let uniqueVersions: string[] = [];
        let showFeedSelector: null | boolean = null;
        let feedTypes: FeedType[] = [];

        await this.doBusyTask(async () => {
            const feeds = await repository.Feeds.allById();
            const feedsByName = this.getFeedsByName(feeds);

            const actionsByName = _.keyBy(this.props.deploymentActions, (action) => action.Name);

            feedTypes = Object.keys(groupBy(Object.values(feeds), (f) => f.FeedType)).map((t) => t as FeedType);

            const operations = this.props.model.ActionPackages.map(async (actionPackage) => {
                const actionPackageReference = actionsByName[actionPackage.DeploymentAction].Packages.find((pkg) => PackageReferenceNamesMatch(actionPackage.PackageReference, pkg.Name));
                const feedId = actionPackageReference!.FeedId;

                const feed = feeds[feedId] ?? feedsByName[feedId];
                if (feed === undefined) {
                    // This will also trigger for bound fields
                    showFeedSelector = true;
                    return [];
                }

                if (feedType === null) {
                    // For the first action we use that as the feed type
                    feedType = feed.FeedType;
                    if (showFeedSelector === null) {
                        showFeedSelector = false;
                    }
                } else if (feedType !== feed.FeedType) {
                    // If any other actionPackages feed is different from the first one then we show UI
                    showFeedSelector = true;
                }

                const packageId = actionPackageReference!.PackageId;
                if (!packageId || isBound(packageId)) {
                    return [];
                }

                const results = await repository.Feeds.searchPackageVersions(feed, packageId, { take: 6 });

                return results.Items.map((pkg) => pkg.Version);
            });
            const allVersions = flatten(await Promise.all(operations));
            uniqueVersions = union(allVersions);
        });

        this.setState({
            feedType,
            feedTypes,
            showFeedSelector: showFeedSelector === null ? true : showFeedSelector,
        });

        if (uniqueVersions.length > 0) {
            this.setState(
                {
                    previewText: uniqueVersions.join("\n"),
                },
                async () => {
                    this.rulesTester!.setValue(this.state.previewText);
                    await this.testRules();
                }
            );
        }
    }

    getFeedsByName(feeds: ResourcesById<FeedResource>): { [feedName: string]: FeedResource } {
        return Object.values(feeds).reduce((idx, feed) => ({ ...idx, [feed.Name]: feed }), {});
    }

    onOkClick() {
        const newModel = cloneDeep(this.props.model);
        newModel.VersionRange = this.state.versionRange;
        newModel.Tag = this.state.tag;

        this.props.onOkClick(newModel);
        return true;
    }

    render() {
        return (
            <OkDialogLayout title="Design Version Rule" errors={this.errors} busy={this.state.busy} onOkClick={() => this.onOkClick()}>
                {this.state.showFeedSelector && <FeedTypeSelect value={this.state.feedType} showOnly={this.state.feedTypes} autoFocus onChange={(value) => this.setState({ feedType: value }, async () => this.testRules())} />}
                <Text value={this.state.versionRange || ""} onChange={(value) => this.throttledVersionRange(value)} autoFocus={!this.state.showFeedSelector} label="Version range" />
                <Note>
                    Use the{" "}
                    {this.state.feedType === FeedType.Nuget || this.state.feedType === FeedType.BuiltIn ? (
                        <ExternalLink href="NuGetVersioning">NuGet</ExternalLink>
                    ) : this.state.feedType === FeedType.Maven ? (
                        <ExternalLink href="MavenVersioning">Maven</ExternalLink>
                    ) : (
                        "selected feed type"
                    )}{" "}
                    versioning syntax to specify the range of versions to include. Examples:
                </Note>
                <pre>
                    (1.1,1.2] = 1.1 &lt; x ≤ 1.2
                    <br />
                    [2.0.0-alpha.1,2.0.0] = 2.0.0-alpha.1 ≤ x &lt; 2.0.0
                    <br />
                    [1.0] = x == 1.0
                    <br />
                    empty = latest version.
                </pre>
                <Text value={this.state.tag || ""} onChange={(value) => this.throttledTag(value)} label="Pre-release tag" />
                <Note>
                    {this.state.feedType === FeedType.Nuget || this.state.feedType === FeedType.BuiltIn ? (
                        <span>
                            A regular-expression which will select the <ExternalLink href="NuGetVersioning">SemVer</ExternalLink> pre-release tag
                        </span>
                    ) : this.state.feedType === FeedType.Maven ? (
                        <span>
                            A <ExternalLink href="MavenVersionParser"> Maven</ExternalLink> qualifier
                        </span>
                    ) : (
                        "A pre-release tag"
                    )}
                    .
                </Note>
                <Note>
                    Check our <ExternalLink href="ChannelVersionRuleTags">documentation</ExternalLink> for more information on tags along with examples.
                </Note>
                <Section sectionHeader="Sample Versions">
                    <div className={styles.codeWrapper}>
                        <RulesTester ref={(ref) => (this.rulesTester = ref)} value={this.state.previewText} onChange={(text) => this.previewChanged(text)} />
                    </div>
                    <Note>Enter possible package versions to see if this rule matches. (Empty version range will match any)</Note>
                </Section>
            </OkDialogLayout>
        );
    }

    private previewChanged(previewText: string) {
        this.setState({ previewText }, async () => this.testRules());
    }

    private throttledVersionRange(versionRange: string) {
        this.setState({ versionRange }, async () => this.testRules());
    }

    private throttledTag(tag: string) {
        this.setState({ tag }, async () => this.testRules());
    }

    private async testRules() {
        const versions = this.state.previewText;

        if (this.state.feedType === null) {
            this.setValidationErrors("Select feed type first");
            return;
        }

        await this.doBusyTask(async () => {
            const results = await Promise.all(
                versions
                    .split("\n")
                    .filter((v) => v.length > 0)
                    .map(async (version) => {
                        const key = this.makeKey(version);

                        if (this.cachedRuleTests.has(key)) {
                            return { version, result: this.cachedRuleTests.get(key) };
                        }

                        const result = await repository.Channels.ruleTest({
                            version,
                            versionRange: this.state.versionRange,
                            preReleaseTag: this.state.tag,
                            feedType: this.state.feedType,
                        });
                        this.cachedRuleTests.set(key, result);

                        return { version, result };
                    })
            );

            let error: string = null!;
            for (const r of results) {
                if (r.result!.Errors.length > 0) {
                    error = r.result!.Errors[0];
                    break;
                }
            }

            if (error) {
                this.setValidationErrors(error);
            }
        });

        this.rulesTester!.showResults((version) => {
            return this.cachedRuleTests.get(this.makeKey(version))!;
        });
    }

    private makeKey(version: string) {
        return `${version}/${this.state.versionRange}/${this.state.tag}/${this.state.feedType}`;
    }
}
