import type { Dispatch, SetStateAction } from "react";
import * as React from "react";
import { useState } from "react";
import { CommunicationStyle } from "~/client/resources";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { ResultsLink } from "../ResultsLink";
import { RolesContentChip } from "./RolesContentChip";
const styles = require("../style.less");

interface RolesContentWithThemeProps {
    machineRoles: string[];
    numberOfItemsToRender: number;
    link: (filter: object) => string;
    filterText: string;
    setFilterText: Dispatch<SetStateAction<string>>;
    theme: OctopusTheme;
}

const onFilter = (filter: string, role: string) => {
    return !filter || filter.length === 0 || !role || role.toLowerCase().includes(filter.toLowerCase());
};

export const RolesContentWithTheme = ({ machineRoles, numberOfItemsToRender, link, filterText, setFilterText, theme }: RolesContentWithThemeProps) => {
    const filteredRoles = machineRoles.filter((item: string) => onFilter(filterText, item)).slice(0, numberOfItemsToRender);

    return (
        <>
            {(filteredRoles.length > 1 || filterText) && (
                <FilterSearchBox
                    placeholder="Filter..."
                    onChange={(value) => setFilterText(value.toLowerCase())}
                    containerClassName={styles.cardContentContainerFilter}
                    inputClassName={styles.dashboardFilterInputClassName}
                    iconColor={theme.secondaryText}
                    iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                />
            )}
            <div className={styles.cardContentContainerRows}>
                {filteredRoles.map((role: string) => {
                    return <RolesContentChip key={role} role={role} link={link} />;
                })}
                {<ResultsLink totalCount={machineRoles.length} renderedCount={filteredRoles.length} link={link({ commStyle: CommunicationStyle.None })} />}
            </div>
        </>
    );
};

interface RolesContentProps {
    machineRoles: string[];
    numberOfItemsToRender: number;
    link: (filter: object) => string;
}

export const RolesContent = (props: RolesContentProps) => {
    const theme = useOctopusTheme();
    const [filterText, setFilterText] = useState<string>("");
    return <RolesContentWithTheme {...props} filterText={filterText} setFilterText={setFilterText} theme={theme} />;
};
