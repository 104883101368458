import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { Action, useAnalyticActionDispatch, useAnalyticViewDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import type { DeploymentProcessResource } from "~/client/resources";
import type { RenderProps } from "~/components/Dialog/CustomDialog";
import CustomDialog from "~/components/Dialog/CustomDialog";
import DialogTrigger from "~/components/Dialog/DialogTrigger";
import type { ProjectRouteParams } from "../../ProjectsRoutes/ProjectRouteParams";
import { useProcessContext } from "../Contexts/ProcessContext";
import OclEditorDialogLayout from "../OclEditorDialogLayout/OclEditorDialogLayout";

interface DeploymentProcessOclEditorDialogTriggerProps {
    resource: DeploymentProcessResource;
}

const DeploymentProcessOclEditorDialogTrigger: React.FC<DeploymentProcessOclEditorDialogTriggerProps> = ({ resource: deploymentProcess }) => {
    const projectContext = useProjectContext();
    const processContext = useProcessContext();
    const dispatchViewAction = useAnalyticViewDispatch();
    const dispatchAction = useAnalyticActionDispatch();
    const { projectContextRepository } = projectContext.state;
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<ProjectRouteParams>();

    return (
        <DialogTrigger
            render={(renderProps) => {
                renderProps.openDialog();
                return (
                    <CustomDialog
                        open={renderProps.open}
                        close={renderProps.closeDialog}
                        render={(dialogProps: RenderProps) => (
                            <OclEditorDialogLayout
                                {...dialogProps}
                                getOcl={async () => {
                                    dispatchViewAction("View or Edit OCL", { resource: "Deployment process" });
                                    const oclResource = await projectContextRepository.DeploymentProcesses.getOcl(deploymentProcess);
                                    return oclResource.Ocl;
                                }}
                                modifyOcl={async (ocl: string) => {
                                    const oclResource = await projectContextRepository.DeploymentProcesses.modifyOcl(deploymentProcess, { Ocl: ocl });
                                    return oclResource.Ocl;
                                }}
                                resource={deploymentProcess}
                                onSave={async () => {
                                    dispatchAction("Commit OCL", { resource: "Deployment process", action: Action.Commit, isDefaultBranch: projectContext.state.isDefaultBranch });
                                    await processContext.actions.refreshFromServer();

                                    renderProps.closeDialog();
                                }}
                                onClose={() => {
                                    renderProps.closeDialog();
                                }}
                                history={history}
                                location={location}
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                match={match!}
                            />
                        )}
                    />
                );
            }}
        />
    );
};

export default DeploymentProcessOclEditorDialogTrigger;
