import React from "react";
import { BooleanRadioButtonGroup, ExpandableFormSection, RadioButton, Summary } from "~/components/form";

type PrivateSpaceProps = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};

export function PrivateSpace({ isEnabled, onChange }: PrivateSpaceProps) {
    return (
        <ExpandableFormSection key="IsPrivateSpaceEnabled" errorKey="IsPrivateSpaceEnabled" title="Private Space" summary={isEnabled ? Summary.summary("Enabled") : Summary.default("Disabled")} help="A Private Space for each user">
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled" />
                <RadioButton value={false} label="Disabled" isDefault={true} />
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>
    );
}
