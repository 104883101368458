/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ActionsUpdateProcessResource, ActionTemplateCategoryResource, ActionTemplateResource, ActionTemplateUsageResource, ActionUpdateResource } from "~/client/resources";
import type ActionProperties from "~/client/resources/actionProperties";
import type ActionTemplateSearchResource from "~/client/resources/actionTemplateSearchResource";
import type { CommunityActionTemplateResource } from "~/client/resources/communityActionTemplateResource";
import type { Client } from "../client";
import BasicRepository from "./basicRepository";

class ActionTemplateRepository extends BasicRepository<ActionTemplateResource, ActionTemplateResource> {
    constructor(client: Client) {
        super("ActionTemplates", client);
    }
    getUsage(template: Partial<ActionTemplateResource>): Promise<ActionTemplateUsageResource[]> {
        return this.client.get(template.Links!["Usage"]);
    }
    getByCommunityTemplate(communityTemplate: CommunityActionTemplateResource) {
        const allArgs = { ...{}, ...{ id: communityTemplate.Id } };
        return this.client.get<ActionTemplateResource>(communityTemplate.Links["InstalledTemplate"], allArgs);
    }
    search(args?: { type?: string }): Promise<ActionTemplateSearchResource[]> {
        return this.client.get<ActionTemplateSearchResource[]>(this.client.getLink("ActionTemplatesSearch"), args);
    }
    updateActions(actionTemplate: Partial<ActionTemplateResource>, actionsToUpdate: ActionsUpdateProcessResource[], defaults: ActionProperties = {}, overrides: ActionProperties = {}) {
        const resource: ActionUpdateResource = {
            ActionsToUpdate: actionsToUpdate,
            Overrides: overrides || {},
            DefaultPropertyValues: defaults || {},
            Version: actionTemplate.Version!,
        };
        return this.client.post(actionTemplate.Links!["ActionsUpdate"], resource);
    }
    getVersion(actionTemplate: ActionTemplateResource, version: any): Promise<ActionTemplateResource> {
        return this.client.get(actionTemplate.Links["Versions"], { version });
    }
    categories(): Promise<ActionTemplateCategoryResource[]> {
        return this.client.get<ActionTemplateCategoryResource[]>(this.client.getLink("ActionTemplatesCategories"));
    }
}

export default ActionTemplateRepository;
