import React from "react";
import type { IconSize } from "./IconSize";
import { getIconSize } from "./getIconSize";
import styles from "./styles.less";

export interface UnhealthyMachineIconProps {
    iconSize?: IconSize;
    title?: string;
}

export function UnhealthyMachineIcon({ title = "Unhealthy", iconSize }: UnhealthyMachineIconProps) {
    return (
        <svg {...getIconSize(iconSize)} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={styles.healthStatusIcon}>
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#DB4437" cx="8" cy="8" r="8" />
                <path stroke="#FFF" strokeWidth=".5" fill="#FFF" d="M11.53 10.037728L9.007273 7.515 11.53 4.992273 10.537728 4 8.015 6.522727 5.492273 4 4.5 4.992273 7.022727 7.515 4.5 10.037728l.992273.992272L8.015 8.507273 10.537728 11.03z" />
            </g>
        </svg>
    );
}
