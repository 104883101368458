import React from "react";
import { Permission } from "~/client/resources";
import { EarlyAccessChip } from "~/components/Chips";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { BooleanRadioButtonGroup, ExpandableFormSection, Note, RadioButton, Summary } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import ToolTip, { ToolTipPosition } from "~/primitiveComponents/dataDisplay/ToolTip";

type ServiceNowIntegrationWorkerFeatureProps = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};
export function ServiceNowIntegrationFeature({ isEnabled, onChange }: ServiceNowIntegrationWorkerFeatureProps) {
    return (
        <ExpandableFormSection
            key="IsServiceNowIntegrationEnabled"
            errorKey="IsServiceNowIntegrationEnabled"
            title="Service Now Integration"
            summary={isEnabled ? Summary.summary("Enabled") : Summary.default("Disabled")}
            help="Enable Service Now integration"
            chip={<EarlyAccessChip noMargin={true} />}
        >
            <Callout title="Early Access" type={CalloutType.Warning}>
                <div>This feature is still in development. We strongly recommend not using it on critical production projects just yet.</div>
            </Callout>
            {isAllowed({ permission: Permission.AdministerSystem }) ? (
                getRadioButtons(isEnabled, onChange)
            ) : (
                <ToolTip content="This feature can only be modified by the System Administrator" position={ToolTipPosition.Left}>
                    {getRadioButtons(isEnabled, onChange)}
                </ToolTip>
            )}
            <Note style={{ marginTop: "1rem" }}>
                <div>This feature enables integration with Service Now.</div>
                <div>
                    It will also require a <ExternalLink href="ServiceNowLicensing">Service Now Integration license</ExternalLink>
                    on your Octopus Deploy license plus the <ExternalLink href="ServiceNowAppInstallation">Octopus Service Now application installed</ExternalLink> on your Service Now instance.
                </div>
            </Note>
        </ExpandableFormSection>
    );
}

function getRadioButtons(isEnabled: boolean, onChange: (isEnabled: boolean) => void): React.ReactNode {
    if (isAllowed({ permission: Permission.AdministerSystem })) {
        return (
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <RadioButton value={true} label="Enabled" />
                <RadioButton value={false} label="Disabled" />
            </BooleanRadioButtonGroup>
        );
    } else {
        return (
            <Callout type={CalloutType.Information} title={"Permission required"}>
                The {Permission.AdministerSystem} permission is required to {isEnabled} the Built-in Worker.
            </Callout>
        );
    }
}
