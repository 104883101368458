/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as _ from "lodash";
import { compact } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ActionEvent, AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, AnalyticView, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { DeployButton } from "~/areas/projects/components/Releases/DeployButton/DeployButton";
import PreventProgression from "~/areas/projects/components/Releases/PreventProgression/PreventProgression";
import { DeploymentCreateGoal } from "~/areas/projects/components/Releases/ReleasesRoutes/releaseRouteLinks";
import UpdateVariables from "~/areas/projects/components/Releases/UpdateVariables/UpdateVariables";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import ArtifactLink from "~/areas/tasks/components/Task/Artifacts/ArtifactLink";
import type { ResourcesById } from "~/client/repositories/basicRepository";
import type { PackageNote } from "~/client/repositories/packageRepository";
import type {
    ReleaseResource,
    LifecycleProgressionResource,
    PhaseProgressionResource,
    EnvironmentResource,
    ArtifactResource,
    ChannelResource,
    LifecycleResource,
    DeploymentResource,
    ResourceCollection,
    ProjectResource,
    TaskResource,
    IPhasedResource,
} from "~/client/resources";
import { PackageReferenceNamesMatch, ProcessType } from "~/client/resources";
import { DefectStatus } from "~/client/resources/defectResource";
import { Permission } from "~/client/resources/permission";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import NavigationButton from "~/components/Button/NavigationButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import { List } from "~/components/List/List";
import ListEventsForRelease from "~/components/ListEventsForRelease";
import Markdown from "~/components/Markdown/index";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import { FormSectionHeading, Note } from "~/components/form";
import isBound from "~/components/form/BoundField/isBound";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import routeLinks from "~/routeLinks";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import type { LifecycleStatus } from "~/utils/MapProgressionToStatus/MapProgressionToStatus";
import { mapProgressionToStatus } from "~/utils/MapProgressionToStatus/MapProgressionToStatus";
import StringHelper from "~/utils/StringHelper";
import { LifecycleProgression } from "./LifecycleProgression";
import PackagesList from "./PackagesList/PackagesList";
import { ProcessSnapshotFormSection } from "./ProcessSnapshot/ProcessSnapshot";
import VariableSnapshot from "./VariableSnapshot/VariableSnapshot";
import type { PackageModel } from "./packageModel";
import { buildPartialReleaseNotes } from "./releaseNoteHelper";
const styles = require("./style.less");

interface ReleaseState extends DataBaseComponentState {
    project: ProjectResource;
    release: ReleaseResource;
    progression: LifecycleProgressionResource;
    showUnblockReleaseDialog: boolean;
    environmentsById: ResourcesById<EnvironmentResource>;
    packages: PackageModel[];
    artifacts: ResourceCollection<ArtifactResource>;
    defects: Defect;
    channels: ChannelResource[];
    releaseChannel: ChannelResource;
    lifecycle: LifecycleResource;
    deployments: DeploymentResource[];
    deploymentTasks: Array<TaskResource<{ DeploymentId: string }>>;
    lifecycleStatus: LifecycleStatus;
    deploymentsByPhase: { [phase: string]: DeploymentResource[] };
    progressionByPhase: { [phase: string]: PhaseProgressionResource };
    showFullReleaseNotes: boolean;
    showLifecycleProgression: boolean;
    isInitialLoad: boolean;
    totalNumOfEnvironments: number;
    totalNumOfPhases: number;
    hasPendingInteruptions: boolean;
    currentPageIndex: number;
    currentSkip: number;
    variableSnapshotRefreshKey: string;
    deleted: boolean;
}

interface Defect {
    releaseHasDefect: boolean;
    defectDescription: string;
}

class ArtifactsList extends List<ArtifactResource> {}

type ReleaseProps = RouteComponentProps<ProjectRouteParams & { releaseVersion: string }>;

interface ReleasePropsInternal extends ReleaseProps, WithProjectContextInjectedProps {
    dispatchAction: AnalyticActionDispatcher;
}

class ReleaseInternal extends DataBaseComponent<ReleasePropsInternal, ReleaseState> {
    private showHideLifecycleThreshhold: number = 20;
    private packageResolveMessage: string = "Package will be resolved during deployment";

    constructor(props: ReleasePropsInternal) {
        super(props);
        this.state = {
            project: null!,
            release: null!,
            progression: null!,
            showUnblockReleaseDialog: false,
            environmentsById: null!,
            packages: [],
            artifacts: null!,
            defects: null!,
            channels: [],
            releaseChannel: null!,
            lifecycle: null!,
            deployments: [],
            deploymentTasks: [],
            lifecycleStatus: null!,
            deploymentsByPhase: null!,
            progressionByPhase: null!,
            showFullReleaseNotes: false,
            showLifecycleProgression: true,
            isInitialLoad: true,
            totalNumOfEnvironments: 0,
            totalNumOfPhases: 0,
            hasPendingInteruptions: false,
            currentPageIndex: 0,
            currentSkip: 0,
            variableSnapshotRefreshKey: DateFormatter.timestamp(),
            deleted: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const release = await repository.Projects.getReleaseByVersion(project, this.props.match.params.releaseVersion);
            const environmentsById = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? await repository.Environments.allById() : null!;

            await this.init(project, release);
            await this.startRefreshLoop(() => this.refreshActiveComponents(project, release, environmentsById), 15000);
        });
    }

    render() {
        const projectLinks = routeLinks.project(this.props.match.params.projectSlug);
        if (this.state.deleted) {
            return <InternalRedirect to={projectLinks.releases} push={true} />;
        }

        const releaseLinks = projectLinks.release(this.props.match.params.releaseVersion);
        const overflowActions = [];
        if (this.state.project) {
            overflowActions.push(OverflowMenuItems.navItem("Edit", releaseLinks.edit, { permission: Permission.ReleaseEdit, project: this.state.project.Id, tenant: "*" }));
        }
        if (isAllowed({ permission: Permission.DefectReport, project: this.state.project && this.state.project.Id })) {
            if (this.state.defects && this.state.release) {
                if (this.state.defects.releaseHasDefect) {
                    overflowActions.push(OverflowMenuItems.item("Unblock", () => this.setState({ showUnblockReleaseDialog: true }), { permission: Permission.DefectResolve, project: this.state.project.Id }));
                } else {
                    if (this.state.release && this.state.defects) {
                        overflowActions.push(OverflowMenuItems.dialogItem("Prevent Progression", <PreventProgression defects={this.state.defects} release={this.state.release} onPreventProgressionClicked={(desc) => this.reportDefect(desc)} />));
                    }
                }
            }
        }
        if (this.state.release) {
            overflowActions.push(
                OverflowMenuItems.dialogItem(
                    "Update Variables",
                    <UpdateVariables
                        processType={ProcessType.Deployment}
                        onUpdateVariablesClicked={async () => {
                            const updatedRelease = await repository.Releases.snapshotVariables(this.state.release);
                            this.setState({ release: updatedRelease, variableSnapshotRefreshKey: DateFormatter.timestamp() });
                        }}
                    />,
                    {
                        permission: Permission.ReleaseEdit,
                        project: this.state.project && this.state.project.Id,
                        wildcard: true,
                    }
                )
            );
            overflowActions.push(
                OverflowMenuItems.deleteItemDefault(
                    "release",
                    this.handleDeleteConfirm,
                    {
                        permission: Permission.ReleaseDelete,
                        project: this.state.project && this.state.project.Id,
                        tenant: "*",
                    },
                    "The release and any of its deployments will be permanently deleted and they will disappear from all dashboards."
                )
            );
            overflowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.release.Id]), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const actions = [];
        if (this.state.project) {
            actions.push(
                <PermissionCheck permission={Permission.DeploymentCreate} project={this.state.project.Id} environment="*" tenant="*">
                    <NavigationButton
                        label="Deploy To..."
                        href={releaseLinks.deployments.create(DeploymentCreateGoal.To)}
                        onClick={() => {
                            const ev: ActionEvent = {
                                action: Action.Deploy,
                                resource: "Deploy Release",
                            };

                            this.props.dispatchAction("Initiate Deployment", ev);
                        }}
                    />
                </PermissionCheck>
            );
        }
        if (this.state.progression && this.state.environmentsById && this.state.project) {
            actions.push(
                <DeployButton
                    releaseVersion={this.state.release.Version}
                    projectSlug={this.state.project.Slug}
                    projectId={this.state.project.Id}
                    nextDeployments={this.state.progression.NextDeployments}
                    environmentsById={this.state.environmentsById}
                    tenantedDeploymentMode={this.state.project.TenantedDeploymentMode}
                />
            );
        }
        if (this.state.release) {
            actions.push(<OverflowMenu menuItems={overflowActions} />);
        }
        const sectionControl = <ActionList actions={actions} />;

        return (
            <PaperLayout title={this.releaseTitle()} breadcrumbTitle={"Releases"} breadcrumbPath={projectLinks.releases} busy={this.state.busy} errors={this.errors} sectionControl={sectionControl}>
                {this.state.release && !this.state.deleted && <AnalyticView name="View a Release" resource="Release" />}
                <div className={styles.releaseDetailsLayout}>
                    {this.state.release && (
                        <div className={styles.releaseDetailsLayoutContent}>
                            <FormSectionHeading key="deployments" title="Progression" />
                            <PermissionCheck
                                permission={Permission.LifecycleView}
                                alternate={
                                    <Section key="sectionLifecycleNoAccess" sectionHeader="">
                                        <Callout type={CalloutType.Information} title={"Permission required"}>
                                            The {Permission.LifecycleView} permission is required to view the deployments
                                        </Callout>
                                    </Section>
                                }
                            >
                                <PermissionCheck
                                    permission={Permission.EnvironmentView}
                                    wildcard={true}
                                    alternate={
                                        <Callout type={CalloutType.Information} title={"Permission required"}>
                                            The {Permission.EnvironmentView} permission is required to view where the lifecycle flows.
                                        </Callout>
                                    }
                                >
                                    {this.state.release && this.state.progression && this.state.environmentsById && (
                                        <div className={styles.container}>
                                            {this.state.defects && this.state.defects.releaseHasDefect && (
                                                <div className={styles.container}>
                                                    <Callout title="Deployment to the next phase is blocked" type={CalloutType.Danger}>
                                                        <Markdown markup={this.state.defects.defectDescription} />
                                                        <PermissionCheck permission={Permission.DefectResolve} project={this.state.release.ProjectId}>
                                                            <ActionButton type={ActionButtonType.Save} onClick={() => this.setState({ showUnblockReleaseDialog: true })} label="Unblock" />
                                                        </PermissionCheck>
                                                    </Callout>
                                                    <PermissionCheck permission={Permission.DefectResolve} project={this.state.release.ProjectId}>
                                                        <ConfirmationDialog
                                                            title="Unblock deployment"
                                                            continueButtonLabel="Unblock"
                                                            open={this.state.showUnblockReleaseDialog}
                                                            onClose={() => this.setState({ showUnblockReleaseDialog: false })}
                                                            onContinueClick={async () => this.resolveDefect()}
                                                        >
                                                            <p>If it is now safe to proceed with deployments of this release, you can unblock it.</p>
                                                        </ConfirmationDialog>
                                                    </PermissionCheck>
                                                </div>
                                            )}
                                            <LifecycleProgression
                                                project={this.state.project}
                                                release={this.state.release}
                                                channels={this.state.channels}
                                                releaseChannel={this.state.releaseChannel}
                                                deploymentTasks={this.state.deploymentTasks}
                                                progression={this.state.progression}
                                                lifecycle={this.state.lifecycle}
                                                lifecycleStatus={this.state.lifecycleStatus}
                                                environmentsById={this.state.environmentsById}
                                                deploymentsByPhase={this.state.deploymentsByPhase}
                                                progressionByPhase={this.state.progressionByPhase}
                                                showLifecycleProgression={this.state.showLifecycleProgression}
                                                onLifecycleProgressionToggled={() => this.setState({ showLifecycleProgression: !this.state.showLifecycleProgression })}
                                                totalNumOfEnvironments={this.state.totalNumOfEnvironments}
                                                totalNumOfPhases={this.state.totalNumOfPhases}
                                                isCollapsable={this.state.totalNumOfEnvironments > this.showHideLifecycleThreshhold}
                                                hasPendingInteruptions={this.state.hasPendingInteruptions}
                                                {...this.props}
                                            />
                                        </div>
                                    )}
                                </PermissionCheck>
                            </PermissionCheck>
                            {this.state.release.ReleaseNotes && this.getReleaseNoteSection()}
                            {this.state.release.VersionControlReference && <ProcessSnapshotFormSection release={this.state.release} />}
                            <FormSectionHeading key="packages" title="Packages" />
                            <Section key="sectionPackages" sectionHeader="">
                                <PermissionCheck
                                    permission={Permission.FeedView}
                                    alternate={
                                        <Callout type={CalloutType.Information} title={"Permission required"}>
                                            The {Permission.FeedView} permission is required to view packages
                                        </Callout>
                                    }
                                >
                                    <PermissionCheck
                                        permission={Permission.DeploymentView}
                                        project={this.state.release.ProjectId}
                                        wildcard={true}
                                        alternate={
                                            <Callout type={CalloutType.Information} title={"Permission required"}>
                                                The {Permission.DeploymentView} permission is required to view packages
                                            </Callout>
                                        }
                                    >
                                        <div className={styles.releasePackagesLayout}>
                                            <PackagesList packages={this.state.packages} buildInformation={this.state.release.BuildInformation} />
                                        </div>
                                    </PermissionCheck>
                                </PermissionCheck>
                            </Section>
                            {/*Include the VariableSnapshot outside of the Section so that the table can extend to the edge of the paper element*/}
                            {this.state.release && (
                                <PermissionCheck permission={Permission.VariableView} project={this.state.release.ProjectId} wildcard={true}>
                                    <VariableSnapshot projectId={this.state.release.ProjectId} snapshot={this.state.release} doBusyTask={this.doBusyTask} updateVariablesRefreshKey={this.state.variableSnapshotRefreshKey} />
                                </PermissionCheck>
                            )}
                            {this.state.artifacts && (
                                <>
                                    <FormSectionHeading key="artifacts" title="Artifacts" />
                                    <div className={styles.releaseArtifactsLayout}>
                                        <ArtifactsList
                                            initialData={this.state.artifacts}
                                            onRow={(artifact: ArtifactResource) => (
                                                <>
                                                    <ArtifactLink artifact={artifact} key="link" />
                                                    <div key="time" className={styles.time}>
                                                        <TimeFromNowLabel time={artifact.Created} />
                                                    </div>
                                                </>
                                            )}
                                            showPagingInNumberedStyle={true}
                                            currentPageIndex={this.state.currentPageIndex}
                                            onPageSelected={this.handleArtifactsPageSelected}
                                            empty={
                                                <Note>
                                                    No artifacts have been added. Learn more about <ExternalLink href="Artifacts">collecting artifacts</ExternalLink>.
                                                </Note>
                                            }
                                        />
                                    </div>
                                </>
                            )}
                            <FormSectionHeading key="deploymentHistory" title="Deployment history" />
                            {/* Technically, the `ReleaseView` check should _also be wrapped_ by `EnvironmentView`,
                                but we leak this data in the old portal via event history, so will leave it like this for consistency for now
                            <PermissionCheck permission={Permission.EnvironmentView} project={this.state.release.ProjectId} wildcard={true} alternate={
                                <Callout type={CalloutType.Information}>
                                The {Permission.EnvironmentView} permission is required to view the deployment history, so you can see where it was deployed.
                            </Callout>}> */}
                            <PermissionCheck
                                permission={Permission.DeploymentView}
                                project={this.state.release.ProjectId}
                                wildcard={true}
                                alternate={
                                    <Callout type={CalloutType.Information} title={"Permission required"}>
                                        The {Permission.DeploymentView} permission is required to view the deployment history
                                    </Callout>
                                }
                            >
                                <ListEventsForRelease release={this.state.release} />
                            </PermissionCheck>
                        </div>
                    )}
                </div>
            </PaperLayout>
        );
    }

    private async init(project: ProjectResource, release: ReleaseResource) {
        const { projectContextRepository } = this.props.projectContext.state;

        const [defects, channels, deploymentProcess] = await Promise.all([
            this.loadDefects(release),
            isAllowed({ permission: Permission.LifecycleView }) ? repository.Projects.getChannels(project) : null,
            isAllowed({ permission: Permission.DeploymentView, project: project.Id, wildcard: true }) ? projectContextRepository.DeploymentProcesses.getForRelease(release) : null,
        ]);

        const template = deploymentProcess && (await projectContextRepository.DeploymentProcesses.getTemplate(deploymentProcess, release.ChannelId, release.Id));

        this.setState({
            defects,
            channels: channels ? channels.Items : [],
        });

        const allPackages = template
            ? compact(
                  template.Packages.map((packageTemplate) => {
                      const selectionForStep = release.SelectedPackages.find((selected) => selected.ActionName === packageTemplate.ActionName && PackageReferenceNamesMatch(selected.PackageReferenceName!, packageTemplate.PackageReferenceName!));

                      if (selectionForStep) {
                          return {
                              ActionName: packageTemplate.ActionName,
                              PackageId: packageTemplate.PackageId,
                              PackageReferenceName: packageTemplate.PackageReferenceName!,
                              ProjectName: packageTemplate.ProjectName,
                              FeedName: packageTemplate.FeedName,
                              FeedId: packageTemplate.FeedId,
                              Version: selectionForStep.Version,
                              Notes: {
                                  Notes: "Loading...",
                                  Succeeded: true,
                                  FailureReason: null!,
                              },
                          };
                      }
                  })
              )
            : [];

        this.setState(() => {
            return { packages: allPackages };
        });

        if (isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true })) {
            this.buildPackages(allPackages);
        }
    }

    private async loadTasks(deployments: DeploymentResource[]) {
        const ids = deployments
            .filter((deployment) =>
                session.currentPermissions!.scopeToSpace(repository.spaceId).isAuthorized({
                    permission: Permission.TaskView,
                    projectId: deployment.ProjectId,
                    environmentId: deployment.EnvironmentId,
                    tenantId: deployment.TenantId,
                })
            )
            .map((deployment) => deployment.TaskId);

        return repository.Tasks.byIds(ids);
    }

    private async getLifecycle(project: ProjectResource, release: ReleaseResource) {
        if (release.ChannelId) {
            const channel = await repository.Releases.getChannel(release);
            const lifecycleId = channel.LifecycleId ? channel.LifecycleId : project.LifecycleId;
            const lifecycleRaw = await repository.Lifecycles.get(lifecycleId);
            const lifecycle = await repository.Lifecycles.preview(lifecycleRaw);
            return [lifecycle, channel];
        } else {
            const lifecycleRaw = await repository.Lifecycles.get(project.LifecycleId);
            const lifecycle = await repository.Lifecycles.preview(lifecycleRaw);
            return [lifecycle, null];
        }
    }

    private async refreshActiveComponents(project: ProjectResource, release: ReleaseResource, environmentsById: ResourcesById<EnvironmentResource>) {
        const [channelAndLifecycle, artifacts, progression, deploymentsCollection] = await Promise.all([
            isAllowed({ permission: Permission.LifecycleView }) ? this.getLifecycle(project, release) : null!,
            this.loadArtifactsPromise(release, this.state.currentSkip)!,
            repository.Releases.progression(release),
            isAllowed({ permission: Permission.DeploymentView, project: project.Id, wildcard: true }) ? repository.Releases.getDeployments(release, { take: 1000 }) : null!,
        ]);

        const hasPendingInteruptions = progression.Phases.some((p) => p.Deployments.some((d) => d.Task.HasPendingInterruptions));
        const deployments = deploymentsCollection && deploymentsCollection.Items;
        const tasksPromise = deployments && this.loadTasks(deployments);
        const deploymentsByPhase: { [name: string]: DeploymentResource[] } = {};
        const lifecycle = channelAndLifecycle && (channelAndLifecycle[0] as LifecycleResource);
        const releaseChannel = channelAndLifecycle && (channelAndLifecycle[1] as ChannelResource);

        if (deployments && lifecycle) {
            const phases: IPhasedResource[] = lifecycle.Phases.length > 0 ? lifecycle.Phases : progression.Phases;
            const explicitEnvironmentsByPhase: string[] = [];
            phases.map((phase: IPhasedResource) => {
                phase.AutomaticDeploymentTargets.map((envId) => explicitEnvironmentsByPhase.push(envId));
                phase.OptionalDeploymentTargets.map((envId) => explicitEnvironmentsByPhase.push(envId));
            });
            phases.forEach((phase) => {
                const deploymentsForPhase = deployments.filter((deployment) => {
                    if (phase.AutomaticDeploymentTargets.length > 0 || phase.OptionalDeploymentTargets.length > 0) {
                        return phase.AutomaticDeploymentTargets.indexOf(deployment.EnvironmentId) !== -1 || phase.OptionalDeploymentTargets.indexOf(deployment.EnvironmentId) !== -1;
                    } else {
                        // Phase has no explicit environments selected, use all the remaining environments
                        const environmentsInPhase = _.differenceWith(Object.keys(environmentsById), explicitEnvironmentsByPhase, _.isEqual);
                        return environmentsInPhase.indexOf(deployment.EnvironmentId) !== -1;
                    }
                });
                deploymentsByPhase[phase.Name] = deploymentsForPhase;
            });
        }
        const lifecycleStatus = mapProgressionToStatus(progression);
        const progressionByPhase: { [phase: string]: PhaseProgressionResource } = {};
        let totalNumOfEnvironments: number = 0;
        progression.Phases.forEach((phaseProgress) => {
            progressionByPhase[phaseProgress.Name] = phaseProgress;
            const envsForPhase = [...phaseProgress.OptionalDeploymentTargets, ...phaseProgress.AutomaticDeploymentTargets];
            totalNumOfEnvironments += envsForPhase.length;
        });

        const resultForState = {
            project,
            release,
            environmentsById,
            artifacts,
            progression,
            deployments,
            deploymentsByPhase,
            progressionByPhase,
            lifecycleStatus,
            lifecycle,
            releaseChannel,
            deploymentTasks: isAllowed({ permission: Permission.TaskView, project: project.Id, wildcard: true }) ? await tasksPromise : [],
            showLifecycleProgression: this.state.isInitialLoad ? totalNumOfEnvironments <= this.showHideLifecycleThreshhold || hasPendingInteruptions : this.state.showLifecycleProgression,
            isInitialLoad: false,
            totalNumOfEnvironments,
            totalNumOfPhases: progression.Phases.length,
            hasPendingInteruptions,
        };

        return resultForState;
    }

    private async loadDefects(release: ReleaseResource) {
        const defects: Defect = {
            releaseHasDefect: false,
            defectDescription: null!,
        };

        const defectList = await repository.Defects.all(release);
        let defect = null;
        const hasItems = defectList.Items.length > 0;
        if (hasItems) {
            const lastIndex = defectList.Items.length - 1;
            const potentialDefect = defectList.Items[lastIndex];
            if (potentialDefect.Status === DefectStatus.Unresolved) {
                defect = potentialDefect;
            }
        }

        defects.releaseHasDefect = defect !== null;

        if (defect) {
            defects.defectDescription = defect.Description;
        }
        return defects;
    }

    private buildPackages(packages: PackageModel[]) {
        const grouped: { [feedId: string]: PackageModel[] } = _.groupBy(packages, (pkg) => {
            return pkg.FeedId;
        });
        for (const feedId in grouped) {
            if (isBound(feedId, false)) {
                grouped[feedId].forEach((p) => (p.Notes = { Succeeded: true, Notes: this.packageResolveMessage, FailureReason: null! }));
            } else {
                this.loadPackages(grouped[feedId]);
            }
        }
    }

    private loadPackages(allPackages: PackageModel[]) {
        // Create an object with two arrays, one containing bound packages, and the
        // other containing unbound packages.
        const boundUnbound = allPackages.reduce<{ bound: PackageModel[]; unBound: PackageModel[] }>(
            (container, pkg) => {
                (isBound(pkg.PackageId, false) ? container.bound : container.unBound).push(pkg);
                return container;
            },
            { bound: [], unBound: [] }
        );

        // Bound packages all get a standard release notes string
        this.setState((existingState) => {
            boundUnbound.bound.forEach((bound) => (bound.Notes.Notes = this.packageResolveMessage));
            return {
                packages: [..._.differenceWith(existingState.packages, boundUnbound.bound, this.packageNoteEquals), ...boundUnbound.bound],
            };
        });

        // This is how many concurrent packages to query with each request. This
        // is based on a fixed number of concurrent requests, which is limited
        // by the browser. Chrome has a limit of 5, but we need to leave a few requests
        // for other calls made by this page, so we devote 3 to the release notes.
        // We limit the batch size to 30 though. If there are hundreds of packages
        // we don't want to max out the URL length.
        const requests = Math.min(Math.ceil(boundUnbound.unBound.length / 3), 30);

        // This is the array that will hold chunks of the allPackages array. So an
        // array of PackageModel[30] becomes PackageModel[3][10] (roughly speaking).
        const splitAllPackages: PackageModel[][] = [];

        // Split the original array into a bunch of smaller arrays which will be
        // processed in parallel.
        while (boundUnbound.unBound.length > 0) {
            splitAllPackages.push(boundUnbound.unBound.splice(0, requests));
        }

        // Now process each group of packages with a concurrent request
        splitAllPackages.forEach((pkgs) => {
            repository.Packages.getNotes(pkgs)
                .then((pkgsDetails) => {
                    this.setState((existingState) => {
                        // for every package that was returned, update the existing package
                        // with the returned notes.
                        const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, pkgsDetails.Packages)));

                        return {
                            packages: updated,
                        };
                    });
                })
                .catch((err) => {
                    this.setState((existingState) => {
                        // for every package that was requested, set the state to error.

                        // Possible bug: The Success property looks like it should be referring to PackageNoteResult.Succeeded.
                        const updated = existingState.packages.map((existing) => _.assign(existing, this.findMatchingNotesPackage(existing, pkgs, { Notes: { Success: false, FailureMessage: err.ErrorMessage } })));

                        return {
                            packages: updated,
                        };
                    });
                });
        });
    }

    /**
     * Finding release notes in a bulk fashion from the server means:
     * 1. Requesting the package details (in a request with a bunch of other packages)
     * 2. Assigning the returned details back to the matching packages from the state
     * 3. Optionally setting the some additional field, typically when a batch request failed and all packages need to show an error
     * This function will attempt to find a matching package from the list of returned packages, and if so assign the values from 3
     * to it, and then return it. Otherwise it will return an empty object. The returned object is expected to be assigned to
     * the package in the state to result in an updated package object that can be displayed to the user.
     * @param {PackageNote} original The original package details to match against the package returned by the server
     * @param {PackageNote[]} packages The list of packages returned by the server
     * @param assign An object that is assigned to the matching package, if one was found. It is like an "overlay" on matching packages.
     * @returns {(PackageNote | undefined) | {}} An empty object if no match was found, and the returned package
     * with the assign object assigned to it.
     */
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    private findMatchingNotesPackage(original: PackageNote, packages: PackageNote[], assign: any = null) {
        const packageWithNotes = packages.find((pkgWithNotes) => this.packageNoteEquals(pkgWithNotes, original));
        if (packageWithNotes) {
            if (assign) {
                _.assign(packageWithNotes, assign);
            }
        }
        return packageWithNotes || {};
    }

    private packageNoteEquals(a: PackageNote, b: PackageNote) {
        return a.PackageId === b.PackageId && a.Version === b.Version && a.FeedId === b.FeedId;
    }

    private releaseTitle() {
        return this.state.release ? `Release ${this.state.release && this.state.release.Version}` : StringHelper.ellipsis;
    }

    private buildReleaseNotes() {
        if (this.state.showFullReleaseNotes) {
            return <Markdown markup={this.state.release.ReleaseNotes} />;
        }
        const [releaseNotes, isTruncated] = buildPartialReleaseNotes(this.state.release.ReleaseNotes, 10);
        return (
            <div>
                <Markdown markup={releaseNotes} />
                {isTruncated && <ActionButton type={ActionButtonType.Ternary} onClick={() => this.setState({ showFullReleaseNotes: true })} label="show more" />}
            </div>
        );
    }

    private async resolveDefect() {
        return this.doBusyTask(async () => {
            await repository.Defects.resolve(this.state.release);
            await this.init(this.state.project, this.state.release);
            this.setState(await this.refreshActiveComponents(this.state.project, this.state.release, this.state.environmentsById));
        });
    }

    private async reportDefect(desc: string) {
        return this.doBusyTask(async () => {
            await repository.Defects.report(this.state.release, desc);
            await this.init(this.state.project, this.state.release);
            this.setState(await this.refreshActiveComponents(this.state.project, this.state.release, this.state.environmentsById));
        });
    }

    private getReleaseNoteSection() {
        return [
            <FormSectionHeading key="releaseNoteHeading" title="Release notes" />,
            <Section key="releaseSection" sectionHeader="">
                <div className={styles.releaseNoteLayout}>{this.buildReleaseNotes()}</div>
            </Section>,
        ];
    }

    private loadArtifactsPromise = (release: ReleaseResource, skip: number) => {
        return isAllowed({ permission: Permission.ArtifactView, wildcard: true }) ? repository.Artifacts.list({ regarding: release.Id, skip, take: 10, order: "asc" }) : null;
    };

    private handleArtifactsPageSelected = async (skip: number, p: number) => {
        this.setState({ currentPageIndex: p, currentSkip: skip });
        this.setState({ artifacts: await this.loadArtifactsPromise(this.state.release, skip)! });
    };

    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (this.state.release) {
            await repository.Releases.del(this.state.release);
            this.setState({ deleted: true });
            return true;
        } else {
            return false;
        }
    };
}

export function Release(props: ReleaseProps) {
    const projectContext = useProjectContext();
    const dispatchAction = useAnalyticActionDispatch();

    return <ReleaseInternal {...props} projectContext={projectContext} dispatchAction={dispatchAction} />;
}
