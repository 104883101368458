/* eslint-disable no-restricted-imports */

import { Box, useMediaQuery } from "@material-ui/core";
import cn from "classnames";
import type { MouseEventHandler } from "react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import type { UserResource } from "~/client/resources";
import { repository } from "~/clientInstance";
import { drawerBreakpointWidth } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import { CommonNeedHelpInformation } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import { Avatar } from "~/primitiveComponents/dataDisplay/Avatar/Avatar";
import { SimpleMenu } from "~/primitiveComponents/navigation/Menu/SimpleMenu";
import type { MenuTargetAriaAttributes } from "~/primitiveComponents/navigation/Menu/useMenuState";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
import type { SimpleMenuItem } from "~/primitiveComponents/navigation/MenuItems/SimpleMenuItems";
import { dividerMenuItem } from "~/primitiveComponents/navigation/MenuItems/SimpleMenuItems";
import routeLinks from "../../routeLinks";
import type { SpaceContext } from "../StandardLayout/SpaceLoader";
import { isSpaceNotFound } from "../StandardLayout/SpaceLoader";
import { useThemePalette } from "../Theme/ThemePaletteProvider";
const styles = require("./style.less");

interface ExposedUserAccountMenuProps {
    currentUser: UserResource;
    spaceContext: SpaceContext;
}

export function UserAccountMenu({ currentUser, spaceContext }: ExposedUserAccountMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const userAccountMenuItems = useUserAccountMenuItems(spaceContext);

    return (
        <div>
            <OpenUserAccountMenuButton currentUser={currentUser} onClick={openMenu} ariaAttributes={buttonAriaAttributes} />
            <SimpleMenu accessibleName={"User account"} items={userAccountMenuItems} menuState={menuState} />
        </div>
    );
}

function OpenUserAccountMenuButton({ onClick, currentUser, ariaAttributes }: { onClick: MouseEventHandler; currentUser: UserResource; ariaAttributes: MenuTargetAriaAttributes }) {
    const clickHandler = useCallback<MouseEventHandler>(
        (e) => {
            onClick(e);
            e.preventDefault();
        },
        [onClick]
    );

    return (
        <a className={cn(styles.button)} onClick={clickHandler} href="#" {...ariaAttributes}>
            <Avatar avatarLink={currentUser && currentUser.Links && currentUser.Links.Avatar} isService={currentUser && currentUser.IsService} size={24} />
            <span className={cn(styles.userName, "hide-sm")}>{currentUser.DisplayName}</span>
            <em className={cn("fa fa-caret-down", styles.icon)} />
        </a>
    );
}

function useUserAccountMenuItems(spaceContext: SpaceContext): SimpleMenuItem[] {
    const darkThemeMenuItem = useDarkThemeMenuItem();
    const helpItems = useHelpItems();
    const downloadLink = createDownloadLinkMenuItem();
    const profileLink = createProfileLink(spaceContext);

    return [downloadLink, dividerMenuItem, darkThemeMenuItem, dividerMenuItem, signOutLink, profileLink, dividerMenuItem, ...helpItems];
}

function createProfileLink(spaceContext: SpaceContext): SimpleMenuItem {
    const meLink = isSpaceNotFound(spaceContext)
        ? routeLinks.withoutSpace().currentUser.me // Redirect back to `/` (no space prefix) to ensure we get redirected to an appropriate space context first
        : routeLinks.currentUser.me;

    return {
        type: "internal-link",
        label: "Profile",
        path: meLink,
        showAsActive: "never",
    };
}

const signOutLink: SimpleMenuItem = { type: "internal-link", label: "Sign Out", path: routeLinks.currentUser.signOut, showAsActive: "never" };

function createDownloadLinkMenuItem(): SimpleMenuItem {
    const version = repository.getServerInformation().version;
    const [major, minor, ...rest] = version.split(".");
    const build = rest.join(".");
    return {
        type: "external-link",
        label: `v${major}.${minor} (Build ${build})`,
        href: `https://octopus.com/downloads/${version}`,
    };
}

function useDarkThemeMenuItem(): SimpleMenuItem {
    const [currentPalette, setPalette] = useThemePalette();
    const toggleIsDarkModeEnabled = useCallback((isDarkModeEnabled: boolean) => setPalette(isDarkModeEnabled ? "dark" : "light"), [setPalette]);
    const isDarkModeEnabled = currentPalette === "dark";
    return useMemo(
        () => ({
            type: "toggle",
            label: "Dark Theme",
            isEnabled: isDarkModeEnabled,
            setIsEnabled: toggleIsDarkModeEnabled,
        }),
        [isDarkModeEnabled, toggleIsDarkModeEnabled]
    );
}

function useHelpItems(): SimpleMenuItem[] {
    const isHelpSidebarEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isHelpSidebarEnabled);
    const helpLinks = useHelpLinks();
    const isNarrowScreen = useMediaQuery(`(min-width:${drawerBreakpointWidth}px)`);

    return useMemo(() => {
        if (!isHelpSidebarEnabled || !isNarrowScreen) {
            return helpLinks;
        }
        return [
            {
                type: "information",
                content: (
                    <Box flexDirection="column" style={{ maxWidth: "15rem" }}>
                        <h4>Need help?</h4>
                        <CommonNeedHelpInformation />
                    </Box>
                ),
            },
        ];
    }, [helpLinks, isHelpSidebarEnabled, isNarrowScreen]);
}

function useHelpLinks(): SimpleMenuItem[] {
    const helpSidebarSupportLink = useSelector((state: GlobalState) => state.configurationArea.features.helpSidebarSupportLink);

    return useMemo(() => {
        return [
            { type: "external-link", label: "Documentation", href: "DocumentationHome" },
            { type: "external-link", label: "API Documentation", href: `${window.location.origin + window.location.pathname}/../swaggerui/` },
            { type: "external-link", label: "Support", href: helpSidebarSupportLink ? helpSidebarSupportLink : "HelpGeneral" },
            { type: "external-link", label: "Training Videos", href: "TrainingVideos" },
            { type: "external-link", label: "Suggestions", href: "HelpMakeSuggestion" },
            { type: "external-link", label: "Downloads", href: "https://octopus.com/downloads" },
        ];
    }, [helpSidebarSupportLink]);
}
