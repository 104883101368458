/* eslint-disable @typescript-eslint/no-explicit-any */

import cn from "classnames";
import * as React from "react";
const styles = require("./style.less");

interface ActionListProps {
    actions: any[];
    alignStart?: boolean;
}

const ActionList: React.FC<ActionListProps> = (props) => {
    return <div className={cn(styles.actionList, props.alignStart ? styles.alignStart : styles.alignEnd)}>{props.actions.map((action, index) => (!action ? null : React.cloneElement(action, { ...action.props, key: index })))}</div>;
};

ActionList.displayName = "ActionList";

export default ActionList;
