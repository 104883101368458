/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import * as React from "react";
import type ActionTemplateSearchResource from "~/client/resources/actionTemplateSearchResource";
import { repository } from "~/clientInstance";
import CommunityActionTemplateList from "~/components/ActionTemplates/CommunityActionTemplateList";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import PaperLayout from "~/components/PaperLayout";
import Section from "~/components/Section";
import routeLinks from "../../../../routeLinks";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
const styles = require("./style.less");

interface CommunityTemplatesState extends DataBaseComponentState {
    templates: ActionTemplateSearchResource[];
    isLoaded: boolean;
    filterText?: string;
    projectIdToComeBackTo?: string;
}

class CommunityTemplates extends DataBaseComponent<{}, CommunityTemplatesState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            templates: null!,
            isLoaded: false,
            filterText: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const templates = await repository.ActionTemplates.search();
            this.setState({
                templates: templates.filter((t) => !t.IsInstalled),
                isLoaded: true,
            });
        });
    }

    render() {
        const stepTemplatesLink = routeLinks.library.stepTemplates;
        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Choose Step Template" breadcrumbTitle={"Step Templates"} breadcrumbPath={routeLinks.library.stepTemplates.root} fullWidth={true} flatStyle={true} busy={this.state.busy} errors={this.errors}>
                    {this.state.isLoaded && (
                        <>
                            <Section>
                                <FilterSearchBox
                                    placeholder="Filter by name, category or description..."
                                    onChange={(value) => this.setState({ filterText: value.toLowerCase() })}
                                    fullWidth={true}
                                    autoFocus={true}
                                    containerClassName={styles.filterSearchBoxContainer}
                                />
                            </Section>
                            <CommunityActionTemplateList
                                templates={this.state.templates}
                                installationActionName="Install"
                                onPostSelectionUrlRequested={(template) => stepTemplatesLink.installedStep(template as ActionTemplateSearchResource)}
                                onDetailsUrlRequested={(template) => stepTemplatesLink.communityTemplate(template).details}
                                filter={this.state.filterText}
                            />
                        </>
                    )}
                </PaperLayout>
            </LibraryLayout>
        );
    }
}

export default CommunityTemplates;
