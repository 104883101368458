import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { withProjectContext } from "~/areas/projects/context";
import type { ICanBeVersionControlled } from "~/client/resources/canBeVersionControlledResource";
import CodeEditor, { TextFormat } from "~/components/CodeEditor/CodeEditor";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { RenderProps } from "~/components/Dialog/CustomDialog";
import { CustomDialogActions, CustomFlexDialogContent, CustomSaveDialogActions } from "~/components/DialogLayout/Custom";
import CustomSaveDialogLayout, { CustomSaveDialogTitleBar } from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import type { ProjectRouteParams } from "../../ProjectsRoutes/ProjectRouteParams";
const styles = require("./OclEditorDialogLayout.less");

interface OclEditorDialogLayoutState extends DataBaseComponentState {
    ocl: string | null;
}

interface OclEditorDialogLayoutPropsPublic extends RenderProps {
    resource: ICanBeVersionControlled;
    getOcl(): Promise<string>;
    modifyOcl(ocl: string): Promise<string>;
    onSave(): Promise<void>;
    onClose(): void;
}

interface OclEditorDialogLayoutAnalyticsProps {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
}

type OclEditorDialogLayoutProps = RouteComponentProps<ProjectRouteParams> & WithProjectContextInjectedProps & OclEditorDialogLayoutPropsPublic;
type OclEditorDialogLayoutPropsInternal = OclEditorDialogLayoutProps & OclEditorDialogLayoutAnalyticsProps;

class OclEditorDialogLayoutInternal extends DataBaseComponent<OclEditorDialogLayoutPropsInternal, OclEditorDialogLayoutState> {
    constructor(props: OclEditorDialogLayoutPropsInternal) {
        super(props);
        this.state = {
            ocl: null,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const ocl = await this.props.getOcl();
            this.setState({ ocl });
        });
    }

    private save = async () => {
        return this.doBusyTask(async () => {
            const ocl = await this.props.modifyOcl(this.state.ocl ?? "");
            this.setState({ ocl });
            this.props.onSave();
        });
    };

    render() {
        return (
            <CustomSaveDialogLayout
                {...this.props}
                close={this.props.close}
                open={this.props.open}
                renderTitle={() => <CustomSaveDialogTitleBar title="View/Edit OCL" />}
                busy={this.state.busy}
                errors={this.errors}
                onSaveClick={this.save}
                renderActions={(renderProps) => (
                    <CustomDialogActions
                        actions={
                            <CustomSaveDialogActions
                                close={(wasSaved) => {
                                    if (!wasSaved) {
                                        const ev: ActionEvent = {
                                            action: Action.Cancel,
                                            resource: "Deployment process",
                                        };
                                        this.props.dispatchAction("Cancel Viewing or Editing OCL", ev);
                                    }
                                    renderProps.close();
                                }}
                                saveButtonLabel={"Commit"}
                                hideCancel={false}
                                onSaveClick={renderProps.onSaveClick}
                                savePermission={renderProps.savePermission}
                            />
                        }
                    />
                )}
                renderContent={(_) => (
                    <CustomFlexDialogContent>
                        <div className={styles.container}>
                            <Callout type={CalloutType.Warning} title="Please proceed with caution">
                                <p>This is the raw OCL (Octopus Configuration Language) that is stored in your Git repository. Modifying your OCL directly is only recommended for advanced users.</p>
                            </Callout>
                            {this.state.ocl && (
                                <CodeEditor
                                    value={this.state.ocl}
                                    allowFullScreen={false}
                                    language={TextFormat.PlainText}
                                    onChange={(x) => {
                                        this.setState({ ocl: x });
                                    }}
                                    autoFocus={true}
                                    containerClassName={styles.codeEditorContainer}
                                />
                            )}
                        </div>
                    </CustomFlexDialogContent>
                )}
            />
        );
    }
}

export function OclEditorDialogLayoutInternalWithAnalytics(props: OclEditorDialogLayoutProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();

    return <OclEditorDialogLayoutInternal {...props} dispatchAction={dispatchAction} trackAction={trackAction} />;
}

const OclEditorDialogLayout = withProjectContext(OclEditorDialogLayoutInternalWithAnalytics);
export default OclEditorDialogLayout;
