import cn from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import type { ActivityLogElement } from "~/client/resources/taskDetailsResource";
import { ActivityLogEntryCategory } from "~/client/resources/taskDetailsResource";
import DateFormatter from "~/utils/DateFormatter";
import TextWithLinks from "../TextWithLinks/TextWithLinks";
const styles = require("./style.less");

interface TaskLogLineProps {
    line: ActivityLogElement;
    showAdditional(): void;
}

export default class TaskLogLine extends React.Component<TaskLogLineProps> {
    constructor(props: TaskLogLineProps) {
        super(props);
    }

    shouldComponentUpdate(nextProps: TaskLogLineProps) {
        return !isEqual(this.props.line, nextProps.line);
    }

    render() {
        const line = this.props.line;
        if (line.Category === ActivityLogEntryCategory.Gap) {
            const showAdditional = (e: React.MouseEvent) => {
                e.preventDefault();
                this.props.showAdditional();
            };
            return (
                <div className={styles.gap}>
                    <a href="" onClick={showAdditional}>
                        {line.MessageText}
                    </a>
                </div>
            );
        }

        const occurred = DateFormatter.dateToCustomFormat(line.OccurredAt, "MMMM Do YYYY HH:mm:ss");

        return (
            <div className={cn(styles.logLine, this.getElementClass(line.Category))}>
                <div className={styles.occurred}>{occurred}</div>
                <div className={styles.category}>{line.Category}</div>
                <div className={styles.message}>
                    <TextWithLinks message={line.MessageText} allowHtml={false} /> {line.Detail}
                </div>
            </div>
        );
    }

    getElementClass = (category: ActivityLogEntryCategory) => {
        switch (category) {
            case ActivityLogEntryCategory.Fatal:
            case ActivityLogEntryCategory.Error:
                return styles.elementError;
            case ActivityLogEntryCategory.Warning:
                return styles.elementWarning;
            case ActivityLogEntryCategory.Highlight:
                return styles.elementHighlight;
            case ActivityLogEntryCategory.Wait:
                return styles.elementWait;
            case ActivityLogEntryCategory.Verbose:
                return styles.elementVerbose;
            default:
                return styles.elementInfo;
        }
    };
}
