import React from "react";
import type { OctopusError } from "../../client/resources";
import { useRequiredContext } from "../../hooks";
import type { Errors } from "../DataBaseComponent";
import { DatabaseComponentContextProvider } from "../DataBaseComponent/DataBaseComponentContext";
import { createErrorsFromOctopusError } from "../DataBaseComponent/Errors";
import { FieldErrorContextProvider, useFieldErrors } from "../FieldErrorContext/FieldErrorContext";

type ErrorActions = {
    setErrors: (errors: OctopusError) => void;
    setValidationErrors: (message: string, errors: ValidationErrors) => void;
    clearErrors: () => void;
};

type ValidationError = string;
type ValidationErrorName = string;
type ValidationErrors = Record<ValidationErrorName, ValidationError>;

type ValidationState = {
    message: string | null;
    errors: ValidationErrors;
};

type ErrorState = {
    currentError: OctopusError | null;
    validation: ValidationState;
};

const OctopusErrorContext = React.createContext<OctopusError | null | undefined>(undefined);
OctopusErrorContext.displayName = "OctopusErrorContext";
const ErrorActionContext = React.createContext<ErrorActions | undefined>(undefined);
ErrorActionContext.displayName = "ErrorActionContext";
const ValidationStateContext = React.createContext<ValidationState | undefined>(undefined);

const INITIAL_STATE: ErrorState = {
    currentError: null,
    validation: {
        errors: {},
        message: null,
    },
};

export const ErrorContextProvider: React.FC = (props) => {
    const [state, setState] = React.useState<ErrorState>(INITIAL_STATE);

    const actions: ErrorActions = React.useMemo(
        () => ({
            setErrors: (error: OctopusError) =>
                setState((prev) => ({
                    ...prev,
                    currentError: error,
                    validation: {
                        message: error.ErrorMessage,
                        errors: { ...prev.validation.errors, ...error.Details },
                    },
                })),
            setValidationErrors: (message, errors) => setState((prev) => ({ ...prev, validation: { ...prev.validation, message, errors } })),
            clearErrors: () => setState(INITIAL_STATE),
        }),
        [setState]
    );

    return (
        <OctopusErrorContext.Provider value={state.currentError}>
            <ErrorActionContext.Provider value={actions}>
                <ValidationStateContext.Provider value={state.validation}>
                    <FieldErrorContextProvider fieldErrors={state.validation.errors}>
                        <DatabaseComponentContextProvider>{props.children}</DatabaseComponentContextProvider>
                    </FieldErrorContextProvider>
                </ValidationStateContext.Provider>
            </ErrorActionContext.Provider>
        </OctopusErrorContext.Provider>
    );
};

ErrorContextProvider.displayName = "ErrorContextProvider";

export const useErrorActions = () => {
    return useRequiredContext(ErrorActionContext, "ErrorAction");
};

export const useOctopusError = () => {
    return useRequiredContext(OctopusErrorContext, "OctopusErrorContext");
};

export const useValidationErrors = () => {
    return useRequiredContext(ValidationStateContext, "ValidationState");
};

export const useErrorMessage = () => {
    const errors = useOctopusError();
    const validationErrors = useValidationErrors();
    return errors?.ErrorMessage ?? validationErrors?.message;
};

export const useErrors = () => {
    const currentError = useOctopusError();
    const { getAllFieldErrors } = useFieldErrors();
    const fieldErrors = getAllFieldErrors();
    const message = useErrorMessage();

    return React.useMemo((): Errors | undefined => {
        if (currentError) {
            const converted = createErrorsFromOctopusError(currentError);
            return { ...converted, fieldErrors: { ...converted.fieldErrors, ...fieldErrors } };
        }

        if (message) {
            return { message, fieldErrors, details: {}, errors: Object.values(fieldErrors) };
        }

        return undefined;
    }, [currentError, fieldErrors, message]);
};

export default ErrorContextProvider;
