/* eslint-disable no-restricted-imports */
import type { ChipClassKey, ChipProps as MaterialChipProps } from "@material-ui/core/Chip";
import MaterialChip from "@material-ui/core/Chip";
import type { ClassNameMap } from "@material-ui/styles/withStyles/withStyles";
import classNames from "classnames";
import * as React from "react";
import styles from "./StyledMaterialChip.less";

type ChipStyleProps = {
    backgroundColor?: string;
    labelColor?: string;
    markAsRemoved?: boolean;
    borderColor?: string;
    fullWidth?: boolean;
    noMargin?: boolean;
    accessibilityRole?: string;
    backgroundColorOnHover?: string;
};

type ExposedMaterialChipProps = Pick<MaterialChipProps, "onClick" | "onDelete" | "deleteIcon" | "label" | "variant" | "tabIndex" | "icon" | "className">;

type StyledMaterialChipProps = ChipStyleProps & ExposedMaterialChipProps;

export function StyledMaterialChip(props: StyledMaterialChipProps) {
    const rootMarginClass = props.noMargin ? styles.rootNoMargin : styles.rootMargin;
    const labelTextDecorationClass = props.markAsRemoved ? styles.labelTextDecorationRemoved : styles.labelTextDecoration;
    const labelWidthClass = props.fullWidth ? styles.labelFullWidth : styles.labelWidth;
    const classes: Partial<ClassNameMap<ChipClassKey>> = {
        root: classNames(styles.root, rootMarginClass),
        label: classNames(styles.label, labelTextDecorationClass, labelWidthClass),
    };

    const rootBackgroundColorStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : undefined;
    const rootLabelColorStyle = props.labelColor ? { color: props.labelColor } : undefined;
    const rootStyleProps =
        rootBackgroundColorStyle || rootLabelColorStyle
            ? {
                  style: {
                      ...rootBackgroundColorStyle,
                      ...rootLabelColorStyle,
                  },
              }
            : {};

    //We can't just pass role through as undefined as it's a primitive aria attribute which will end up overriding anything material-ui provides
    //we therefore omit this prop when it hasn't been specified to allow the role to be changed if needed.
    const ariaProps = props.accessibilityRole ? { role: props.accessibilityRole } : {};

    return <MaterialChip classes={classes} icon={props.icon} label={props.label} onClick={props.onClick} tabIndex={props.tabIndex} variant={props.variant} onDelete={props.onDelete} deleteIcon={props.deleteIcon} {...rootStyleProps} {...ariaProps} />;
}
