/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { ServerTimezoneResource } from "~/client/resources";
import { ExpandableFormSection, Summary, Select, Note } from "~/components/form";

interface ScheduleTimezoneProps {
    timezones: ServerTimezoneResource[];
    timezone: string;
    onTimezoneChanged(timezone: string): void;
}

export default class ScheduleTimezone extends React.Component<ScheduleTimezoneProps> {
    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="ScheduleTimezones" title="Schedule Timezone" summary={this.scheduleTimezoneSummary()} help="Select the timezone used for the schedule.">
                    <Select value={this.props.timezone} allowClear={true} onChange={(timezone) => this.props.onTimezoneChanged(timezone!)} items={this.props.timezones.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Select timezone" />
                    <Note>Schedule run times will be calculated using this timezone.</Note>
                </ExpandableFormSection>
            </div>
        );
    }

    private scheduleTimezoneSummary() {
        return this.props.timezone ? Summary.summary(this.props.timezone) : Summary.placeholder("No timezone selected");
    }
}
