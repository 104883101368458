import React from "react";
import { useOctopusTheme } from "~/components/Theme";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
const styles = require("./ProcessActionErrorIndicator.less");

interface ProcessActionWarningIndicatorProps {
    actionWarnings: string[];
}

export const ProcessActionWarningIndicator: React.FC<ProcessActionWarningIndicatorProps> = ({ actionWarnings }) => {
    const theme = useOctopusTheme();
    const items = actionWarnings.map((x) => <li>{x}</li>);

    return (
        <ToolTip content={<ul className={styles.itemContainer}>{items}</ul>}>
            <div className={styles.indicatorContainer}>
                <em className="fa fa-exclamation-triangle" style={{ color: theme.alert }} />
            </div>
        </ToolTip>
    );
};
