import cn from "classnames";
import type { History } from "history";
import { forwardRef } from "react";
import * as React from "react";
import InternalLink from "./InternalLink";
const styles = require("./style.less");

type LocationDescriptor = History.LocationDescriptor;

interface ListInternalLinkProps extends React.HTMLProps<HTMLElement> {
    size?: number;
    to: LocationDescriptor;
    className?: string;
    onClick?: () => void;
    accessibleName?: string;
}

const ListInternalLink = forwardRef<HTMLAnchorElement, ListInternalLinkProps>((props, ref) => {
    return (
        <InternalLink accessibleName={props.accessibleName} className={cn(styles.listInternalLink, props.className)} size={props.size} to={props.to} onClick={props.onClick}>
            {props.children}
        </InternalLink>
    );
});

export default ListInternalLink;
