/* eslint-disable @typescript-eslint/consistent-type-assertions */

import React from "react";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import type { MachineModelHealthStatus, SummaryResource } from "~/client/resources";
import InternalLink from "~/components/Navigation/InternalLink";
import MachineHealthStatusHelper from "~/utils/MachineHealthStatusHelper";
import { HealthStatusContentIconContainer } from "./HealthStatusContentIconContainer";
const styles = require("../style.less");

interface HealthStatusContentRowProps {
    summaryResource: SummaryResource;
    link: (obj: object) => string;
    status: keyof typeof MachineModelHealthStatus;
}

export const HealthStatusContentRow = ({ summaryResource, link, status }: HealthStatusContentRowProps) => {
    const totalMachines = summaryResource.MachineHealthStatusSummaries[status] || 0;
    if (!totalMachines || totalMachines === 0) {
        return null;
    }
    const healthStatus = status as MachineModelHealthStatus;
    const healthStatusName = MachineHealthStatusHelper.getFriendlyName(healthStatus);
    return (
        <div className={styles.healthStatusRowsContainer} key={healthStatus}>
            <HealthStatusContentIconContainer>
                <MachineHealthStatusIcon healthStatus={healthStatus} title={"Health status"} iconSize={"medium"} />
            </HealthStatusContentIconContainer>
            <div className={styles.healthStatusName}>
                <InternalLink to={link({ healthStatuses: healthStatus })}>{healthStatusName}</InternalLink>
            </div>
            <div className={styles.healthStatusMachinesCount}>{totalMachines.toLocaleString()}</div>
        </div>
    );
};
