/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Checkbox, Note } from "~/components/form";
import FileUploadDropzone from "../../../../primitiveComponents/form/FileUploadDragDrop/FileUploadDragDrop";
import { SupportedPackageTypes } from "./Onboarding";
const styles = require("./style.less");

interface UploadPackageProps {
    afterPackageUpload(packageId: string): void;
}

interface UploadPackagePropsInternal extends UploadPackageProps {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
}

interface UploadPackageState extends DataBaseComponentState {
    file?: File | null;
    replace: boolean;
}

class UploadPackageInternal extends DataBaseComponent<UploadPackagePropsInternal, UploadPackageState> {
    constructor(props: UploadPackagePropsInternal) {
        super(props);
        this.state = { replace: false };
    }

    async save() {
        return this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Package",
            };

            return await this.props.trackAction("Save Upload Package", ev, async () => {
                const pkg = await repository.Packages.upload(this.state!.file!, this.state.replace);
                this.props.afterPackageUpload(pkg.Id);
            });
        });
    }

    render() {
        const cancel = () => {
            const ev: ActionEvent = {
                action: Action.Cancel,
                resource: "Package",
            };

            this.props.dispatchAction("Cancel uploading Package", ev);

            return true;
        };

        return (
            <SaveDialogLayout title="Upload a Package" busy={this.state.busy} errors={this.errors} saveButtonLabel="Upload" busyButtonLabel="Uploading..." onSaveClick={() => this.save()} onCancelClick={cancel}>
                <div className={styles.dropzoneArea}>
                    <FileUploadDropzone label={"Drag and drop a package containing all the files to deploy or select to browse"} onFilesChanged={(file) => this.setState({ file: file[0] })} />
                </div>
                <Note>
                    Supported packages include {SupportedPackageTypes}.<br />
                    Learn more about <ExternalLink href="SupportedPackages">supported packages</ExternalLink>.
                </Note>
                <Checkbox label="Replace this version if it exists" value={this.state.replace} onChange={(replace) => this.setState({ replace })} />
            </SaveDialogLayout>
        );
    }
}

export function PackageUpload(props: UploadPackageProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();

    return <UploadPackageInternal {...props} dispatchAction={dispatchAction} trackAction={trackAction} />;
}
