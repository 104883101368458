/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import type { AccountResource } from "~/client/resources/accountResource";
import { AccountType } from "~/client/resources/accountResource";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Note from "~/primitiveComponents/form/Note/Note";
import routeLinks from "~/routeLinks";
import { useKeyedItemAccess } from "../../KeyAccessProvider/KeyedItemAccessProvider";
import type { KeyedItemProps, NameOrIdKey } from "../../KeyAccessProvider/types";
import SelectWithAddRefresh from "../SelectWithAddRefresh/SelectWithAddRefresh";
const styles = require("./style.less");

type AccountSelectProps = FormFieldProps<string> & {
    multiple?: boolean;
    fixedLabel?: boolean;
    type: AccountType | AccountType[];
    items: AccountResource[];
    placeholder?: string;
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    onRequestRefresh(): Promise<any>;
};

interface TextState {
    error?: string;
    refreshing: boolean;
}

export const AccountSelect: React.FC<AccountSelectProps> = (props) => {
    const keyedBy = useKeyedItemAccess();
    return <AccountSelectInternal itemKey={keyedBy} {...props} />;
};

type AccountSelectInternalProps = AccountSelectProps & KeyedItemProps;

export class AccountSelectInternal extends React.Component<AccountSelectInternalProps, TextState> {
    constructor(props: AccountSelectInternalProps) {
        super(props);
        this.state = {
            error: null!,
            refreshing: false,
        };
    }

    handleChange = (accountId: string | undefined) => {
        const value = accountId === "" ? null : accountId;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange!(value!);
    };

    getItems(itemKey: NameOrIdKey) {
        const map = (acc: AccountResource) => ({ value: acc[itemKey], text: acc.Name });
        const types = Array.isArray(this.props.type) ? this.props.type : [this.props.type];

        return this.props.items.filter((a) => types.find((t) => a.AccountType === t)).map(map);
    }

    selectionRenderer = (accountIdOrName: string) => {
        const account = this.props.items.find((acc) => acc[this.props.itemKey] === accountIdOrName);
        if (!account) {
            return accountIdOrName;
        }
        return <div className={styles.innerContainer}>{account.Name}</div>;
    };

    onlyTypeIs(type: string) {
        const types = Array.isArray(this.props.type) ? this.props.type : [this.props.type];
        return types.length === 1 && types[0] === type;
    }

    render() {
        const { items, onChange, onValidate, onRequestRefresh, itemKey, ...otherProps } = this.props;

        let warning = "";
        if (this.onlyTypeIs(AccountType.AzureSubscription)) {
            warning = "Only Azure Management Certificate Accounts may be selected.";
        }

        if (this.onlyTypeIs(AccountType.AzureServicePrincipal)) {
            warning = "Only Azure Service Principal Accounts may be selected.";
        }

        if (this.onlyTypeIs(AccountType.AmazonWebServicesAccount)) {
            warning = "Only Amazon Web Service Accounts may be selected.";
        }

        if (this.onlyTypeIs(AccountType.GoogleCloudAccount)) {
            warning = "Only Google Cloud Accounts may be selected.";
        }

        if (this.onlyTypeIs(AccountType.SshKeyPair)) {
            warning = "Only SSH Key Pair Accounts may be selected.";
        }

        if (this.onlyTypeIs(AccountType.Token)) {
            warning = "Only Token Accounts may be selected.";
        }

        if (this.onlyTypeIs(AccountType.UsernamePassword)) {
            warning = "Only Username/Password Accounts may be selected.";
        }

        return (
            <div className={styles.outerContainer}>
                <SelectWithAddRefresh
                    addUrl={`#${routeLinks.infrastructure.accounts.root}`}
                    onRequestRefresh={this.onRequestRefresh}
                    label="Select account"
                    {...otherProps}
                    allowFilter={true}
                    onChange={this.handleChange}
                    error={this.state.error || this.props.error}
                    items={this.getItems(this.props.itemKey)}
                    selectionRenderer={this.selectionRenderer}
                />
                {warning && <Note>{warning}</Note>}
            </div>
        );
    }

    private onRequestRefresh = async () => {
        this.setState({ refreshing: true });
        try {
            await this.props.onRequestRefresh();
        } finally {
            this.setState({ refreshing: false });
        }
    };
}

export const BoundAccountSelect = withBoundField(AccountSelect);
export default AccountSelect;
