import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const ListeningTarget = (props: Partial<SvgImageProps>) => (
    <SvgImage viewBox="0 0 200 200" title="Listening Target" {...props}>
        <path
            className={styles.imgCyan}
            d="M164.3,0H36.2C32.8,0,30,2.7,30,6.1v82.8c0,3.4,2.8,6.1,6.2,6.1H81l19-32.6L119,95h45.4c3.4,0,6.2-2.7,6.2-6.1
	V6.1C170.5,2.7,167.7,0,164.3,0z"
        />
        <path
            className={styles.imgWhite}
            d="M87.5,83.8L100,62.4l9.7,16.6c4-1.6,7.9-3.5,11.8-6c12.1-7.8,22.9-26.1,17.5-40.4l0.1,0.2
	C125.4-3,84.1,13,78.1,32.1c-3.4,10.8,5.1,8.9,12.7,3.7c12.9-9.2,27.8-6,25.6,7.5c-2.5,14.3-22.9,14.9-44.1,6.7
	c-15.6-6.1-30-15.1-42.2-26.6v47.5C30,70.9,57.2,85.7,87.5,83.8z M122.5,21c0.6-0.6,1.5-0.9,2.3-0.8c1.8,0.1,3.5,0.9,4.7,2.3
	c0.9,0.9,1.5,2.1,1.8,3.3c0.2,0.6,0.2,1.3,0,1.9c-0.2,0.6-0.5,1.2-1,1.6h0c-0.6,0.6-1.5,0.9-2.3,0.8c-1.8-0.1-3.4-0.9-4.6-2.3
	C121.2,25.4,120.8,22.5,122.5,21z M102.7,19.8c-0.8,0.4-1.7,0.6-2.6,0.6c-0.4,0-0.9,0-1.2-0.2c-0.4-0.2-0.7-0.5-0.9-0.9
	c-0.6-1.3,0.4-2.8,2.6-3.8c0.8-0.4,1.7-0.6,2.6-0.6c0.4,0,0.9,0,1.2,0.2s0.7,0.5,0.9,0.9C105.9,17.3,104.9,18.8,102.7,19.8z
	 M114.4,19.9c-2.8-0.7-4.5-2.3-4.1-3.9c0.1-0.5,0.8-1.8,3.3-1.8c0.8,0,1.5,0.1,2.2,0.3c2.8,0.7,4.5,2.3,4.1,3.9
	c-0.1,0.5-0.8,1.8-3.3,1.8C115.8,20.2,115.1,20.1,114.4,19.9z M88.8,61.1c0.2-0.4,0.5-0.7,0.9-0.9c0.7-0.4,1.4-0.7,2.2-0.9
	c0.5-0.1,1-0.1,1.5-0.1c1.6,0,2.7,0.6,2.9,1.6c0.3,1.4-1,2.6-3.3,3.1c-0.5,0.1-1,0.2-1.5,0.2c-1.6,0-2.7-0.6-2.9-1.6
	C88.6,62,88.6,61.5,88.8,61.1z M43.4,43.7c-0.3,0.3-0.6,0.5-0.9,0.6c-0.3,0.1-0.7,0.2-1.1,0.2c-2-0.2-3.8-1.1-5.2-2.5
	c-3.3-2.9-3.6-5.5-2.4-6.7c0.3-0.3,0.6-0.5,0.9-0.6c0.3-0.1,0.7-0.2,1.1-0.2c2,0.2,3.8,1.1,5.2,2.5C44.2,39.8,44.6,42.4,43.4,43.7z
	 M61.5,52.6c-0.3,0.4-0.6,0.7-1.1,1c-0.4,0.2-0.9,0.3-1.4,0.2c-1.3-0.1-2.6-0.4-3.8-1c-2.8-1.4-4.2-3.5-3.4-5.1
	c0.3-0.4,0.6-0.8,1.1-1c0.4-0.2,0.9-0.3,1.4-0.2c1.3,0.1,2.6,0.4,3.8,1C60.9,49,62.3,51.1,61.5,52.6z M80.7,59.4L80.7,59.4
	c-0.2,1.3-1.6,2-3.9,2h-1.1c-1.5,0-2.9-0.6-4-1.6c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.1-0.5-0.1-0.8c0.2-1.3,1.6-2.1,4-2.1h1.1
	c1.5,0,2.9,0.6,4,1.6c0.2,0.2,0.3,0.4,0.4,0.7C80.7,58.9,80.7,59.1,80.7,59.4z"
        />
        <path className={styles.imgWhite} d="M175.6,85.6l0.1,0.2L175.6,85.6z" />
        <path className={styles.imgNavy} d="M100.1,200c22.7,0,41.1-18.4,41.1-41.1s-18.4-41.1-41.1-41.1c-22.7,0-41.1,18.4-41.1,41.1S77.4,200,100.1,200z" />
        <path
            className={styles.imgWhite}
            d="M77.6,173.1c3.8-2.6,8.2-7.1,6.5-12.3c-1.2-2.7-1.9-5.5-2.4-8.4c-0.1-2.6,0.2-5.2,1.1-7.7
	c1.7-4.6,5-8.3,9.1-10.4c4.2-2.1,8.9-2.4,13.3-0.9c8.4,2.7,14.2,13.2,10.7,22.3c-2,5.2-2.9,9.3,1.6,13.4c1.2,1.1,4.2,2.8,4.1,4.7
	c0,2.6-4.7-0.5-5.3-1c0.6,1.1,6.5,7.8,2.8,8.2c-3.5,0.4-6.6-4.8-8.6-7c-3.5-3.8-2.9,4.5-2.9,6.3c0,2.7-1.8,8.2-5,4.6
	c-2.7-3-1.7-7.7-3.5-10.9c-2-3.6-5.3,3.6-6.2,4.9c-0.8,1.3-5.6,8.6-7.5,4.8c-1.5-3.1,0.9-7.9,2.1-10.7c-0.4,1-3.5,2.5-4.4,3
	c-2,1.1-4.2,1.7-6.4,1.6C72,177.1,75.7,174.4,77.6,173.1z"
        />
        <polygon className={styles.imgNavy} points="122,107.2 100,69.4 100,69.4 77.9,107.2 " />
        <path className={styles.imgNavy} d="M105,87v38H95V87H105z" />
    </SvgImage>
);
export { ListeningTarget };
