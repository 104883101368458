import * as React from "react";
import type { ProjectSummaryResource } from "~/client/resources";
import Card from "~/components/Card/Card";
import Logo from "~/components/Logo/Logo";
import Markdown from "~/components/Markdown";
import routeLinks from "~/routeLinks";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
const styles = require("./style.less");

interface ProjectCardProps {
    project: ProjectSummaryResource;
}

const ProjectCard: React.FC<ProjectCardProps> = (props) => {
    const project = props.project;
    return (
        <Card
            logo={<Logo url={props.project.Links.Logo} isDisabled={project.IsDisabled} />}
            link={<InternalLink to={{ pathname: routeLinks.project(project).root, state: { project } }} />}
            header={project.Name}
            content={<Markdown markup={project.Description} />}
            includeContentFade={true}
            isDisabled={project.IsDisabled}
            className={styles.projectCard}
        />
    );
};

ProjectCard.displayName = "ProjectCard";

export default ProjectCard;
