import React = require("react");
import { useState } from "react";
import { useSelector } from "react-redux";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { getFormattedCommitMessage } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { ProcessType } from "~/client/resources";
import { Text } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import ActionButton, { ActionButtonType } from "../../../../components/Button";
import { useProjectContext } from "../../context";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";
import { CommitSummaryAndDetails } from "../VersionControl/CommitDialog";
const styles = require("./styles.less");

interface DeleteRunbookProps {
    runbookName: string;
    onChange(canDelete: boolean): void;
    onCommitMessageUpdate(message: string): void;
}

const defaultCommitMessage = "Delete Runbook";

const isConfigurationAsCodeForRunbooksEnabledSelector = (state: GlobalState) => state.configurationArea.features.isConfigurationAsCodeForRunbooksEnabled;

const DeleteRunbook: React.FC<DeleteRunbookProps> = ({ runbookName, onChange, onCommitMessageUpdate }) => {
    const [confirmationRunbookName, updateConfirmationRunbookName] = useState<string>("");
    const [commitMessage, setCommitMessage] = useState<CommitMessageWithDetails>({ summary: "", details: "" });
    const [showAdvanced, updateShowAdvanced] = useState<boolean>(false);
    const projectContext = useProjectContext();
    const isConfigurationAsCodeForRunbooksEnabled = useSelector(isConfigurationAsCodeForRunbooksEnabledSelector);

    const onRunbookNameConfirmation = (confirmationRunbookName: string) => {
        updateConfirmationRunbookName(confirmationRunbookName);
        onChange(confirmationRunbookName.replace(/\s+/g, " ").toLowerCase() === runbookName.replace(/\s+/g, " ").toLowerCase());
    };

    const deleteVCSRunbook = () => {
        const updateCommitMessage = (commitMessage: CommitMessageWithDetails) => {
            setCommitMessage(commitMessage);
            onCommitMessageUpdate(getFormattedCommitMessage(commitMessage, defaultCommitMessage));
        };

        return (
            <div className={styles.versionControlledRunbookSettingsSpacing}>
                A commit named <span className={styles.embolden}>{defaultCommitMessage}</span> will be added to the <span className={styles.embolden}>{projectContext.state.branch?.Name}</span> branch.
                <div>
                    <ActionButton
                        label={showAdvanced ? "Hide Advanced" : "Show Advanced"}
                        type={ActionButtonType.Ternary}
                        className={styles.showAdvancedLink}
                        onClick={(e) => {
                            e.preventDefault();
                            updateShowAdvanced(!showAdvanced);
                        }}
                    />
                </div>
                {showAdvanced && (
                    <div className={styles.branchAndCommitSection}>
                        <CommitSummaryAndDetails
                            onCommitMessageChanged={updateCommitMessage}
                            defaultSummary={defaultCommitMessage}
                            commitMessage={commitMessage}
                            commitMessageAccessibleName={"Commit message for deleting a runbook"}
                            commitDetailsAccessibleName={"Commit details for deleting a runbook"}
                        />
                    </div>
                )}
            </div>
        );
    };

    const isVersionControlled = isVersionControlledProcess(projectContext.state.model.IsVersionControlled, ProcessType.Runbook, !!isConfigurationAsCodeForRunbooksEnabled);
    return (
        <div>
            <Callout title="This is a destructive action" type={CalloutType.Danger}>
                This action <strong>cannot</strong> be undone. This will permanently delete the <strong>{runbookName}</strong> Runbook and all of its contents, including Runbook Snapshots and Runbook Runs history.
            </Callout>
            <div>
                <p>Please type in the name of the Runbook ({runbookName}) to confirm.</p>
                <Text accessibleName={"Name of runbook to be deleted"} value={confirmationRunbookName} onChange={onRunbookNameConfirmation} />
            </div>
            {isVersionControlled && deleteVCSRunbook()}
        </div>
    );
};

export default DeleteRunbook;
