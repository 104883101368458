import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticErrorCallback, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import AdvancedProjectSection from "~/areas/projects/components/Projects/AdvancedProjectSection";
import type { ResourcesById } from "~/client/repositories/basicRepository";
import type { LifecycleResource, ProjectGroupResource, EnvironmentResource, ProjectSummaryResource, ProjectResource } from "~/client/resources";
import { Permission } from "~/client/resources";
import { session, repository } from "~/clientInstance";
import { ActionButton, ActionButtonType } from "~/components/Button";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Text, required, Checkbox } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import routeLinks from "../../../../routeLinks";

export interface AddProjectDialogProps {
    groupId?: string;
    projectCreated: (project: ProjectResource, option?: ProjectCreatedOption) => void;
}

export type ProjectCreatedOption = {
    vcsRedirect: boolean;
    newlyCreatedProjectRedirect: boolean;
};

type InternalAddProjectDialogProps = AddProjectDialogProps & {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
};

interface AddProjectDialogState extends DataBaseComponentState {
    vcsRedirect: boolean;
    name: string;
    description: string;
    newProjectId: string | undefined;
    projectGroupId: string | undefined;
    selectedLifecycle: LifecycleResource | undefined;
    projectGroups: ProjectGroupResource[];
    lifecycles: LifecycleResource[] | undefined;
    environmentsById: ResourcesById<EnvironmentResource>;
    otherProjects: ProjectSummaryResource[];
    showLifecycleMap: boolean;
    showAdvanced: boolean;
}

class AddProjectInternal extends DataBaseComponent<InternalAddProjectDialogProps, AddProjectDialogState> {
    constructor(props: InternalAddProjectDialogProps) {
        super(props);
        this.state = {
            vcsRedirect: false,
            name: "",
            description: "",
            projectGroupId: undefined,
            selectedLifecycle: undefined,
            projectGroups: [],
            lifecycles: undefined,
            environmentsById: {},
            newProjectId: undefined,
            otherProjects: [],
            showLifecycleMap: false,
            showAdvanced: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [projectGroups, lifecycles, environmentsById] = await Promise.all<ProjectGroupResource[], LifecycleResource[], ResourcesById<EnvironmentResource>>([
                repository.ProjectGroups.all(),
                isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.all() : Promise.resolve([]),
                repository.Environments.allById(),
            ]);

            const lifecyclePreviews = await Promise.all(lifecycles.map((l) => repository.Lifecycles.preview(l)));
            const lifecycle = lifecyclePreviews.find((x: LifecycleResource) => x.Name === "Default Lifecycle");
            const projectGroup = projectGroups.find((x) => x.Name === "All Projects" || x.Name === "Default Project Group");
            const projectGroupId = this.props.groupId || (projectGroup ? projectGroup.Id : projectGroups[0].Id);

            this.setState({
                lifecycles: lifecyclePreviews,
                projectGroups,
                environmentsById,
                selectedLifecycle: lifecycle || lifecyclePreviews[0],
                projectGroupId,
            });
        });
    }

    async save() {
        await this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Project",
                isCaCenabled: this.state.vcsRedirect ? "True" : "False",
            };

            await this.props.trackAction("Save Project", ev, async (cb: AnalyticErrorCallback) => {
                if (!this.state.projectGroupId) {
                    this.setValidationErrors("You need to select a project group");
                    cb("Missing Project Group");
                    return false;
                }

                if (!this.state.selectedLifecycle) {
                    this.setValidationErrors("You need to select a lifecycle");
                    cb("Missing Lifestyle");
                    return false;
                }

                const result = await repository.Projects.create({
                    Name: this.state.name,
                    Description: this.state.description,
                    ProjectGroupId: this.state.projectGroupId,
                    LifecycleId: this.state.selectedLifecycle.Id,
                    ProjectConnectivityPolicy: { AllowDeploymentsToNoTargets: true },
                });

                // refresh permissions to include the new project
                if (session.currentUser) {
                    const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser, true);
                    session.refreshPermissions(permissionSet);
                }
                this.props.projectCreated(result, { vcsRedirect: this.state.vcsRedirect, newlyCreatedProjectRedirect: true });
                return true;
            });
        });

        return false;
    }

    render() {
        if (!this.state.lifecycles) {
            return <SaveDialogLayout title="Add New Project" busy={true} errors={this.errors} onSaveClick={() => this.save()} />;
        }

        const showAdvancedButton = this.state.lifecycles.length <= 1;

        return (
            <SaveDialogLayout
                title="Add New Project"
                busy={this.state.busy}
                errors={this.errors}
                saveButtonLabel={this.state.vcsRedirect ? "Save and configure VCS" : undefined}
                saveButtonDisabled={this.state.name.length === 0}
                onSaveClick={() => this.save()}
                onCancelClick={() => {
                    this.props.dispatchAction("Cancel adding Project", { resource: "Project", action: Action.Cancel });
                    return true;
                }}
            >
                <PermissionCheck
                    permission={Permission.LifecycleView}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.LifecycleView} permission is required to create a project
                        </Callout>
                    }
                >
                    {!this.state.busy && this.state.lifecycles.length === 0 && (
                        <Callout type={CalloutType.Danger} title="No lifecycles Configured">
                            <InternalLink to={routeLinks.library.lifecycles}>Configure your lifecycles</InternalLink> before setting up a project.
                        </Callout>
                    )}
                    {!this.state.busy && Object.keys(this.state.environmentsById).length === 0 && (
                        <Callout type={CalloutType.Warning} title="No Environments Configured">
                            Please consider <InternalLink to={routeLinks.infrastructure.overview}>configuring your infrastructure</InternalLink> before setting up a project.
                        </Callout>
                    )}
                    <Text label="New project name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a project name")} autoFocus={true} />
                    <Checkbox
                        label="Use version control for this project"
                        value={this.state.vcsRedirect}
                        onChange={() => {
                            this.setState({ vcsRedirect: !this.state.vcsRedirect });
                        }}
                        note={"If set, you will be redirected to the VCS Settings page to configure version control when you save."}
                    />
                    {showAdvancedButton && (
                        <ActionButton
                            label={this.state.showAdvanced ? "Hide Advanced" : "Show Advanced"}
                            type={ActionButtonType.Ternary}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({ showAdvanced: !this.state.showAdvanced });
                            }}
                        />
                    )}
                    {(!showAdvancedButton || this.state.showAdvanced) && (
                        <AdvancedProjectSection {...this.state} onDescriptionChanged={this.handleDescriptionChanged} onProjectGroupChanged={(newValue) => this.setState({ projectGroupId: newValue })} onLifecycleChanged={this.handleLifeCycleChange} />
                    )}
                </PermissionCheck>
            </SaveDialogLayout>
        );
    }

    private handleLifeCycleChange = (value: string | undefined) => {
        const lifecycles = this.state.lifecycles?.filter((l) => l.Id === value) || [];
        this.setState({ selectedLifecycle: lifecycles.length > 0 ? lifecycles[0] : undefined });
    };

    private handleDescriptionChanged = (description: string) => {
        this.setState({ description });
    };
}

const AddProject: React.FC<AddProjectDialogProps> = (props) => {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();

    return <AddProjectInternal {...props} dispatchAction={dispatchAction} trackAction={trackAction} />;
};

export default AddProject;
