/* eslint-disable @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-explicit-any */
import classnames from "classnames";
import IconButton from "material-ui/IconButton";
import ClearFix from "material-ui/internal/ClearFix";
import transitions from "material-ui/styles/transitions";
import * as PropTypes from "prop-types";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { baseSizeInPx } from "~/fontWeights";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import type { Origin } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import { MenuItemButton } from "~/primitiveComponents/navigation/MenuItems/MenuItemButton/MenuItemButton";
import { MenuList } from "~/primitiveComponents/navigation/MenuList/MenuList";
import { noOp } from "~/utils/noOp";
const keycode = require("keycode");
const stylesheet = require("./style.less");

type DropdownMenuOptionVariant = "danger";

export interface DropdownMenuOption {
    value: string | undefined | null;
    text: string;
    icon?: JSX.Element;
    disabled?: boolean;
    onChange?: () => void;
    variant?: DropdownMenuOptionVariant;
}

function getStyles(props: DropDownMenuProps, context: any) {
    const { disabled } = props;
    const spacing = context.muiTheme.baseTheme.spacing;
    const palette = context.muiTheme.baseTheme.palette;
    const accentColor = context.muiTheme.dropDownMenu.accentColor;
    return {
        control: {
            cursor: disabled ? "not-allowed" : "pointer",
            height: "100%",
            position: "relative",
            width: "100%",
        },
        icon: {
            fill: accentColor,
            width: `1.5rem`,
            height: `1.5rem`,
            padding: 0,
        },
        iconChildren: {
            fill: "inherit",
        },
        label: {
            color: disabled ? palette.disabledColor : palette.textColor,
            height: `${spacing.desktopToolbarHeight}px`,
            lineHeight: `${spacing.desktopToolbarHeight}px`,
            overflow: "hidden",
            opacity: 1,
            position: "relative",
            paddingLeft: spacing.desktopGutter,
            paddingRight: spacing.iconSize * 2 + spacing.desktopGutterMini,
            textOverflow: "ellipsis",
            top: 0,
            whiteSpace: "nowrap",
        },
        labelWhenOpen: {
            opacity: 0,
            top: spacing.desktopToolbarHeight / 8,
        },
        root: {
            display: "inline-block",
            fontSize: spacing.desktopDropDownMenuFontSize,
            height: spacing.desktopSubheaderHeight,
            fontFamily: context.muiTheme.baseTheme.fontFamily,
            outline: "none",
            position: "relative",
            transition: transitions.easeOut(),
        },
        rootWhenOpen: {
            opacity: 1,
        },
        underline: {
            borderTop: `solid 0.0625rem ${accentColor}`,
            bottom: 1,
            left: 0,
            margin: `-0.0625rem ${spacing.desktopGutter}px`,
            right: 0,
            position: "absolute",
        },
        buttons: {
            position: "absolute",
            right: 0,
            top: `${13 / baseSizeInPx}rem`,
        },
    };
}

interface DropDownMenuProps {
    allowClear: boolean;

    /**
     * This is the point on the anchor that the popover"s
     * `targetOrigin` will attach to.
     * Options:
     * vertical: [top, center, bottom]
     * horizontal: [left, middle, right].
     */
    anchorOrigin: Origin;

    /**
     * If true, the popover will apply transitions when
     * it gets added to the DOM.
     */
    animated: boolean;

    /**
     * Override the default animation component used.
     */
    animation: () => void;

    autoFocus: boolean;

    /**
     * The width will automatically be set according to the items inside the menu.
     * To control this width in css instead, set this prop to `false`.
     */
    autoWidth: boolean;

    /**
     * The css class name of the root element.
     */
    className: string;

    /**
     * Disables the menu.
     */
    disabled: boolean;

    /**
     * Overrides default `SvgIcon` dropdown arrow component.
     */
    iconButton: React.ReactNode;

    /**
     * Overrides the styles of icon element.
     */
    iconStyle: React.CSSProperties;

    /**
     * Overrides the styles of label when the `DropDownMenu` is inactive.
     */
    labelStyle: React.CSSProperties;

    /**
     * The style object to use to override underlying list style.
     */
    listStyle: React.CSSProperties;

    /**
     * The maximum height of the `Menu` when it is displayed.
     */
    maxHeight: number;

    /**
     * Override the inline-styles of menu items.
     */
    menuItemStyle: React.CSSProperties;

    /**
     * Overrides the styles of `Menu` when the `DropDownMenu` is displayed.
     */
    menuStyle: React.CSSProperties;

    /**
     * Callback function fired when a menu item is clicked, other than the one currently selected.
     * @param {string} value If `multiple` is true, the menu"s `value`
     * array with either the menu item"s `value` added (if
     * it wasn"t already selected) or omitted (if it was already selected).
     * Otherwise, the `value` of the menu item.
     */
    onChange: (value: string) => void;

    /**
     * Callback function fired when the menu is closed.
     */
    onClose: () => void;

    /**
     * Set to true to have the `DropDownMenu` automatically open on mount.
     */
    openImmediately: boolean;

    /**
     * Override the inline-styles of selected menu items.
     */
    selectedMenuItemStyle: React.CSSProperties;

    /**
     * Callback function fired when a menu item is clicked, other than the one currently selected.
     *
     * @param {string} The `value` of the menu item.
     * @param {any} menuItem The selected `MenuItemButton`.
     * If `multiple` is true, this will be an array with the `MenuItemButton`s matching the `value`s parameter.
     */
    selectionRenderer: ((value: string, menuItem: any) => React.ReactNode) | undefined;

    /**
     * Override the inline-styles of the root element.
     */
    style: React.CSSProperties;

    /**
     * This is the point on the popover which will attach to
     * the anchor"s origin.
     * Options:
     * vertical: [top, center, bottom]
     * horizontal: [left, middle, right].
     */
    targetOrigin: Origin;

    transformOrigin: Origin;

    /**
     * Overrides the inline-styles of the underline.
     */
    underlineStyle: React.CSSProperties;

    value: string | undefined;

    filter: React.ReactNode;

    /**
     * If provided, adds a value to a visually hidden and accessible input
     * as an alternative to the display value
     */
    selectedValueAccessibleName: string;
    items: DropdownMenuOption[];
    empty?: string;
}

interface DropDownMenuState {
    open: boolean;
    anchorEl: any;
}

//eslint-disable-next-line react/no-unsafe
export class DropDownMenu extends React.Component<DropDownMenuProps, DropDownMenuState> {
    static muiName = "DropDownMenu";

    static defaultProps: Partial<DropDownMenuProps> = {
        animated: true,
        autoWidth: true,
        disabled: false,
        iconButton: <ThirdPartyIcon iconType={ThirdPartyIconType.ArrowDropDown} />,
        openImmediately: false,
        maxHeight: 500,
        allowClear: false,
        anchorOrigin: {
            vertical: "top",
            horizontal: "left",
        },
        items: [],
        empty: "No Items",
    };

    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };

    state: DropDownMenuState = {
        open: false,
        anchorEl: null,
    };

    componentDidMount() {
        if (this.props.autoWidth) {
            this.setWidth();
        }
        if (this.props.openImmediately) {
            // TODO: Temporary fix to make openImmediately work with popover.
            setTimeout(
                () =>
                    this.setState({
                        open: true,
                        anchorEl: this.rootNode,
                    }),
                0
            );
        }

        if (this.props.autoFocus) {
            // this focuses
            this.close();
        }
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.props.autoWidth) {
            this.setWidth();
        }
    }

    rootNode = undefined as any;
    arrowNode = undefined as any;
    firstMenuNode = React.createRef<HTMLButtonElement>();
    filterContainerRef = React.createRef<HTMLDivElement>();

    /**
     * This method is deprecated but still here because the TextField
     * need it in order to work. TODO: That will be addressed later.
     */
    getInputNode() {
        const rootNode = this.rootNode;

        rootNode.focus = () => {
            if (!this.props.disabled) {
                this.setState({
                    open: !this.state.open,
                    anchorEl: this.rootNode,
                });
            }
        };

        return rootNode;
    }

    setWidth() {
        const el = this.rootNode;
        if (!this.props.style || !this.props.style.hasOwnProperty("width")) {
            el.style.width = "auto";
        }
    }

    handleTouchTapControl = (event: any) => {
        event.preventDefault();
        if (!this.props.disabled) {
            this.setState({
                open: !this.state.open,
                anchorEl: this.rootNode,
            });
        }
    };

    handleKeyDown = (event: React.KeyboardEvent<{}>) => {
        switch (keycode(event)) {
            case "up":
            case "down":
                event.preventDefault();
                event.stopPropagation();
                this.setState({
                    open: true,
                    anchorEl: this.rootNode,
                });
                break;
        }
    };

    handleClearTouchTap = (event: any) => {
        event.stopPropagation();
        event.preventDefault();

        if (this.props.onChange) {
            this.props.onChange("");
        }
    };

    handleFilterKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (keycode(event)) {
            case "esc":
                this.close();
                break;
            case "tab":
            case "down":
                if (this.state.open && this.props.items.length > 0 && this.firstMenuNode.current) {
                    this.firstMenuNode.current.focus();
                    event.preventDefault();
                }
                break;
        }
    };

    close = () => {
        this.setState(
            {
                open: false,
            },
            () => {
                if (this.props.onClose) {
                    this.props.onClose();
                }

                requestAnimationFrame(() => {
                    if (!this.arrowNode) return;

                    const dropArrow = this.arrowNode;
                    // eslint-disable-next-line react/no-find-dom-node
                    const dropNode = ReactDOM.findDOMNode(dropArrow) as HTMLElement;
                    dropNode.focus();
                    dropArrow.setKeyboardFocus(true);
                });
            }
        );
    };

    handleCloseMenuWhenPressingTab: React.KeyboardEventHandler = (event) => {
        if (keycode(event.keyCode) === "tab" && !this.props.filter) {
            this.close();
        }
    };

    render() {
        const {
            animated,
            animation,
            autoWidth,
            children,
            className,
            disabled,
            iconStyle,
            labelStyle,
            listStyle,
            maxHeight,
            menuStyle: menuStyleProp,
            selectionRenderer,
            onClose,
            openImmediately,
            menuItemStyle,
            selectedMenuItemStyle,
            style,
            underlineStyle,
            value,
            iconButton,
            anchorOrigin,
            transformOrigin,
            allowClear,
            onChange,
            selectedValueAccessibleName,
            empty,
            autoFocus = true,
            ...other
        } = this.props;
        const { anchorEl, open } = this.state;

        const { prepareStyles } = this.context.muiTheme;
        const styles = getStyles(this.props, this.context);

        const currentOption = this.props.items.find((x) => x.value === value);

        const displayValue: React.ReactNode = selectionRenderer && currentOption && currentOption.value ? selectionRenderer(currentOption.value, currentOption) : currentOption?.text ?? currentOption?.value;

        let menuStyle = undefined;
        if (anchorEl && !autoWidth) {
            const filterContainerRefWidth = this.filterContainerRef.current?.clientWidth ?? 0;
            const largestWidth = anchorEl.clientWidth > filterContainerRefWidth ? anchorEl.clientWidth : filterContainerRefWidth;
            menuStyle = Object.assign(
                {
                    width: largestWidth,
                },
                menuStyleProp
            );
        } else {
            menuStyle = menuStyleProp;
        }

        // TODO: We need to pass a better accessible name in
        const accessibleName = "drop down menu";
        return (
            <div
                {...other}
                ref={(node) => {
                    this.rootNode = node;
                }}
                className={className}
                style={prepareStyles(Object.assign({}, styles.root, open && styles.rootWhenOpen, style))}
            >
                <ClearFix style={styles.control as any} onClick={this.handleTouchTapControl}>
                    <div style={prepareStyles(Object.assign({}, styles.label, open && styles.labelWhenOpen, labelStyle))}>{displayValue}</div>
                    <input type="text" readOnly className={stylesheet.visuallyHidden} value={selectedValueAccessibleName ?? ""} tabIndex={-1} />
                    <div style={styles.buttons as any}>
                        {allowClear && value && (
                            <IconButton
                                onFocus={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }} // stop focus moving to parent
                                disabled={disabled}
                                onClick={this.handleClearTouchTap}
                                style={Object.assign({}, styles.icon, iconStyle)}
                                iconStyle={styles.iconChildren}
                                {...{ "aria-label": "ClearSelection" }}
                            >
                                <ThirdPartyIcon iconType={ThirdPartyIconType.Clear} />
                            </IconButton>
                        )}
                        <IconButton
                            disabled={disabled}
                            onKeyDown={disabled ? noOp : this.handleKeyDown}
                            ref={(node) => {
                                this.arrowNode = node;
                            }}
                            style={Object.assign({}, styles.icon, iconStyle)}
                            iconStyle={styles.iconChildren}
                            {...{ "aria-label": "ToggleDropDown" }}
                        >
                            {iconButton}
                        </IconButton>
                    </div>
                    <div style={prepareStyles(Object.assign({}, styles.underline, underlineStyle))} />
                </ClearFix>
                <Popover anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} anchorEl={anchorEl} open={open} onClose={this.close} className={stylesheet.popover}>
                    {this.props.filter && (
                        <div onKeyDown={this.handleFilterKeyDown} ref={this.filterContainerRef}>
                            {this.props.filter}
                        </div>
                    )}
                    {this.props.items.length === 0 && this.props.empty && <span className={stylesheet.empty}>{this.props.empty}</span>}

                    <div onKeyDown={this.handleCloseMenuWhenPressingTab} tabIndex={-1} className={stylesheet.menuListScrollContainer} style={menuStyle}>
                        <MenuList accessibleName={accessibleName}>
                            {this.props.items.map((menuItem, index) => {
                                const refProps = index === 0 ? { ref: this.firstMenuNode } : {};

                                return (
                                    <MenuItemButton
                                        key={menuItem.value ?? index}
                                        {...refProps}
                                        onClick={() => {
                                            if (menuItem.disabled) {
                                                return;
                                            }

                                            this.props.onChange?.(menuItem.value ?? "");
                                            this.close();
                                            menuItem.onChange?.();
                                        }}
                                        isSelected={menuItem.value === this.props.value}
                                        autoFocus={autoFocus && !Boolean(this.props.filter) && ((index === 0 && !Boolean(value)) || menuItem.value === this.props.value)}
                                        disabled={menuItem.disabled}
                                        compact={true}
                                    >
                                        <MenuItemContentWithIcon icon={menuItem.icon} isDisabled={menuItem.disabled} text={menuItem.text} variant={menuItem.variant} />
                                    </MenuItemButton>
                                );
                            })}
                        </MenuList>
                    </div>
                </Popover>
            </div>
        );
    }
}

interface MenuItemContentWithIconProps {
    isDisabled: boolean | undefined;
    text: string;
    icon: React.ReactNode | undefined;
    variant: DropdownMenuOptionVariant | undefined;
}

function MenuItemContentWithIcon({ icon, text, isDisabled, variant }: MenuItemContentWithIconProps) {
    return (
        <div className={classnames(stylesheet.menuItemContent, { [stylesheet.danger]: variant === "danger" })}>
            {icon && <div className={stylesheet.menuItemContentIcon}>{icon}</div>}
            <div className={stylesheet.menuItemContentText}>
                {text}
                {isDisabled && " (disabled)"}
            </div>
        </div>
    );
}
