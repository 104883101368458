import * as React from "react";
import getActionLogoUrl from "~/areas/projects/components/getActionLogoUrl";
import type { IProcessResource } from "~/client/resources/deploymentProcessResource";
import Logo from "~/components/Logo/Logo";
import type { ActionToggleInfo } from "./deploymentStepsWorker";
const styles = require("./style.less");

interface ActionSummaryProps {
    process: IProcessResource;
    action: ActionToggleInfo;
    stepActionIdsToSkip?: string[];
    actionIsExcluded?: boolean;
}

const ActionSummary: React.SFC<ActionSummaryProps> = (props) => {
    return (
        <div className={styles.logo}>
            <Logo size="1.5rem" url={getLogoUrl(props.action, props.process)} />
            <span className={props.actionIsExcluded ? styles.excludedAction : null}>
                {isActionDisabledOrSkipped(props.action) ? (
                    <span className={styles.skipStepPreviewTitle}>
                        {props.action.details.ActionNumber} {props.action.details.ActionName}
                    </span>
                ) : (
                    <span>
                        {props.action.details.ActionNumber}. {props.action.details.ActionName}
                    </span>
                )}
            </span>
            {props.actionIsExcluded && <span className={styles.excludedActionPostfixLabel}> (excluded)</span>}
        </div>
    );

    function isActionDisabledOrSkipped(action: ActionToggleInfo) {
        return (props.stepActionIdsToSkip && props.stepActionIdsToSkip.some((id) => id === action.details.ActionId)) || action.details.IsDisabled;
    }
};

export function getLogoUrl(action: ActionToggleInfo, process: IProcessResource) {
    const findAction = () => {
        for (const key of process.Steps) {
            for (const actionResource of key.Actions) {
                if (actionResource.Id === action.Id) {
                    return actionResource;
                }
            }
        }

        return null;
    };

    return getActionLogoUrl(findAction());
}

export default ActionSummary;
