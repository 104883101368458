import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

const Onboarding = () => {
    return (
        <OnboardingPage
            title="Create tags to easily classify and group tenants"
            learnMore={
                <p>
                    If you would like more information, or hints on how to get started with tags, take a look at our <ExternalLink href="MultiTenantTags">documentation</ExternalLink>.
                </p>
            }
        />
    );
};

export default Onboarding;
