import React from "react";
import { GitCommit } from "~/client/resources/versionControlledResource";

interface GitRefProps {
    value: string | GitCommit;
}

const GitBranch: React.FC<GitRefProps & React.ComponentProps<"span">> = ({ value }, props) => {
    return <span {...props}>{value.substr("refs/heads/".length)}</span>;
};

const GitTag: React.FC<GitRefProps & React.ComponentProps<"span">> = ({ value }, props) => {
    return <span {...props}>{value.substr("refs/tags/".length)}</span>;
};

const GitCommit: React.FC<GitRefProps & React.ComponentProps<"span">> = ({ value }, props) => {
    const shortGitCommit = (commit: string, length = 7) => {
        return commit.substring(0, length);
    };

    return <span {...props}>{shortGitCommit(value)}</span>;
};

const GitRef: React.FC<GitRefProps & React.ComponentProps<"span">> = ({ value }, props) => {
    if (value) {
        if (value.startsWith("refs/heads/")) return <GitBranch value={value} />;
        if (value.startsWith("refs/tags/")) return <GitTag value={value} />;
        if (/^[0-9a-f]{7,40}$/i.test(value)) return <GitCommit value={value} />;
    }

    return <span {...props}>{value}</span>;
};

export { GitBranch, GitTag, GitCommit };
export default GitRef;
