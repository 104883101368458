import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
const styles = require("../../../primitiveComponents/dataDisplay/SvgImage/styles.less");

const StarFish = (props: Partial<SvgImageProps>) => (
    <SvgImage {...props} viewBox="0 0 66 63" title="Sign In Authentication">
        <g id="starfish" fillRule="nonzero" strokeWidth="1">
            <path
                className={styles.imgCyan}
                d="M32.97 55.68l-17.137 7.014c-2.234.915-4.65-.84-4.47-3.245l1.382-18.45L.773 26.885c-1.562-1.84-.639-4.678 1.707-5.25l17.99-4.388 9.736-15.738c1.27-2.052 4.257-2.052 5.526 0l9.736 15.738 17.99 4.388c2.346.572 3.27 3.41 1.708 5.25L53.193 41l1.382 18.45c.181 2.406-2.236 4.16-4.47 3.245L32.969 55.68h.001z"
            />
            <path
                className={styles.imgWhite}
                d="M32.97 27.376a4.081 4.081 0 0 0-4.084 4.08 4.082 4.082 0 0 0 4.083 4.079 4.082 4.082 0 0 0 2.887-6.966 4.081 4.081 0 0 0-2.887-1.194M39.39 39.28a1.78 1.78 0 1 0 .001 3.56 1.781 1.781 0 1 0 0-3.56m3.353 5.584a1.623 1.623 0 1 0-.002 3.246 1.623 1.623 0 0 0 .002-3.246m3.353 5.585a1.466 1.466 0 1 0-.076 2.931 1.466 1.466 0 0 0 .075-2.931m3.354 5.584a1.308 1.308 0 1 0-.073 2.615 1.308 1.308 0 0 0 .073-2.615M26.916 39.28a1.78 1.78 0 1 1 0 3.56 1.781 1.781 0 1 1 0-3.56m-3.353 5.584a1.623 1.623 0 1 1 .002 3.246 1.623 1.623 0 0 1-.002-3.246m-3.353 5.585a1.466 1.466 0 1 1 .079 2.93 1.466 1.466 0 0 1-.079-2.93m-3.353 5.584a1.308 1.308 0 1 1 .074 2.615 1.308 1.308 0 0 1-.074-2.615m25.584-26.935a1.782 1.782 0 0 0 3.414 1.022 1.78 1.78 0 0 0-1.196-2.215 1.78 1.78 0 0 0-2.218 1.193m6.317-1.605a1.625 1.625 0 0 0 3.112.932 1.624 1.624 0 1 0-3.112-.932m6.316-1.606a1.466 1.466 0 1 0 2.81.84 1.466 1.466 0 0 0-2.81-.84m6.317-1.607a1.309 1.309 0 1 0 2.507.751 1.309 1.309 0 0 0-2.507-.75m-37.332 4.572a1.782 1.782 0 1 1-3.39 1.1 1.782 1.782 0 0 1 3.39-1.1m-6.352-1.46a1.624 1.624 0 1 1-3.09 1.003 1.624 1.624 0 0 1 3.09-1.003m-6.352-1.461a1.468 1.468 0 0 1-2.79.906 1.467 1.467 0 0 1 2.79-.906m-6.352-1.46a1.31 1.31 0 0 1-2.49.808 1.309 1.309 0 1 1 2.49-.808M33.91 20.63a1.78 1.78 0 1 0-1.88 3.024 1.78 1.78 0 0 0 1.88-3.024m-1.78-3.487a1.624 1.624 0 0 0 1.714-2.757 1.625 1.625 0 0 0-2.438 1.748c.098.42.358.782.724 1.009m.101-6.511a1.465 1.465 0 1 0 1.545-2.49 1.465 1.465 0 0 0-1.545 2.49m.101-6.512a1.31 1.31 0 0 0 1.381-2.22 1.307 1.307 0 1 0-1.381 2.22"
            />
        </g>
    </SvgImage>
);
export { StarFish };
