/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { uniqBy } from "lodash";
import * as React from "react";
import MediaQuery from "react-responsive";
import type { ResourcesById } from "~/client/repositories/basicRepository";
import { PhaseProgress, TaskState } from "~/client/resources";
import type { ProjectResource, ReleaseResource, ChannelResource, DeploymentResource, LifecycleResource, LifecycleProgressionResource, PhaseProgressionResource, EnvironmentResource, TaskResource, IPhasedResource } from "~/client/resources";
import { Permission } from "~/client/resources/permission";
import { TenantedDeploymentMode } from "~/client/resources/tenantedDeploymentMode";
import { NavigationButton } from "~/components/Button";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import { ChannelChip } from "~/components/Chips";
import { PermissionCheck } from "~/components/PermissionCheck";
import Section from "~/components/Section";
import WarningIcon from "~/components/WarningIcon/WarningIcon";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { DataTable, DataTableHeader, DataTableHeaderColumn, DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable";
import Note from "~/primitiveComponents/form/Note/Note";
import routeLinks from "~/routeLinks";
import type { LifecycleStatus } from "~/utils/MapProgressionToStatus/MapProgressionToStatus";
import { isAllowed } from "../../../../../components/PermissionCheck/PermissionCheck";
import { DeploymentCreateGoal } from "../ReleasesRoutes/releaseRouteLinks";
import PhaseDeployments from "./PhaseDeployments";
import PhaseHeader from "./PhaseHeader";
const styles = require("./style.less");

const breakpoint = 600;

interface LifecycleProgressionProps {
    project: ProjectResource;
    release: ReleaseResource;
    channels: ChannelResource[];
    releaseChannel: ChannelResource;
    deploymentTasks: Array<TaskResource<{ DeploymentId: string }>>;
    lifecycle: LifecycleResource;
    lifecycleStatus: LifecycleStatus;
    progression: LifecycleProgressionResource;
    environmentsById: ResourcesById<EnvironmentResource>;
    deploymentsByPhase: { [phase: string]: DeploymentResource[] };
    progressionByPhase: { [phase: string]: PhaseProgressionResource };
    totalNumOfEnvironments: number;
    totalNumOfPhases: number;
    showLifecycleProgression: boolean;
    isCollapsable: boolean;
    hasPendingInteruptions: boolean;
    onLifecycleProgressionToggled(): void;
}

const LifecycleProgression: React.StatelessComponent<LifecycleProgressionProps> = (props) => {
    const renderLifecycleProgression = () => {
        const body = () => (
            <div>
                {props.showLifecycleProgression && (
                    <DataTable>
                        <DataTableHeader>{renderHeaderRow()}</DataTableHeader>
                        {renderPhaseRows()}
                    </DataTable>
                )}
                {!props.showLifecycleProgression && props.hasPendingInteruptions && (
                    <div>
                        <WarningIcon />1 or many deployments require manual intervention
                    </div>
                )}
            </div>
        );

        return (
            <PermissionCheck
                permission={Permission.TaskView}
                project={props.project.Id}
                wildcard={true}
                alternate={
                    <Callout type={CalloutType.Information} title={"Permission required"}>
                        The {Permission.TaskView} permission is required to view lifecycle progression and what tasks have run.
                    </Callout>
                }
            >
                {body}
            </PermissionCheck>
        );
    };

    const renderHeaderRow = () => {
        return (
            <DataTableRow>
                <DataTableHeaderColumn>Lifecycle</DataTableHeaderColumn>
                <DataTableHeaderColumn>Task</DataTableHeaderColumn>
                <DataTableHeaderColumn>When</DataTableHeaderColumn>
                <MediaQuery minWidth={breakpoint}>
                    <DataTableHeaderColumn />
                </MediaQuery>
            </DataTableRow>
        );
    };

    const renderPhaseRows = () => {
        const phases: IPhasedResource[] = props.lifecycle.Phases.length > 0 ? props.lifecycle.Phases : props.progression.Phases;

        return phases.map((phase: IPhasedResource, index: number) => {
            return buildPhaseRows(phase, index, props.deploymentsByPhase, props.progressionByPhase, props.environmentsById);
        });
    };

    const buildPhaseRows = (
        phase: IPhasedResource,
        index: number,
        deploymentsByPhase: { [phase: string]: DeploymentResource[] },
        progressionByPhase: { [phase: string]: PhaseProgressionResource },
        environmentsById: ResourcesById<EnvironmentResource>
    ) => {
        const environmentsInPhase = [...phase.AutomaticDeploymentTargets, ...phase.OptionalDeploymentTargets];

        const phaseRows = [];
        const deployments = deploymentsByPhase[phase.Name];
        const phaseProgression = progressionByPhase[phase.Name];
        const phaseHeader = (
            <span className={styles.phaseHeader}>
                {phase.Name}
                {phase.IsOptionalPhase ? <small>(optional)</small> : null}
                {phase.MinimumEnvironmentsBeforePromotion !== 0 ? <small>(any {phase.MinimumEnvironmentsBeforePromotion})</small> : null}
            </span>
        );

        const phaseRow =
            environmentsInPhase.length > 1 || !deployments || deployments.length === 0 ? (
                <PhaseHeader
                    phase={phase}
                    lifecycleStatus={props.lifecycleStatus}
                    key={index}
                    className={styles.deploymentsTableRow}
                    isOptional={phase.IsOptionalPhase}
                    title={phaseHeader}
                    actionButton={
                        renderRedeploymentButtonForPhase(deployments, props.deploymentTasks, "Redeploy...", "Redeploy to all...", `Redeploy ${phase.Name}`) ||
                        renderRetryButtonForPhase(deployments, props.deploymentTasks, "Try again...", "Try again all...", `Retry deployment for ${phase.Name}`) ||
                        renderDeploymentButtonForPhase(phaseProgression, "Deploy...", "Deploy to all...", `Deploy ${phase.Name}`)
                    }
                />
            ) : (
                <PhaseDeployments
                    key={index}
                    phase={phase}
                    lifecycleStatus={props.lifecycleStatus}
                    title={phase.Name}
                    deployments={deployments}
                    deploymentTasks={props.deploymentTasks}
                    environmentsById={environmentsById}
                    actionButton={
                        renderRedeploymentButtonForPhase(deployments, props.deploymentTasks, "Redeploy...", "Redeploy to all...", `Redeploy ${phase.Name}`) ||
                        renderRetryButtonForPhase(deployments, props.deploymentTasks, "Try again...", "Try again all...", `Retry deployment for ${phase.Name}`)
                    }
                />
            );

        phaseRows.push(
            phaseRow,
            environmentsInPhase.length > 1 && buildEnvironmentRows(environmentsInPhase, deployments, phase, environmentsById, progressionByPhase) // phaseRow is the PhaseHeader, now need to render env
        );
        return phaseRows;
    };

    const buildEnvironmentRows = (environmentIds: string[], deployments: DeploymentResource[], phase: IPhasedResource, environmentsById: ResourcesById<EnvironmentResource>, progressionByPhase: { [phase: string]: PhaseProgressionResource }) => {
        const phaseProgression = progressionByPhase[phase.Name];
        const phaseProgressionCompleted = phaseProgression.Progress === PhaseProgress.Complete;

        return environmentIds
            .filter((environmentId) => environmentsById.hasOwnProperty(environmentId))
            .map((environmentId, index) => {
                const deploymentsForEnvironment = deployments.filter((deployment) => {
                    return deployment.EnvironmentId === environmentId;
                });
                return deploymentsForEnvironment.length === 0 ? (
                    <PhaseHeader
                        phase={phase}
                        lifecycleStatus={props.lifecycleStatus}
                        key={index}
                        className={styles.phaseEnvironmentRow}
                        title={<span>{environmentsById[environmentId].Name}</span>}
                        isOptional={phase.IsOptionalPhase}
                        environmentId={environmentId}
                        actionButton={phaseProgressionCompleted ? renderEnvironmentScopedDeploymentAction("Deploy...", [environmentId], `Deploy ${phase.Name}`) : null}
                    />
                ) : (
                    <PhaseDeployments
                        key={index}
                        phase={phase}
                        lifecycleStatus={props.lifecycleStatus}
                        className={styles.phaseEnvironmentRow}
                        deployments={deploymentsForEnvironment}
                        deploymentTasks={props.deploymentTasks}
                        environmentsById={environmentsById}
                        actionButton={getActionButtonForEnvironmentDeployments(deploymentsForEnvironment)}
                    />
                );
            });
    };

    const getActionButtonForEnvironmentDeployments = (deploymentsForEnvironment: DeploymentResource[]) => {
        if (deploymentStateIs(TaskState.Success, deploymentsForEnvironment[0].Id, props.deploymentTasks)) {
            return renderEnvironmentScopedDeploymentAction("Redeploy...", [deploymentsForEnvironment[0].EnvironmentId], deploymentsForEnvironment[0].Name);
        } else if (deploymentStateIs(TaskState.Failed, deploymentsForEnvironment[0].Id, props.deploymentTasks) || deploymentStateIs(TaskState.Canceled, deploymentsForEnvironment[0].Id, props.deploymentTasks)) {
            return renderRetryAction("Try again...", deploymentsForEnvironment[0].Id, deploymentsForEnvironment[0].Name);
        }
        return null;
    };

    const renderChannelLifecycleDetails = () => {
        return (
            <Section>
                <div>
                    <div>
                        Lifecycle: <b style={{ marginRight: "1rem" }}>{props.lifecycle.Name}</b>
                        Channel: <ChannelChip channelName={props.releaseChannel.Name} />
                    </div>
                </div>
                {props.isCollapsable && (
                    <div>
                        <Note>
                            This lifecycle has {props.totalNumOfEnvironments} {props.totalNumOfEnvironments > 1 ? "environments" : "environment"}.
                        </Note>
                        {!props.showLifecycleProgression && (
                            <div style={{ marginTop: "0.5rem" }}>
                                <Callout type={CalloutType.Information} title="Progression hidden">
                                    <div>Due to the number of environments in this lifecycle, the progression has been hidden.</div>
                                    <ActionButton onClick={props.onLifecycleProgressionToggled} label={props.showLifecycleProgression ? "HIDE PROGRESSION" : "SHOW PROGRESSION"} type={ActionButtonType.Ternary} />
                                </Callout>
                            </div>
                        )}
                        {props.showLifecycleProgression && <ActionButton onClick={props.onLifecycleProgressionToggled} label={props.showLifecycleProgression ? "HIDE PROGRESSION" : null!} type={ActionButtonType.Secondary} />}
                    </div>
                )}
            </Section>
        );
    };

    const renderRedeploymentButtonForPhase = (deployments: DeploymentResource[] = [], deploymentTasks: Array<TaskResource<{ DeploymentId: string }>>, singleDeployLabel: string, multiDeployLabel: string, accessibleName: string | undefined) => {
        if (deployments.length === 0) {
            return null;
        }

        const uniqueDeploymentPerEnvironment = uniqBy(deployments, (d) => d.EnvironmentId);
        const environmentsWithError = uniqueDeploymentPerEnvironment
            .filter((deployment) => {
                const task = deploymentTasks.filter((deploymentTask) => deploymentTask.Arguments.DeploymentId === deployment.Id)[0];
                return task.State === TaskState.Success;
            })
            .map((deployment) => deployment.EnvironmentId);

        if (environmentsWithError.length !== uniqueDeploymentPerEnvironment.length) {
            return null;
        }

        return renderEnvironmentScopedDeploymentAction(environmentsWithError.length > 1 ? multiDeployLabel : singleDeployLabel, environmentsWithError, accessibleName);
    };

    const renderRetryButtonForPhase = (deployments: DeploymentResource[] = [], deploymentTasks: Array<TaskResource<{ DeploymentId: string }>>, singleDeployLabel: string, multiDeployLabel: string, accessibleName: string | undefined) => {
        if (deployments.length === 0) {
            return null;
        }

        const uniqueDeploymentPerEnvironment = uniqBy(deployments, (d) => d.EnvironmentId);
        const environmentsWithError = uniqueDeploymentPerEnvironment
            .filter((deployment) => {
                const task = deploymentTasks.filter((deploymentTask) => deploymentTask.Arguments.DeploymentId === deployment.Id)[0];
                return task.State === TaskState.Failed || task.State === TaskState.Canceled;
            })
            .map((deployment) => deployment.EnvironmentId);

        if (environmentsWithError.length !== uniqueDeploymentPerEnvironment.length) {
            return <div />;
        }

        return renderEnvironmentScopedDeploymentAction(environmentsWithError.length > 1 ? multiDeployLabel : singleDeployLabel, environmentsWithError, accessibleName);
    };

    const renderDeploymentButtonForPhase = (phaseProgress: PhaseProgressionResource, singleDeployLabel: string, multiDeployLabel: string, accessibleName: string | undefined) => {
        const phaseEnvironments = [...phaseProgress.AutomaticDeploymentTargets, ...phaseProgress.OptionalDeploymentTargets];
        const availableEnvironments = phaseEnvironments.filter((env) => {
            return props.progression.NextDeployments.indexOf(env) !== -1;
        });

        if (availableEnvironments.length === 0) {
            return null;
        }
        const tenantedOnlyDeployment = props.project.TenantedDeploymentMode === TenantedDeploymentMode.Tenanted;
        if (availableEnvironments.length > 1 && tenantedOnlyDeployment) {
            return null;
        }

        return renderEnvironmentScopedDeploymentAction(availableEnvironments.length > 1 ? multiDeployLabel : singleDeployLabel, availableEnvironments, accessibleName);
    };

    const renderEnvironmentScopedDeploymentAction = (label: string, environmentIds: string[], accessibleName: string | undefined) => {
        const url = `${routeLinks.project(props.project).release(props.release).deployments.create(DeploymentCreateGoal.To)}/${environmentIds.join(",")}`;
        return renderGenericDeploymentAction(label, url, accessibleName, environmentIds);
    };

    const renderGenericDeploymentAction = (label: string, url: string, accessibleName: string | undefined, environmentIds?: string[]) => {
        const hasPermissionToCreateDeploymentsInAllEnvironments = environmentIds
            ? environmentIds.every((e) =>
                  isAllowed({
                      permission: Permission.DeploymentCreate,
                      project: "*",
                      environment: e,
                      projectGroup: "*",
                      tenant: "*",
                  })
              )
            : true;

        if (hasPermissionToCreateDeploymentsInAllEnvironments) {
            return <NavigationButton href={url} label={label} accessibleName={accessibleName} />;
        }
        return <></>;
    };

    const renderRetryAction = (label: string, previousDeploymentId: string, accessibleName: string | undefined) => {
        const url = routeLinks.project(props.project).release(props.release).deployments.retry(previousDeploymentId);
        return renderGenericDeploymentAction(label, url, accessibleName);
    };

    const deploymentStateIs = (state: TaskState, deploymentId: string, deploymentTasks: Array<TaskResource<{ DeploymentId: string }>>) => {
        const task = deploymentTasks.filter((deploymentTask) => deploymentTask.Arguments.DeploymentId === deploymentId)[0];
        return task.State === state;
    };

    return (
        <>
            {renderChannelLifecycleDetails()}
            {renderLifecycleProgression()}
        </>
    );
};

LifecycleProgression.displayName = "LifecycleProgression";

export default LifecycleProgression;
