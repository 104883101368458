/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { range } from "lodash";
import Paper from "material-ui/Paper";
import * as React from "react";
import { DndProvider } from "react-dnd";
import TouchBackend from "react-dnd-touch-backend";
import { List as VirtualList, AutoSizer, WindowScroller } from "react-virtualized";
import { headerId } from "~/components/PaperLayout/PaperLayout";
import Sticky, { StickyStatus } from "~/components/Sticky/Sticky";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import AlignedScrollTableRow from "~/primitiveComponents/dataDisplay/ScrollTable/AlignedScrollTableRow/AlignedScrollTableRow";
import ResizeColumnHandleDragLayer from "~/primitiveComponents/dataDisplay/ScrollTable/ResizeColumnHandleDragLayer/ResizeColumnHandleDragLayer";
import { BorderCss } from "~/utils/BorderCss/BorderCss";
const styles = require("./style.less");

const TouchBackendOptions = {
    enableMouseEvents: true,
};

export interface CellAlignmentArgs {
    customColumnWidthsInPercent?: number[];
    showResizeHandles?: boolean;
}

export type CellAligner = (cells: JSX.Element[], optionalArgs?: CellAlignmentArgs) => JSX.Element;

export interface RenderArgs {
    columnWidthsInPercent: ReadonlyArray<number>;
    borderStyle: BorderCss;
    cellAligner: CellAligner;
}

export interface RowRenderArgs extends RenderArgs {
    index: number;
    isVisible: boolean;
}

interface ScrollTableProps {
    relativeColumnWidths: ReadonlyArray<number>;
    minimumColumnWidthsInPx: ReadonlyArray<number>;
    rowCount: number;
    overscanRowCount: number;
    shouldVirtualize: boolean;
    onColumnWidthsChanged(relativeColumnWidths: ReadonlyArray<number>): void;
    rowHeight(index: number): number;
    headers(renderArgs: RenderArgs): React.ReactNode[];
    rowRenderer(rowRenderArgs: RowRenderArgs): React.ReactNode;
}

interface ScrollTableState {
    headerStickyState: StickyStatus;
}

let scrollTableCount = 0;

function createBorderStyle(theme: OctopusTheme) {
    return new BorderCss(0.0625, "solid", theme.divider);
}

class ScrollTable extends React.Component<ScrollTableProps, ScrollTableState> {
    private readonly scrollTableId: number;
    private rowCellAligner: CellAligner = undefined!;
    private windowScroller: WindowScroller | null = null;
    private timeoutId?: number;
    private virtualList: VirtualList | null = null;

    constructor(props: ScrollTableProps) {
        super(props);
        this.state = {
            headerStickyState: StickyStatus.STATUS_ORIGINAL,
        };
        this.scrollTableId = scrollTableCount++;
        this.setRowCellAligner();
    }

    get relativeColumnWidthsInPercent() {
        return convertRelativeSizesToPercentages(this.props.relativeColumnWidths);
    }

    componentDidMount() {
        this.refreshWindowPosition();
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            window.clearTimeout(this.timeoutId);
        }
    }

    render() {
        return withTheme((theme) => {
            const borderStyle = createBorderStyle(theme);

            const headerRenderArgs: RenderArgs = {
                columnWidthsInPercent: this.relativeColumnWidthsInPercent,
                borderStyle,
                cellAligner: (cells, optionalArgs) => cellAlignerInner(cells, optionalArgs, this),
            };

            const rowRenderer: (args: any) => JSX.Element = (args: any) => {
                return this.renderRow(args, borderStyle);
            };

            return (
                <DndProvider backend={TouchBackend} options={TouchBackendOptions}>
                    <div id={`scrollTable-${this.scrollTableId}`} className={styles.table}>
                        <ResizeColumnHandleDragLayer />
                        <div className={styles.headerContainer}>
                            {/*The use of `headerId` here assumes that the scroll tables sticky anchor is always the paper layouts sticky header*/}
                            <Sticky top={`#${headerId}`} innerZ={9} bottomBoundary={`#scrollTable-${this.scrollTableId}`} onStateChange={(state) => this.setState({ headerStickyState: state.status })}>
                                <Paper style={this.state.headerStickyState === StickyStatus.STATUS_FIXED ? {} : { boxShadow: "none" }}>
                                    {this.props.headers(headerRenderArgs).map((h, index) => {
                                        return <div key={index}>{h}</div>;
                                    })}
                                </Paper>
                            </Sticky>
                        </div>
                        <div className={styles.tableBody}>
                            {this.props.shouldVirtualize && (
                                <WindowScroller ref={(windowScroller) => (this.windowScroller = windowScroller)}>
                                    {({ height, isScrolling, onChildScroll, scrollTop }) => (
                                        <AutoSizer disableHeight={true}>
                                            {({ width }) => (
                                                <VirtualList
                                                    autoHeight={true}
                                                    tabIndex={-1}
                                                    height={height}
                                                    scrollTop={scrollTop}
                                                    onScroll={onChildScroll}
                                                    isScrolling={isScrolling}
                                                    rowCount={this.props.rowCount}
                                                    rowHeight={({ index }: { index: number }) => this.props.rowHeight(index)}
                                                    width={width}
                                                    className={styles.virtualList}
                                                    overscanRowCount={this.props.overscanRowCount}
                                                    rowRenderer={rowRenderer}
                                                    ref={(virtualList) => (this.virtualList = virtualList)}
                                                />
                                            )}
                                        </AutoSizer>
                                    )}
                                </WindowScroller>
                            )}
                            {!this.props.shouldVirtualize &&
                                range(0, this.props.rowCount).map((ind) => {
                                    return rowRenderer({
                                        key: ind,
                                        index: ind,
                                        isVisible: true,
                                        style: {},
                                    });
                                })}
                        </div>
                    </div>
                </DndProvider>
            );
        });
    }

    onColumnWidthsChanged = (newColumnWidths: ReadonlyArray<number>) => {
        // Run it through convertRelativeSizesToPercentages again to ensure that everything is rounded appropriately
        // and adds up to exactly 100%
        this.props.onColumnWidthsChanged(convertRelativeSizesToPercentages(newColumnWidths));
        // For performance reasons, we use `shouldComponentUpdate` in the variable editor.
        // The cell aligner is one of the properties that we watch to determine whether to re-render a variable row
        // By changing the cell aligner function instance, we can trigger the rows to re-render.
        this.setRowCellAligner();
        if (this.virtualList) {
            // Need to tell the virtual list that something has changed and it must re-render
            this.virtualList.forceUpdateGrid();
        }
    };

    private renderRow({ key, index, isVisible, style }: any, borderStyle: BorderCss) {
        const cells = this.props.rowRenderer({
            columnWidthsInPercent: this.relativeColumnWidthsInPercent,
            index,
            isVisible,
            cellAligner: this.rowCellAligner,
            borderStyle,
        });

        return (
            <div style={style} key={key}>
                {cells}
            </div>
        );
    }

    private setRowCellAligner() {
        this.rowCellAligner = (cells: JSX.Element[], optionalArgs?: CellAlignmentArgs) => cellAlignerInner(cells, optionalArgs, this);
    }

    private refreshWindowPosition() {
        this.timeoutId = window.setTimeout(() => {
            if (this.windowScroller) {
                // https://github.com/bvaughn/react-virtualized/blob/master/docs/WindowScroller.md#updateposition
                // This needs to be called if anything above the table in the dom moves, so that the table
                // can re-evaluate its position w.r.t. the window. It sucks to have to be so coupled to whatever
                // is displayed above the table, so instead lets just re-check the position every 500ms
                // so we don't have to worry about it
                this.windowScroller.updatePosition();
            }
            this.refreshWindowPosition();
        }, 500);
    }
}

function cellAlignerInner(cells: JSX.Element[], optionalArgs: CellAlignmentArgs | undefined, scrollTable: ScrollTable): JSX.Element {
    const emptyCellAlignmentArgs: CellAlignmentArgs = {};
    const { customColumnWidthsInPercent, showResizeHandles } = optionalArgs || emptyCellAlignmentArgs;
    return (
        <AlignedScrollTableRow
            cells={cells}
            showResizeHandles={showResizeHandles!}
            relativeColumnWidthsInPercent={customColumnWidthsInPercent || scrollTable.relativeColumnWidthsInPercent}
            onColumnWidthsChanged={scrollTable.onColumnWidthsChanged}
            minimumColumnWidthsInPx={scrollTable.props.minimumColumnWidthsInPx}
        />
    );
}

function convertRelativeSizesToPercentages(relativeColumnSizes: ReadonlyArray<number>) {
    const totalSize = sum(relativeColumnSizes);
    const allColumnsExceptLast = relativeColumnSizes.slice(0, relativeColumnSizes.length - 1);
    const columnSizePercentageExceptLast = allColumnsExceptLast.map((relativeColumnSize) => {
        return (relativeColumnSize / totalSize) * 100;
    });
    const lastColumnSize = 100 - sum(columnSizePercentageExceptLast);
    return [...columnSizePercentageExceptLast, lastColumnSize];

    function sum(numbers: ReadonlyArray<number>) {
        return numbers.reduce((p, c) => p + c, 0);
    }
}

export default ScrollTable;
