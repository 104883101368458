import cn from "classnames";
import * as React from "react";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/ThirdPartyIcon/ThirdPartyIcon";
import type { ExternalLinkProps } from "./ExternalLink";
import ExternalLink from "./ExternalLink";
const styles = require("./style.less");

const ExternalVideoLink: React.SFC<ExternalLinkProps> = (props: ExternalLinkProps) => {
    // Display default CTA when there is no children
    const defaultText = "Watch in action";

    const { className, ...restProps } = props;

    return (
        <ExternalLink className={cn([styles.externalVideoLink, className])} {...restProps}>
            <ThirdPartyIcon iconType={ThirdPartyIconType.AvPlayCircleFilled} />
            {React.Children.count(props.children) > 0 ? props.children : defaultText}
        </ExternalLink>
    );
};

export default ExternalVideoLink;
