/* eslint-disable @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-non-null-assertion */

import _ from "lodash";
import * as React from "react";
import { generateDefaultActionContainer, isRunOnBuiltInWorker, isRunOnServerOrWorkerPool } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
// TODO: Cleanup - We should seriously reconsider importing from other areas like this.
import type { RunOn, RunOnServerOrWorkerPool } from "~/areas/projects/components/Process/types";
import { ExecutionLocation, RunOnBuiltInWorker, RunOnDeploymentTarget, RunOnWorkerPool } from "~/areas/projects/components/Process/types";
import type { AccountResource, CertificateResource, KubernetesEndpointResource, ProxyResource, WorkerPoolResource } from "~/client/resources";
import {
    AccountType,
    KubernetesAuthenticationType,
    KubernetesAwsAuthentication,
    KubernetesAzureAuthentication,
    KubernetesCertificateAuthentication,
    KubernetesGoogleCloudAuthentication,
    KubernetesPodServiceAccountAuthentication,
    KubernetesStandardAccountAuthentication,
    Permission,
} from "~/client/resources";
import type { FeedResource } from "~/client/resources/feedResource";
import { repository } from "~/clientInstance";
import { ExecutionContainerImageSelector, StepExecutionOption } from "~/components/ContainerSelector";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { KubernetesCluster } from "~/components/Images/MachineSettings/KubernetesCluster";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Text } from "~/components/form";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import CertificateSelect from "~/components/form/CertificateSelect/CertificateSelect";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import StringCheckbox from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "~/primitiveComponents/form/Note/Note";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import Select from "~/primitiveComponents/form/Select/Select";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
import EndpointCard from "./EndpointCard";
import kubernetesCategory from "./KubernetesCategoryDefinition";
import type { BuiltInEndpointRegistration } from "./endpointRegistry";
import { CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
const styles = require("./styles.less");

interface KubernetesEndpointProps {
    proxies: ProxyResource[];
    doBusyTask: DoBusyTask;
    busy: Promise<void> | boolean;
    endpoint: KubernetesEndpointResource;
    accounts: AccountResource[];
    workerPools: WorkerPoolResource[];
    certificates: () => Promise<CertificateResource[]>;
    refreshAccounts: () => Promise<{}>;
    refreshCertificates: () => Promise<boolean>;
    isBuiltInWorkerEnabled: boolean;
    feeds: FeedResource[];
    imageNameError?: string;
    refreshFeeds: (callback?: (feeds: FeedResource[]) => void) => Promise<void>;
    onChange(newValue: KubernetesEndpointResource): void;
    getFieldError(field: string): string;
}

enum GkeClusterType {
    Regional,
    Zonal,
}

interface KubernetesEndpointState {
    certificate: CertificateResource | undefined;
    account: AccountResource | undefined;
    executionContainerSelected: boolean;
    gkeClusterType: GkeClusterType;
}

export class KubernetesEndpoint extends React.Component<KubernetesEndpointProps, KubernetesEndpointState> {
    constructor(props: KubernetesEndpointProps) {
        super(props);
        this.state = {
            certificate: undefined,
            account: undefined,
            executionContainerSelected: !!props.endpoint.Container?.Image && !!props.endpoint.Container?.FeedId,
            gkeClusterType: GkeClusterType.Regional,
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            await this.getCertificateResource();
            await this.getAccountResource();
        });
        if (this.getAccountType() === AccountType.GoogleCloudAccount) {
            const googleCloudAuth = this.getGoogleCloudAuth();
            if (googleCloudAuth.Region) {
                this.setState({ gkeClusterType: GkeClusterType.Regional });
            } else if (googleCloudAuth.Zone) {
                this.setState({ gkeClusterType: GkeClusterType.Zonal });
            }
        }
    }

    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="Account" title="Authentication" focusOnExpandAll summary={this.accountSummary()} help="Select the account or certificate that identifies the Kubernetes user.">
                    <Select
                        label="Select an authentication type"
                        items={[
                            { value: AccountType.UsernamePassword, text: "Username and password" },
                            { value: AccountType.Token, text: "Token" },
                            { value: AccountType.AzureServicePrincipal, text: "Azure Service Principal" },
                            { value: AccountType.AmazonWebServicesAccount, text: "AWS Account" },
                            { value: AccountType.GoogleCloudAccount, text: "Google Cloud Account" },
                            { value: KubernetesAuthenticationType.KubernetesCertificate, text: "Client Certificate" },
                            { value: KubernetesAuthenticationType.KubernetesPodServiceAccount, text: "Pod Service Account" },
                        ]}
                        onChange={(x?: string) => {
                            const endpoint = this.props.endpoint;
                            // If switching types, clear any previous selection
                            if (endpoint.AccountType !== x) {
                                endpoint.Authentication = null;
                            }
                            endpoint.AccountType = x as string;
                            this.props.onChange(endpoint);
                        }}
                        autoFocus={true}
                        sortItems={false}
                        value={this.getAccountType() || ""}
                    />
                    {this.getAccountType() === AccountType.UsernamePassword && (
                        <AccountSelect
                            onRequestRefresh={this.props.refreshAccounts}
                            value={this.getStandardAuth().AccountId}
                            type={[AccountType.UsernamePassword]}
                            allowClear={true}
                            onChange={async (x) => {
                                const endpoint = this.props.endpoint;
                                this.getStandardAuth().AccountId = x;
                                endpoint.AccountType = AccountType.UsernamePassword;
                                this.props.onChange(endpoint);
                                await this.getAccountResource();
                            }}
                            items={this.props.accounts}
                        />
                    )}
                    {this.getAccountType() === AccountType.Token && (
                        <AccountSelect
                            onRequestRefresh={this.props.refreshAccounts}
                            value={this.getStandardAuth().AccountId}
                            type={[AccountType.Token]}
                            allowClear={true}
                            onChange={async (x) => {
                                const endpoint = this.props.endpoint;
                                this.getStandardAuth().AccountId = x;
                                endpoint.AccountType = AccountType.Token;
                                this.props.onChange(endpoint);
                                await this.getAccountResource();
                            }}
                            items={this.props.accounts}
                        />
                    )}
                    {this.getAccountType() === AccountType.AzureServicePrincipal && (
                        <div>
                            <AccountSelect
                                onRequestRefresh={this.props.refreshAccounts}
                                value={this.getAzureAuth().AccountId}
                                type={[AccountType.AzureServicePrincipal]}
                                allowClear={true}
                                onChange={async (x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getAzureAuth().AccountId = x;
                                    endpoint.AccountType = AccountType.AzureServicePrincipal;
                                    this.props.onChange(endpoint);
                                    await this.getAccountResource();
                                }}
                                items={this.props.accounts}
                            />
                            <Text
                                value={this.getAzureAuth().ClusterName}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getAzureAuth().ClusterName = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("ClusterName")}
                                label="AKS cluster name"
                            />
                            <Text
                                value={this.getAzureAuth().ClusterResourceGroup}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getAzureAuth().ClusterResourceGroup = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("ClusterResourceGroup")}
                                label="AKS resource group name"
                            />
                            <StringCheckbox
                                value={this.getAzureAuth().AdminLogin}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getAzureAuth().AdminLogin = x;
                                    this.props.onChange(endpoint);
                                }}
                                label={<span>Login with administrator credentials.</span>}
                            />
                            <Note>
                                Enabling this option passes the <code>--admin</code> flag to <code>az aks get-credentials</code>. This is useful for AKS clusters with Azure Active Directory integration. See the{" "}
                                <ExternalLink href="KubeTargets">documentation</ExternalLink> for more details.
                            </Note>
                        </div>
                    )}
                    {this.getAccountType() === AccountType.AmazonWebServicesAccount && (
                        <div>
                            <RadioButtonGroup
                                value={!!this.getAwsAuth().UseInstanceRole}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getAwsAuth().UseInstanceRole = x;
                                    this.getAwsAuth().AccountId = "";
                                    this.props.onChange(endpoint);
                                }}
                                label="Execute using the AWS service role for an EC2 instance"
                            >
                                <RadioButton value={true} label="Yes" />
                                <RadioButton value={false} label="No" />
                            </RadioButtonGroup>
                            {!this.getAwsAuth().UseInstanceRole && (
                                <AccountSelect
                                    onRequestRefresh={this.props.refreshAccounts}
                                    value={this.getAwsAuth().AccountId}
                                    type={[AccountType.AmazonWebServicesAccount]}
                                    allowClear={true}
                                    onChange={async (x) => {
                                        const endpoint = this.props.endpoint;
                                        this.getAwsAuth().AccountId = x;
                                        endpoint.AccountType = AccountType.AmazonWebServicesAccount;
                                        this.props.onChange(endpoint);
                                        await this.getAccountResource();
                                    }}
                                    items={this.props.accounts}
                                />
                            )}
                            <RadioButtonGroup
                                value={!!this.getAwsAuth().AssumeRole}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getAwsAuth().AssumeRole = x;
                                    this.props.onChange(endpoint);
                                }}
                                label="Assume a different AWS service role"
                            >
                                <RadioButton value={true} label="Yes" />
                                <RadioButton value={false} label="No" />
                            </RadioButtonGroup>
                            {!!this.getAwsAuth().AssumeRole && (
                                <div>
                                    <Text
                                        label="Assumed role ARN"
                                        value={this.getAwsAuth().AssumedRoleArn || ""}
                                        onChange={(x) => {
                                            const endpoint = this.props.endpoint;
                                            this.getAwsAuth().AssumedRoleArn = x;
                                            this.props.onChange(endpoint);
                                        }}
                                        error={this.props.getFieldError("AssumedRoleArn")}
                                    />
                                    <Text
                                        label="Assumed role session name"
                                        value={this.getAwsAuth().AssumedRoleSession || ""}
                                        onChange={(x) => {
                                            const endpoint = this.props.endpoint;
                                            this.getAwsAuth().AssumedRoleSession = x;
                                            this.props.onChange(endpoint);
                                        }}
                                        error={this.props.getFieldError("AssumedRoleSession")}
                                    />
                                    <Text
                                        label="Assumed role session duration (in seconds)"
                                        type="number"
                                        min={900}
                                        max={43200}
                                        value={_.toString(this.getAwsAuth().AssumeRoleSessionDurationSeconds)}
                                        onChange={(x) => {
                                            const endpoint = this.props.endpoint;
                                            this.getAwsAuth().AssumeRoleSessionDurationSeconds = _.toNumber(x) || null;
                                            this.props.onChange(endpoint);
                                        }}
                                        error={this.props.getFieldError("AssumeRoleSessionDurationSeconds")}
                                    />
                                    <Note>If blank, defaults to 3600 seconds (1 hour).</Note>
                                    <Text
                                        label="Assumed role external ID"
                                        value={this.getAwsAuth().AssumeRoleExternalId || ""}
                                        onChange={(x) => {
                                            const endpoint = this.props.endpoint;
                                            this.getAwsAuth().AssumeRoleExternalId = x;
                                            this.props.onChange(endpoint);
                                        }}
                                        error={this.props.getFieldError("AssumeRoleExternalId")}
                                    />
                                    <Note>
                                        {" "}
                                        Learn more about <ExternalLink href="AwsDocsRolesTermsAndConcepts">Roles Terms and Concepts</ExternalLink>.
                                    </Note>
                                </div>
                            )}
                            <Text
                                value={this.getAwsAuth().ClusterName}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getAwsAuth().ClusterName = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("ClusterName")}
                                label="EKS cluster name"
                            />
                        </div>
                    )}
                    {this.getAccountType() === KubernetesAuthenticationType.KubernetesCertificate && (
                        <div>
                            <CertificateSelect
                                allowClear={true}
                                value={this.getCertificateAuth().ClientCertificate}
                                error={this.props.getFieldError("ClientCertificate")}
                                onChange={async (x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getCertificateAuth().ClientCertificate = x;
                                    endpoint.AccountType = KubernetesAuthenticationType.KubernetesCertificate;
                                    this.props.onChange(endpoint);
                                    await this.getCertificateResource();
                                }}
                                items={this.props.certificates}
                                onRequestRefresh={this.props.refreshCertificates}
                                doBusyTask={this.props.doBusyTask}
                            />
                        </div>
                    )}
                    {this.getAccountType() === KubernetesAuthenticationType.KubernetesPodServiceAccount && (
                        <div>
                            <Text
                                value={this.getPodServiceAuth().TokenPath}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getPodServiceAuth().TokenPath = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("TokenPath")}
                                label="Token file path"
                            />
                            <Note>The path to the token of the pod service account. The default value usually is: /var/run/secrets/kubernetes.io/serviceaccount/token</Note>
                        </div>
                    )}
                    {this.getAccountType() === AccountType.GoogleCloudAccount && (
                        <div>
                            <RadioButtonGroup
                                value={this.getGoogleCloudAuth().UseVmServiceAccount}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getGoogleCloudAuth().UseVmServiceAccount = x;
                                    this.getGoogleCloudAuth().AccountId = "";
                                    this.props.onChange(endpoint);
                                }}
                                label="When running in a Compute Engine virtual machine, use the associated VM service account"
                            >
                                <RadioButton value={true} label="Yes" />
                                <RadioButton value={false} label="No" />
                            </RadioButtonGroup>
                            {!this.getGoogleCloudAuth().UseVmServiceAccount && (
                                <AccountSelect
                                    onRequestRefresh={this.props.refreshAccounts}
                                    value={this.getGoogleCloudAuth().AccountId}
                                    type={[AccountType.GoogleCloudAccount]}
                                    allowClear={true}
                                    onChange={async (x) => {
                                        const endpoint = this.props.endpoint;
                                        this.getGoogleCloudAuth().AccountId = x;
                                        endpoint.AccountType = AccountType.GoogleCloudAccount;
                                        this.props.onChange(endpoint);
                                        await this.getAccountResource();
                                    }}
                                    items={this.props.accounts}
                                />
                            )}

                            <RadioButtonGroup
                                value={this.getGoogleCloudAuth().ImpersonateServiceAccount}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getGoogleCloudAuth().ImpersonateServiceAccount = x;
                                    this.props.onChange(endpoint);
                                }}
                                label="Impersonate service accounts"
                            >
                                <RadioButton value={true} label="Yes" />
                                <RadioButton value={false} label="No" />
                            </RadioButtonGroup>
                            <Note>
                                Learn more about <ExternalLink href="GCPImpersonateServiceAccount">Impersonating Service Accounts</ExternalLink>.
                            </Note>

                            {this.getGoogleCloudAuth().ImpersonateServiceAccount && (
                                <Text
                                    value={this.getGoogleCloudAuth().ServiceAccountEmails}
                                    onChange={(x) => {
                                        const endpoint = this.props.endpoint;
                                        this.getGoogleCloudAuth().ServiceAccountEmails = x;
                                        this.props.onChange(endpoint);
                                    }}
                                    error={this.props.getFieldError("KubernetesGoogleCloudAuthentication.ServiceAccountEmails")}
                                    label="Service Account Emails"
                                />
                            )}

                            <Text
                                value={this.getGoogleCloudAuth().ClusterName}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getGoogleCloudAuth().ClusterName = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("KubernetesGoogleCloudAuthentication.ClusterName")}
                                label="GKE cluster name"
                            />

                            <Text
                                value={this.getGoogleCloudAuth().Project}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    this.getGoogleCloudAuth().Project = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("KubernetesGoogleCloudAuthentication.Project")}
                                label="Project"
                            />

                            <RadioButtonGroup
                                value={this.state.gkeClusterType}
                                onChange={(x) => {
                                    this.setState({ gkeClusterType: x });
                                    const endpoint = this.props.endpoint;
                                    this.getGoogleCloudAuth().Region = "";
                                    this.getGoogleCloudAuth().Zone = "";
                                    this.props.onChange(endpoint);
                                }}
                                label="Select cluster type"
                            >
                                <RadioButton value={GkeClusterType.Regional} label="Regional" />
                                <RadioButton value={GkeClusterType.Zonal} label="Zonal" />
                            </RadioButtonGroup>
                            <Note>
                                Learn more about <ExternalLink href="GKETypesOfClusters">Types of Clusters</ExternalLink>.
                            </Note>

                            {this.state.gkeClusterType === GkeClusterType.Regional && (
                                <Text
                                    value={this.getGoogleCloudAuth().Region}
                                    onChange={(x) => {
                                        const endpoint = this.props.endpoint;
                                        this.getGoogleCloudAuth().Region = x;
                                        this.props.onChange(endpoint);
                                    }}
                                    error={this.props.getFieldError("KubernetesGoogleCloudAuthentication.Region")}
                                    label="Region"
                                />
                            )}

                            {this.state.gkeClusterType === GkeClusterType.Zonal && (
                                <Text
                                    value={this.getGoogleCloudAuth().Zone}
                                    onChange={(x) => {
                                        const endpoint = this.props.endpoint;
                                        this.getGoogleCloudAuth().Zone = x;
                                        this.props.onChange(endpoint);
                                    }}
                                    error={this.props.getFieldError("KubernetesGoogleCloudAuthentication.Zone")}
                                    label="Zone"
                                />
                            )}

                            {this.state.gkeClusterType !== undefined && (
                                <Note>
                                    View the <ExternalLink href="GCPRegionsZones">GCP Regions and Zones</ExternalLink> documentation for a current list of the available region and zone codes.
                                </Note>
                            )}
                        </div>
                    )}
                </ExpandableFormSection>
                <ExpandableFormSection errorKey={"ClusterURL"} title="Kubernetes Details" summary={this.kubernetesSummary()} help={"Enter the Kubernetes cluster details."}>
                    {this.getAccountType() !== AccountType.AzureServicePrincipal && this.getAccountType() !== AccountType.GoogleCloudAccount && (
                        <div>
                            <Text
                                value={this.props.endpoint.ClusterUrl}
                                onChange={(x) => {
                                    const endpoint = this.props.endpoint;
                                    endpoint.ClusterUrl = x;
                                    this.props.onChange(endpoint);
                                }}
                                error={this.props.getFieldError("ClusterURL")}
                                label="Kubernetes cluster URL"
                            />
                            <Note>
                                Must be an absolute URL. e.g. <em>https://kubernetes.example.com</em>
                            </Note>
                            {this.getAccountType() != KubernetesAuthenticationType.KubernetesPodServiceAccount && (
                                <div>
                                    <CertificateSelect
                                        allowClear={true}
                                        value={this.props.endpoint.ClusterCertificate}
                                        error={this.props.getFieldError("ClusterCertificate")}
                                        onChange={(x) => {
                                            const endpoint = this.props.endpoint;
                                            endpoint.ClusterCertificate = x;
                                            this.props.onChange(endpoint);
                                        }}
                                        items={this.props.certificates}
                                        onRequestRefresh={this.props.refreshCertificates}
                                        doBusyTask={this.props.doBusyTask}
                                    />
                                    <Note>The optional cluster certificate authority.</Note>
                                </div>
                            )}
                            {this.getAccountType() == KubernetesAuthenticationType.KubernetesPodServiceAccount && (
                                <div>
                                    <Text
                                        value={this.props.endpoint.ClusterCertificatePath}
                                        onChange={(x) => {
                                            const endpoint = this.props.endpoint;
                                            endpoint.ClusterCertificatePath = x;
                                            this.props.onChange(endpoint);
                                        }}
                                        error={this.props.getFieldError("ClusterCertificatePath")}
                                        label="Cluster certificate file path"
                                        placeholder="/var/run/secrets/kubernetes.io/serviceaccount/ca.crt"
                                    />
                                    <Note>The path to the CA certificate of the cluster. The default value usually is: /var/run/secrets/kubernetes.io/serviceaccount/ca.crt</Note>
                                </div>
                            )}
                            {!this.props.endpoint.ClusterCertificate && !this.props.endpoint.ClusterCertificatePath && (
                                <div>
                                    <StringCheckbox
                                        value={this.props.endpoint.SkipTlsVerification}
                                        onChange={(x) => {
                                            const endpoint = this.props.endpoint;
                                            endpoint.SkipTlsVerification = x;
                                            this.props.onChange(endpoint);
                                        }}
                                        label="Skip TLS verification"
                                    />
                                    <Note>Enable this option to skip the verification of the cluster certificate. This can only be selected if no cluster certificate is specified.</Note>
                                </div>
                            )}
                        </div>
                    )}
                    <Text
                        value={this.props.endpoint.Namespace}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.Namespace = x;
                            this.props.onChange(endpoint);
                        }}
                        placeholder="default"
                        error={this.props.getFieldError("Namespace")}
                        label="Kubernetes namespace"
                    />
                </ExpandableFormSection>
                {this.props.workerPools.length > 1 && (
                    <ExpandableFormSection
                        errorKey={"DefaultWorkerPool"}
                        title="Worker Pool"
                        summary={this.props.endpoint.DefaultWorkerPoolId ? CommonSummaryHelper.resourceSummary(this.props.endpoint.DefaultWorkerPoolId, this.props.workerPools, "worker pool") : Summary.placeholder("No pool selected - default pool")}
                        help="Select the optional worker pool."
                    >
                        <Select
                            label={"Select a default pool"}
                            items={this.props.workerPools.map((e) => ({ value: e.Id, text: e.Name }))}
                            value={this.props.endpoint.DefaultWorkerPoolId}
                            allowFilter={true}
                            allowClear={true}
                            onChange={(x) => {
                                this.props.endpoint.DefaultWorkerPoolId = x;
                                this.props.onChange(this.props.endpoint);
                            }}
                            sortItems={false}
                        />
                    </ExpandableFormSection>
                )}
                {this.renderContainerImageSelector()}
            </div>
        );
    }

    private renderContainerImageSelector() {
        const runOn = this.whereToRun();

        if (!isRunOnServerOrWorkerPool(runOn)) {
            return null;
        }

        const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, wildcard: true });

        return (
            <ExpandableFormSection
                errorKey="Octopus.Action.Container.Image|Octopus.Action.Container.FeedId"
                title="Health Check Container Image"
                summary={CommonSummaryHelper.actionContainerSummary(runOn.container, this.props.feeds || [], runOn, !feedViewPermissionGranted)}
                help={`Choose to run directly on ${isRunOnBuiltInWorker(runOn) ? "the Octopus Server" : "a worker"} or inside a container`}
            >
                {this.state.executionContainerSelected && <Note>This image is only used when performing health checks. It is not used when performing deployments.</Note>}
                <ExecutionContainerImageSelector
                    workerPools={this.props.workerPools}
                    runOn={runOn}
                    feeds={this.props.feeds}
                    workerPoolNameOrId={this.props.endpoint.DefaultWorkerPoolId}
                    refreshFeeds={this.props.refreshFeeds}
                    onFeedChange={(feedId) => this.onFeedIdChanged(feedId!, runOn)}
                    onImageNameChange={(imageName) => this.onImageNameChanged(imageName, runOn)}
                    resetContainer={(updatedRunOn) => this.onContainerReset(updatedRunOn)}
                    onStepExecutionOptionChange={(option) => {
                        if (option === StepExecutionOption.RunInsideContainerOnWorker) {
                            this.onRunOnChanged({ ...runOn, runningInContainer: true });
                        } else {
                            this.onRunOnChanged({ ...runOn, runningInContainer: false, container: generateDefaultActionContainer() });
                        }
                    }}
                    imageNameError={this.props.imageNameError}
                />
            </ExpandableFormSection>
        );
    }
    private onFeedIdChanged = (feedId: string, runOn: RunOnServerOrWorkerPool) => {
        const updatedContainer = { ...runOn.container, FeedId: feedId };
        this.onRunOnChanged({ runningInContainer: runOn.runningInContainer, executionLocation: runOn.executionLocation, container: updatedContainer });
    };

    private onImageNameChanged = (imageName: string, runOn: RunOnServerOrWorkerPool) => {
        const updatedContainer = { ...runOn.container, Image: imageName };
        this.onRunOnChanged({ runningInContainer: runOn.runningInContainer, executionLocation: runOn.executionLocation, container: updatedContainer });
    };

    private onContainerReset = (runOn: RunOnServerOrWorkerPool) => {
        this.onRunOnChanged({ ...runOn });
    };

    private onRunOnChanged = (runOn: RunOnBuiltInWorker | RunOnWorkerPool) => {
        if (runOn.runningInContainer) {
            this.setState({ executionContainerSelected: runOn.runningInContainer });
            if (this.props.endpoint.Container?.FeedId !== runOn.container.FeedId || this.props.endpoint.Container?.Image !== runOn.container.Image) {
                this.props.endpoint.Container = { ...runOn.container };
                this.props.onChange(this.props.endpoint);
            }
        } else {
            if (this.props.endpoint.Container !== null) {
                this.props.endpoint.Container = null;
                this.props.onChange(this.props.endpoint);
            }
        }
    };

    private getAccountType(): string {
        // First use any value saved in the AccountType field
        if (this.props.endpoint.AccountType) {
            return this.props.endpoint.AccountType;
        }

        // Inspect the account resource
        if (this.state.account) {
            return this.state.account.AccountType;
        }

        // Inspect the certificate resource
        if (this.state.certificate) {
            return KubernetesAuthenticationType.KubernetesCertificate;
        }

        // Using an instance role means we have an AWS account
        if ((this.props.endpoint.Authentication as KubernetesAwsAuthentication)?.UseInstanceRole) {
            return AccountType.AmazonWebServicesAccount;
        }

        // Using a VM account means we have an GoogleCloud account
        if ((this.props.endpoint.Authentication as KubernetesGoogleCloudAuthentication)?.UseVmServiceAccount) {
            return AccountType.GoogleCloudAccount;
        }

        if ((this.props.endpoint.Authentication as KubernetesPodServiceAccountAuthentication)?.TokenPath) {
            return KubernetesAuthenticationType.KubernetesPodServiceAccount;
        }

        return null!;
    }

    private accountSummary() {
        if (this.props.endpoint.Authentication) {
            if (this.props.endpoint.Authentication.AuthenticationType === KubernetesAuthenticationType.KubernetesCertificate) {
                if (this.state.certificate) {
                    return Summary.summary(this.state.certificate.Name);
                }
                return Summary.summary(<span>Using certificate</span>);
            }

            if (this.props.endpoint.Authentication.AuthenticationType === KubernetesAuthenticationType.KubernetesPodServiceAccount) {
                return Summary.summary(<span>Using pod service account</span>);
            }

            const stdAccount = this.props.endpoint.Authentication as KubernetesStandardAccountAuthentication;
            if (stdAccount.AccountId) {
                return CommonSummaryHelper.resourceSummary(stdAccount.AccountId, this.props.accounts, "account");
            }

            const awsAccount = this.props.endpoint.Authentication as KubernetesAwsAuthentication;
            if (awsAccount?.UseInstanceRole) {
                return Summary.summary(<span>Using AWS instance role</span>);
            }

            const googleCloudAccount = this.props.endpoint.Authentication as KubernetesGoogleCloudAuthentication;
            if (googleCloudAccount?.UseVmServiceAccount) {
                return Summary.summary(<span>Using Google Cloud service account in the Virtual Machine</span>);
            }
        }

        return Summary.placeholder("No account or certificate selected");
    }

    private kubernetesSummary() {
        if (this.props.endpoint.ClusterUrl) {
            const tlsNotes = this.props.endpoint.ClusterCertificate ? " with a custom certificate" : this.props.endpoint.SkipTlsVerification === "True" ? " without TLS verification" : " with TLS verification";

            const namespaceNotes = this.props.endpoint.Namespace ? (
                <span>
                    {" "}
                    and namespace <strong>{this.props.endpoint.Namespace}</strong>
                </span>
            ) : (
                <span />
            );

            return Summary.summary(
                <span>
                    Connecting to cluster at <strong>{this.props.endpoint.ClusterUrl}</strong>
                    {namespaceNotes}
                    {tlsNotes}
                </span>
            );
        }

        if (this.props.endpoint.Namespace) {
            return Summary.summary(
                <span>
                    Namespace is <strong>{this.props.endpoint.Namespace}</strong>
                </span>
            );
        }

        return Summary.placeholder("No cluster URL or namespace");
    }

    private async getCertificateResource() {
        if (this.props.endpoint.Authentication && this.props.endpoint.Authentication.AuthenticationType === KubernetesAuthenticationType.KubernetesCertificate) {
            const auth = this.props.endpoint.Authentication as KubernetesCertificateAuthentication;
            if (auth.ClientCertificate) {
                const certificate = await repository.Certificates.get(auth.ClientCertificate);
                this.setState({
                    certificate,
                    // Clear the account if we have an account selected
                    account: !certificate ? this.state.account : undefined,
                });
            }
        }
    }

    private async getAccountResource() {
        if (this.props.endpoint.Authentication && this.props.endpoint.Authentication.AuthenticationType !== KubernetesAuthenticationType.KubernetesCertificate) {
            const auth = this.props.endpoint.Authentication as KubernetesStandardAccountAuthentication;
            if (auth.AccountId) {
                const account = await repository.Accounts.get((this.props.endpoint.Authentication as KubernetesStandardAccountAuthentication).AccountId);
                this.setState({
                    account,
                    // Clear the certificate if we have an account selected
                    certificate: !account ? this.state.certificate : undefined,
                });
            }
        }
    }

    /**
     * Return the Aws authentication object, creating a new one if necessary
     */
    private getAwsAuth(): KubernetesAwsAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesAuthenticationType.KubernetesAws) {
            this.props.endpoint.Authentication = new KubernetesAwsAuthentication();
        }

        return this.props.endpoint.Authentication as KubernetesAwsAuthentication;
    }

    /**
     * Return the Azure authentication object, creating a new one if necessary
     */
    private getAzureAuth(): KubernetesAzureAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesAuthenticationType.KubernetesAzure) {
            this.props.endpoint.Authentication = new KubernetesAzureAuthentication();
            // Azure supplies these values for us
            this.props.endpoint.ClusterUrl = "";
            this.props.endpoint.ClusterCertificate = "";
            this.props.endpoint.SkipTlsVerification = "False";
        }

        return this.props.endpoint.Authentication as KubernetesAzureAuthentication;
    }

    /**
     * Return the standard authentication object, creating a new one if necessary
     */
    private getStandardAuth(): KubernetesStandardAccountAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesAuthenticationType.KubernetesStandard) {
            this.props.endpoint.Authentication = new KubernetesStandardAccountAuthentication();
        }

        return this.props.endpoint.Authentication as KubernetesStandardAccountAuthentication;
    }

    /**
     * Return the Certificate authentication object, creating a new one if necessary
     */
    private getCertificateAuth(): KubernetesCertificateAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesAuthenticationType.KubernetesCertificate) {
            this.props.endpoint.Authentication = new KubernetesCertificateAuthentication();
        }

        return this.props.endpoint.Authentication as KubernetesCertificateAuthentication;
    }

    /**
     * Return the Pod Service Token, creating a new one if necessary
     */
    private getPodServiceAuth(): KubernetesPodServiceAccountAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesAuthenticationType.KubernetesPodServiceAccount) {
            this.props.endpoint.Authentication = new KubernetesPodServiceAccountAuthentication();
        }

        return this.props.endpoint.Authentication as KubernetesPodServiceAccountAuthentication;
    }

    /**
     * Return the Google cloud authentication object, creating a new one if necessary
     */
    private getGoogleCloudAuth(): KubernetesGoogleCloudAuthentication {
        if (!this.props.endpoint.Authentication || this.props.endpoint.Authentication.AuthenticationType !== KubernetesAuthenticationType.KubernetesGoogleCloud) {
            this.props.endpoint.Authentication = new KubernetesGoogleCloudAuthentication();
        }

        return this.props.endpoint.Authentication as KubernetesGoogleCloudAuthentication;
    }

    private whereToRun(): RunOn {
        if (this.props.workerPools.length > 0) {
            const runOn = new RunOnWorkerPool();
            runOn.container = this.props.endpoint.Container || generateDefaultActionContainer();
            runOn.executionLocation = ExecutionLocation.WorkerPool;
            runOn.runningInContainer = this.state.executionContainerSelected;
            return runOn;
        } else if (this.props.isBuiltInWorkerEnabled) {
            const runOn = new RunOnBuiltInWorker();
            runOn.container = this.props.endpoint.Container || generateDefaultActionContainer();
            runOn.executionLocation = ExecutionLocation.OctopusServer;
            runOn.runningInContainer = this.state.executionContainerSelected;
            return runOn;
        }

        return new RunOnDeploymentTarget();
    }
}

export default KubernetesEndpoint;

const kubernetesEndpointRegistration: BuiltInEndpointRegistration = {
    key: EndpointRegistrationKey.Kubernetes,
    displayOrder: 20,
    communicationStyle: CommunicationStyle.Kubernetes,
    name: "Kubernetes Cluster",
    categories: [kubernetesCategory],
    renderCard: ({ registration, category, onNavigate }) => (
        <EndpointCard
            logo={<KubernetesCluster className={styles.centreThumbnail} title={registration.name} />}
            registrationName={registration.name}
            description="Connect to an existing Kubernetes Cluster (K8S account and namespace boundary)."
            onNavigate={onNavigate}
        />
    ),
};

export { kubernetesEndpointRegistration };
