import cn from "classnames";
import * as React from "react";
const styles = require("./CustomDialogTitleBar.less");

interface CustomDialogTitleProps {
    title: string;
    actions?: React.ReactNode;
    className?: string;
}

export const CustomDialogTitleBar: React.SFC<CustomDialogTitleProps> = ({ title, actions, className }) => {
    return (
        <div className={cn(styles.titleBar, className)}>
            <div className={styles.title}>{title}</div>
            <div className={styles.actions}>{actions}</div>
        </div>
    );
};

export default CustomDialogTitleBar;
