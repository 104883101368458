import * as React from "react";
import type { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { alwaysHideOnboardingQuestionnaireKey } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/AlwaysHideOnboardingQuestionnaire";
import { OnboardingQuestionnaireDialog } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/OnboardingQuestionnaireDialog";
import { HostingEnvironment } from "~/client/resources";
import type { ProjectGroupResource, LicenseStatusResource } from "~/client/resources";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import BusyIndicator from "~/components/BusyIndicator/BusyIndicator";
import { DisabledChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import NavigationSidebarLayout from "~/components/NavigationSidebarLayout/index";
import Environment from "~/environment";
import routeLinks from "~/routeLinks";
import type { ProjectContextState } from "../../context";
import { ProjectContextProvider } from "../../context";
import type { BranchSpecifier } from "../ProjectsRoutes/BranchSpecifier";
import { UseDefaultBranch } from "../ProjectsRoutes/BranchSpecifier";
import { CreateReleaseButton } from "../Releases";
import { ProjectLinks } from "./ProjectLinks";

interface ProjectLayoutProps {
    projectSlug: string;
    branchName: string | undefined;
    ignoreBranchNotFound?: boolean;
    isNewlyCreatedProject?: string | null;
}

export function ProjectLayout({ projectSlug, isNewlyCreatedProject, ignoreBranchNotFound, branchName, children }: PropsWithChildren<ProjectLayoutProps>) {
    const isMultiTenancyEnabled = useSelector((state: GlobalState) => state.configurationArea.currentSpace.isMultiTenancyEnabled);
    return (
        <ErrorContextProvider>
            <ProjectLayoutInternal projectSlug={projectSlug} branchName={branchName} isNewlyCreatedProject={isNewlyCreatedProject} ignoreBranchNotFound={ignoreBranchNotFound} isMultiTenancyEnabled={isMultiTenancyEnabled}>
                {children}
            </ProjectLayoutInternal>
        </ErrorContextProvider>
    );
}

type ProjectLayoutInternalProps = PropsWithChildren<{
    projectSlug: string;
    branchName: string | undefined;
    ignoreBranchNotFound: boolean | undefined;
    isNewlyCreatedProject: string | null | undefined;
    isMultiTenancyEnabled: boolean;
}>;

interface ProjectLayoutState extends DataBaseComponentState {
    projectGroups: ProjectGroupResource[] | null;
    licenseStatus: LicenseStatusResource | null;
}

class ProjectLayoutInternal extends DataBaseComponent<ProjectLayoutInternalProps, ProjectLayoutState> {
    constructor(props: ProjectLayoutInternalProps) {
        super(props);
        this.state = {
            projectGroups: null,
            licenseStatus: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projectGroups = repository.ProjectGroups.all();
            const licenseStatus = repository.Licenses.getCurrentStatus();

            this.setState({ projectGroups: await projectGroups, licenseStatus: await licenseStatus });
        });
    }

    toBranchSpecifier(branch: string | undefined): BranchSpecifier {
        return branch ? branch : UseDefaultBranch;
    }

    render() {
        const projectSlug = this.props.projectSlug;
        const branchName = this.props.branchName;
        const branchSpecifier = this.toBranchSpecifier(branchName);
        const isMultiTenancyEnabled: boolean | undefined = this.props.isMultiTenancyEnabled;
        return (
            <ProjectContextProvider doBusyTask={this.doBusyTask} projectIdOrSlug={projectSlug} branch={branchSpecifier}>
                {({ state }) => {
                    const projectLogo = state.model && state.model && state.model.Links.Logo;
                    const showBusyIndicator = !(state.model || (this.state && this.errors));
                    return state.model ? (
                        <main id="maincontent">
                            {this.shouldShowOnboardingQuestionnaire() ? <OnboardingQuestionnaireDialog /> : null}
                            {this.areaTitle(state, false)}
                            {this.renderErrors()}
                            <NavigationSidebarLayout
                                logoUrl={projectLogo}
                                name={state.model.Name}
                                description={state.model.Description}
                                preNavbarComponent={
                                    <div>
                                        {state.model.IsDisabled && (
                                            <div>
                                                <DisabledChip />
                                            </div>
                                        )}
                                        {(state.summary?.HasDeploymentProcess || !state.summary?.HasRunbooks) && <CreateReleaseButton projectId={state.model.Id} projectSlug={state.model.Slug} />}
                                    </div>
                                }
                                navLinks={ProjectLinks(projectSlug, branchSpecifier, state.model.Id, state.summary, isMultiTenancyEnabled)}
                                content={
                                    state.model.IsVersionControlled && state.vcsHasError && !this.props.ignoreBranchNotFound ? (
                                        <div style={{ height: "100px", width: "100%" }}>
                                            <ErrorPanel message="There was an error accessing version control."></ErrorPanel>
                                        </div>
                                    ) : (
                                        this.props.children
                                    )
                                }
                            />
                        </main>
                    ) : (
                        <main id="maincontent">
                            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(showBusyIndicator)} />
                            {this.renderErrors()}
                            <NavigationSidebarLayout logoUrl={projectLogo} navLinks={[]} content={<></>} />
                        </main>
                    );
                }}
            </ProjectContextProvider>
        );
    }

    renderErrors() {
        const errors = this.state && this.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} />;
    }

    private renderBusy(forceBusy: boolean) {
        return <BusyFromPromise promise={this.state.busy || forceBusy}>{(busy: boolean) => <BusyIndicator show={busy || forceBusy} />}</BusyFromPromise>;
    }

    private areaTitle(state: ProjectContextState, forceBusy: boolean) {
        const hasAccessibleProjectGroup = this.state.projectGroups && state.model.ProjectGroupId && this.state.projectGroups.find((pg) => pg.Id === state.model.ProjectGroupId);
        return !hasAccessibleProjectGroup ? (
            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(forceBusy)} />
        ) : (
            <AreaTitle breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root} link={routeLinks.projects.filteredByGroup(state.model.ProjectGroupId)} title={hasAccessibleProjectGroup.Name} busyIndicator={this.renderBusy(forceBusy)} />
        );
    }

    private shouldShowOnboardingQuestionnaire() {
        const canShowQuestionnaire = Environment.isInDevelopmentMode() || (this.state.licenseStatus !== null && this.state.licenseStatus.HostingEnvironment !== HostingEnvironment.SelfHosted);
        if (!canShowQuestionnaire) {
            return false;
        }
        const alwaysHideQuestionnaire = localStorage.getItem(alwaysHideOnboardingQuestionnaireKey);
        return alwaysHideQuestionnaire !== "true" && this.props.isNewlyCreatedProject === "true";
    }
}
