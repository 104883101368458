import React from "react";
import type { IconSize } from "./IconSize";
import { getIconSize } from "./getIconSize";
import styles from "./styles.less";

export interface UnavailableMachineIconProps {
    iconSize?: IconSize;
    title?: string;
}

export function UnavailableMachineIcon({ title = "Unavailable", iconSize }: UnavailableMachineIconProps) {
    return (
        <svg {...getIconSize(iconSize)} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={styles.healthStatusIcon}>
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <circle fill="#DB4437" cx="8" cy="8" r="8" />
                <path
                    d="M12.240622 3.58747c0-.126157-.09669-.228427-.21596-.228427H4.802677l7.437945 6.58802V3.58747zm-3.739554 7.18861h2.69302l.896546.793832L12.5 11.05282 3.409366 3 3 3.517093l.895418.793235v6.237922c0 .126157.09669.228428.21596.228428h3.684295v.938163c-.246475.089186-.440077.293965-.524395.55467H4.797602c-.15057 0-.27263.129105-.27263.288366 0 .15926.12206.288368.27263.288368H7.27297c.116063.36099.43654.603756.797023.603756.360484 0 .68096-.242766.797024-.603756h2.47142c.15057 0 .27263-.129107.27263-.288368 0-.15926-.12206-.288367-.27263-.288367H8.86307c-.084762-.260412-.278197-.465014-.524395-.55467v-.93876h.162393z"
                    fillRule="nonzero"
                    fill="#FFF"
                />
            </g>
        </svg>
    );
}
