import * as React from "react";
import { ProcessType, StartTrigger } from "~/client/resources";
import SpecialVariables from "~/client/specialVariables";
import ActionButton, { ActionButtonType } from "~/components/Button";
import FilterSearchBox from "~/components/FilterSearchBox";
import { StepRolling } from "~/components/Images/Process/StepRolling";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout";
import Section from "~/components/Section";
import WarningPanel from "~/components/WarningPanel/WarningPanel";
import { ErrorPanel, Note } from "~/components/form";
import Divider from "~/primitiveComponents/dataDisplay/Divider";
import { useProjectContext } from "../../context";
import getActionLogoUrl from "../getActionLogoUrl";
import { processScopedEditPermission } from "./Common/CommonProcessHelpers";
import { useActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import { useProcessContext } from "./Contexts/ProcessContext";
import { ProcessErrorSelectors, useProcessErrorSelectors } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessQueryStringContext } from "./Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessSearchFilterContext } from "./Contexts/ProcessSearchFilter/ProcessSearchFilterContext";
import { ProcessWarningSelectors, useProcessWarningSelectors } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { ActionContextMenuTarget, NoContextMenuTarget, ParentStepContextMenuTarget, ProcessListItemContextMenu } from "./ListItems/ProcessListItemContextMenu";
import type { ContextMenuTarget } from "./ListItems/ProcessListItemContextMenu";
import ProcessListItemForSidebar from "./ListItems/ProcessListItemForSidebar";
import StepSorter from "./ListItems/ProcessListItemSorter";
import type { StoredAction } from "./types";
const deploymentPartStyles = require("./ListItems/ProcessListItem.less");
const deploymentPartForSidebarStyles = require("./ListItems/ProcessListItemForSidebar.less");
const styles = require("./ProcessSidebarLayout.less");

interface ProcessSidebarLayoutProps {
    render(): React.ReactNode;
}

const DeploymentProcessLayoutOverflowMenu: React.FC = () => {
    const context = useProcessContext();
    const projectContext = useProjectContext();

    const processType = context.selectors.getProcessType();

    const overFlowActions = React.useMemo(
        () => [
            OverflowMenuItems.dialogItem(
                "Reorder Steps",
                <StepSorter
                    title="Reorder Steps"
                    initialItems={context.selectors.getAllSteps()}
                    onComplete={(process) => {
                        context.actions.reorderSteps(process.map((x) => x.Id));
                        context.actions.resetPackageRequirementAfterPackageAcquisitionStep();
                    }}
                />,
                {
                    permission: processScopedEditPermission(processType),
                    project: projectContext.state.model.Id,
                    wildcard: true,
                }
            ),
        ],
        //TODO: Create a smart component for step sorter in order to avoid changing menu items unncessarily as
        //we don't really need to know about the steps and actions until the dialog is actually shown. These menu items
        //will re-render if our state changes, which is unwanted.
        [context.actions, context.selectors, processType, projectContext.state.model.Id]
    );

    return React.useMemo(() => <OverflowMenu key="reorder" menuItems={overFlowActions} />, [overFlowActions]);
};

interface ActionSidebarItemProps {
    actionId: string;
    isCurrent: boolean;
    onShowContextMenu: (event: React.MouseEvent<Element, MouseEvent>, actionId: string) => void;
}

const ActionSidebarItem: React.FC<ActionSidebarItemProps> = (props) => {
    const { onShowContextMenu } = props;

    const { selectors } = useProcessContext();
    const actionTemplates = useActionTemplatesFromContext();
    const action = selectors.getActionById(props.actionId);
    const step = selectors.getStepById(action.ParentId);

    const isChildAction = selectors.isChildAction(action.Id);
    const stepNumber = selectors.getStepNumber(step.Id);
    const actionNumber = selectors.getActionNumber(action.Id);
    let actionTypeName = action.ActionType;
    const actionTemplate = actionTemplates.find((x) => x.Type === action.ActionType);
    if (actionTemplate) {
        actionTypeName = actionTemplate.Name;
    }

    const { actions: queryStringActions } = useProcessQueryStringContext();

    const onClick = React.useCallback(() => {
        queryStringActions.showProcessAction(action.Id);
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        //The current query string actions are tied to the same context as selectors. In order to avoid onClick breaking
        //memoization, we should move the actions into its own separate context.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action.Id]);

    const icon = React.useMemo(() => <StepRolling height="1.6rem" className={deploymentPartStyles.stepIcon} />, []);
    const processErrorSelectors = useProcessErrorSelectors();
    const processWarningSelectors = useProcessWarningSelectors();

    //TODO: revisit, we should remove the selectors from `getActionErrors` and consider passing in the result of the associated selectors instead to avoid
    //having to get a new set of errors every time process state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const errors = React.useMemo(() => processErrorSelectors.getActionErrors(action.Id, selectors), [action.Id, processErrorSelectors]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const warnings = React.useMemo(() => processWarningSelectors.getActionWarnings(action.Id, selectors), [action.Id, processWarningSelectors]);

    const handleShowContextMenu = React.useCallback(
        (event: React.MouseEvent<Element, MouseEvent>) => {
            onShowContextMenu(event, action.Id);
        },
        [action.Id, onShowContextMenu]
    );

    return (
        <ProcessListItemForSidebar
            actionType={actionTypeName}
            logoUrl={getActionLogoUrl(action)}
            icon={icon}
            isCurrentAction={props.isCurrent}
            isParentGroup={false}
            actionErrors={errors}
            actionWarnings={warnings}
            index={`${isChildAction ? `${stepNumber}.${actionNumber}.` : `${stepNumber}.`}`}
            isDisabled={selectors.isActionDisabled(action.Id)}
            notes={action.Notes}
            name={action.Name}
            isRunInParallelWithLast={step.StartTrigger === StartTrigger.StartWithPrevious && !selectors.isChildAction(action.Id) && !selectors.isFirstStep(step.Id)}
            onShowContextMenu={handleShowContextMenu}
            onClick={onClick}
        />
    );
};

interface ParentStepSidebarItemProps {
    stepId: string;
    isCurrent: boolean;
    onShowContextMenu: (event: React.MouseEvent<Element, MouseEvent>, stepId: string) => void;
    renderChildAction: (action: StoredAction) => React.ReactNode;
}

const ParentStepSidebarItem: React.FC<ParentStepSidebarItemProps> = ({ stepId, isCurrent, onShowContextMenu, renderChildAction }) => {
    const { selectors } = useProcessContext();
    const step = selectors.getStepById(stepId);
    const maxParallelism = step.Properties[SpecialVariables.Action.MaxParallelism];
    const showWindowSize = maxParallelism ? maxParallelism.toString().length > 0 : false;
    const { actions: queryStringActions } = useProcessQueryStringContext();

    const onClick = React.useCallback(() => {
        queryStringActions.showProcessParentStep(step.Id);
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        //The current query string actions are tied to the same context as selectors. In order to avoid onClick breaking
        //memoization, we should move the actions into its own separate context.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step.Id]);

    const parentStepLabel = React.useMemo(
        () =>
            showWindowSize ? (
                <span>Rolling deployment</span>
            ) : (
                <span>
                    Multi-step deployment across
                    <br />
                    deployment targets
                </span>
            ),
        [showWindowSize]
    );
    const icon = React.useMemo(() => <StepRolling height="1.6rem" className={deploymentPartStyles.stepIcon} />, []);
    const processErrorSelectors = useProcessErrorSelectors();
    const errors = React.useMemo(() => processErrorSelectors.getStepErrors(step.Id), [processErrorSelectors, step.Id]);

    const processWarningSelectors = useProcessWarningSelectors();
    const warnings = React.useMemo(() => processWarningSelectors.getStepWarnings(step.Id), [processWarningSelectors, step.Id]);

    const handleShowContextMenu = React.useCallback(
        (event: React.MouseEvent<Element, MouseEvent>) => {
            onShowContextMenu(event, step.Id);
        },
        [onShowContextMenu, step.Id]
    );

    return (
        <div key={step.Id} className={deploymentPartForSidebarStyles.group}>
            <ProcessListItemForSidebar
                actionType={parentStepLabel}
                icon={icon}
                isCurrentAction={isCurrent}
                isParentGroup={true}
                actionErrors={errors}
                actionWarnings={warnings}
                index={`${selectors.getStepNumber(step.Id)}.`}
                isDisabled={selectors.isStepDisabled(step.Id)}
                name={step.Name}
                isRunInParallelWithLast={step.StartTrigger === StartTrigger.StartWithPrevious && !selectors.isFirstStep(step.Id)}
                onShowContextMenu={handleShowContextMenu}
                onClick={onClick}
                notes={null}
            />
            {selectors.getChildActions(step.Id).map((action) => {
                return renderChildAction(action);
            })}
        </div>
    );
};

const ProcessSidebarLayout: React.FC<ProcessSidebarLayoutProps> = (props) => {
    const { selectors } = useProcessContext();
    const actionTemplates = useActionTemplatesFromContext();
    const {
        filteredSteps,
        actions: searchFilterActions,
        state: { searchFilter },
    } = useProcessSearchFilterContext();
    const {
        state: { queryFilter },
    } = useProcessQueryStringContext();
    const {
        state: { model: project },
    } = useProjectContext();
    const showExtendedFilterDetails = searchFilter.channel || searchFilter.environment || !searchFilter.includeUnscoped;
    const [contextMenuState, setContextMenuState] = React.useState<{ open: boolean; target: ContextMenuTarget }>({
        open: false,
        target: NoContextMenuTarget,
    });
    const processType = selectors.getProcessType();

    const handleCloseMenu = React.useCallback(
        () =>
            setContextMenuState({
                open: false,
                target: NoContextMenuTarget,
            }),
        []
    );

    const showParentContextMenu = React.useCallback(
        (event: React.MouseEvent, stepId: string) => {
            event.stopPropagation();
            setContextMenuState({
                open: true,
                target: ParentStepContextMenuTarget.create(event.currentTarget, stepId),
            });
        },
        [setContextMenuState]
    );

    const showActionContextMenu = React.useCallback(
        (event: React.MouseEvent, actionId: string) => {
            event.stopPropagation();
            setContextMenuState({
                open: true,
                target: ActionContextMenuTarget.create(event.currentTarget, actionId),
            });
        },
        [setContextMenuState]
    );

    const renderChildAction = React.useCallback(
        (action: StoredAction) => {
            return <ActionSidebarItem key={action.Id} actionId={action.Id} isCurrent={queryFilter.actionId === action.Id} onShowContextMenu={showActionContextMenu} />;
        },
        [queryFilter, showActionContextMenu]
    );

    const sectionSearchFilter = React.useMemo(
        () => (
            <Section bodyClassName={styles.headerBody}>
                <div className={styles.headerBodyFilter}>
                    <FilterSearchBox placeholder="Filter by name..." value={searchFilter.filterKeyword} onChange={(val) => searchFilterActions.onFilterChange((prev) => ({ ...prev, filterKeyword: val }))} fullWidth={true} autoFocus={true} />
                    <DeploymentProcessLayoutOverflowMenu />
                </div>
                {showExtendedFilterDetails && (
                    <div className={styles.regardingAnyContainer}>
                        <Note style={{ fontSize: "0.875rem" }}>
                            {!!searchFilter.environment && (
                                <div>
                                    Environment: <strong>{searchFilter.environment.Name}</strong>
                                </div>
                            )}
                            {!!searchFilter.channel && (
                                <div>
                                    Channel: <strong>{searchFilter.channel.Name}</strong>
                                </div>
                            )}
                            <div>{searchFilter.includeUnscoped ? "Including unscoped steps" : "Excluding unscoped steps"}</div>
                        </Note>
                        <div>
                            <ActionButton type={ActionButtonType.Ternary} onClick={() => searchFilterActions.onClearFilter()} label="Clear" />
                        </div>
                    </div>
                )}
            </Section>
        ),
        [searchFilter.channel, searchFilter.environment, searchFilter.filterKeyword, searchFilter.includeUnscoped, searchFilterActions, showExtendedFilterDetails]
    );

    if (!selectors.hasValidProcess() || !actionTemplates) {
        return <PaperLayout busy={true} />;
    }

    return (
        <>
            <ProcessListItemContextMenu open={contextMenuState.open} target={contextMenuState.target} onRequestClose={handleCloseMenu} project={project} />
            <ProcessErrorSelectors>
                {(errorSelectors) => {
                    const errorMessage = errorSelectors.getGlobalErrorMessage();
                    return (
                        <ProcessWarningSelectors>
                            {(warningSelectors) => {
                                const warningMessage = warningSelectors.getGlobalWarningMessage();
                                return (
                                    <React.Fragment>
                                        {errorMessage && (
                                            <ErrorPanel message={errorMessage} errors={errorSelectors.getGlobalErrors()} parsedHelpLinks={undefined} helpText={undefined} helpLink={undefined} statusCode={undefined} scrollToPanel={true} />
                                        )}
                                        {warningMessage && <WarningPanel message={warningMessage} warnings={warningSelectors.getGlobalWarnings()} parsedHelpLinks={undefined} helpText={undefined} helpLink={undefined} scrollToPanel={false} />}
                                    </React.Fragment>
                                );
                            }}
                        </ProcessWarningSelectors>
                    );
                }}
            </ProcessErrorSelectors>
            {processType === ProcessType.Deployment && <Divider fullHeight={true} />}
            <div className={styles.container}>
                {selectors.hasSteps() && (
                    <div className={styles.sidebar}>
                        <>
                            {sectionSearchFilter}
                            <div className={deploymentPartForSidebarStyles.stepList}>
                                {filteredSteps.steps.length > 0 ? (
                                    filteredSteps.steps
                                        .filter((x) => x.filtered)
                                        .map(({ step: filteredStep }) => {
                                            const step = selectors.getStepById(filteredStep.Id);
                                            if (step.ActionIds.length === 1) {
                                                const action = selectors.getActionById(step.ActionIds[0]);
                                                return <ActionSidebarItem key={action.Id} actionId={action.Id} isCurrent={queryFilter.actionId === action.Id} onShowContextMenu={showActionContextMenu} />;
                                            }
                                            return <ParentStepSidebarItem key={step.Id} stepId={step.Id} isCurrent={queryFilter.parentStepId === step.Id} onShowContextMenu={showParentContextMenu} renderChildAction={renderChildAction} />;
                                        })
                                ) : (
                                    <NoResults />
                                )}
                            </div>
                        </>
                    </div>
                )}
                <div className={styles.content}>{props.render()}</div>
            </div>
        </>
    );
};

export default ProcessSidebarLayout;
