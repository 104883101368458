import type { AnalyticFields, AnalyticSession } from "./AnalyticSession";
import { AnalyticsEnvironment } from "./AnalyticSession";

export class UndefinedSession implements AnalyticSession {
    track(name: string, event: AnalyticFields): void {
        throw new Error("Analytic Session not started.");
    }
    end(): void {
        throw new Error("Analytic Session not started.");
    }

    public readonly environment: AnalyticsEnvironment = AnalyticsEnvironment.Undefined;
}
