import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { getURISafeBranchName, ProcessType } from "~/client/resources";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";
import type { WithOptionalRunbookContextInjectedProps } from "../Runbooks/RunbookContext";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";

interface GlobalConnectedProps {
    isConfigurationAsCodeForRunbooksEnabled?: boolean;
}

type BranchAwareRedirectProps = { children: React.ReactNode } & WithProjectContextInjectedProps & WithOptionalRunbookContextInjectedProps & GlobalConnectedProps;

const BranchAwareRedirectInternal: React.FC<BranchAwareRedirectProps> = (props) => {
    const location = useLocation();
    const { projectSlug, branchName } = useParams<ProjectRouteParams>();

    if (!projectSlug) {
        throw new Error("Cannot use a BranchAwareRedirect where a projectslug route parameter does not exist");
    }

    let redirectTo = undefined;
    if (!branchName) {
        const isVersionControlled = isVersionControlledProcess(props.projectContext.state.model.IsVersionControlled, props.runbookContext ? ProcessType.Runbook : ProcessType.Deployment, !!props.isConfigurationAsCodeForRunbooksEnabled);
        if (isVersionControlled) {
            const branch = props.projectContext.state.branch;
            if (branch === undefined) {
                throw new Error("Cannot use BranchAwareRedirect without a Branch loaded into ProjectContext");
            }

            const path = location.pathname;
            const projectIdPart = `/projects/${projectSlug}`;
            const insertionIndex = path.indexOf(projectIdPart) + projectIdPart.length;

            const prefix = path.slice(0, insertionIndex);
            const branchPart = `/branches/${getURISafeBranchName(branch)}`;
            const suffix = path.slice(insertionIndex);

            const pathWithBranch = [prefix, branchPart, suffix].join("");
            redirectTo = { ...location, pathname: pathWithBranch };
        }
    }

    return redirectTo ? <InternalRedirect from={location.pathname} to={redirectTo} push={false} /> : <>{props.children}</>;
};

const isConfigurationAsCodeForRunbooksEnabledSelector = (state: GlobalState) => state.configurationArea.features.isConfigurationAsCodeForRunbooksEnabled;

export const BranchAwareRedirect: React.FC<{ children: React.ReactNode }> = (props) => {
    const isConfigurationAsCodeForRunbooksEnabled = useSelector(isConfigurationAsCodeForRunbooksEnabledSelector);
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    return <BranchAwareRedirectInternal {...props} projectContext={projectContext} runbookContext={runbookContext} isConfigurationAsCodeForRunbooksEnabled={isConfigurationAsCodeForRunbooksEnabled} />;
};

export default BranchAwareRedirect;
