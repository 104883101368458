import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { TrackJS } from "trackjs";
import { client } from "~/clientInstance";
import BusyFromPromise from "~/components/BusyFromPromise";
import BusyIndicator from "~/components/BusyIndicator";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { resolveStringPathWithSpaceId } from "~/components/Navigation/resolvePathWithSpaceId";
import { ErrorPanel } from "~/components/form";
import useLocalStorage from "~/hooks/useLocalStorage";
import { MenuItemButton } from "~/primitiveComponents/navigation/MenuItems/MenuItemButton/MenuItemButton";
import { MenuList } from "~/primitiveComponents/navigation/MenuList/MenuList";
import { ShouldTrackErrors } from "~/utils/TrackJS";
import type { GlobalSearchResult } from "./GlobalSearchHelpers";
import { EmptyGlobalSearchResults, getRedirectUrl, IsPageSearchResult, NoKeywordGuidance, PageSearchResultListItem, SearchItemCount, SpaceSearchResultListItem, usePageSearch, useSpaceSearch } from "./GlobalSearchHelpers";
const styles = require("./GlobalSearch.less");

interface GlobalSearchListProps {
    items: GlobalSearchResult[];
    keyword: string;
    onClick: (item: GlobalSearchResult) => void;
    busy?: Promise<unknown> | boolean;
}

export interface GlobalSearchRef {
    focusFirstItem: () => void;
}

const GlobalSearchList = forwardRef<GlobalSearchRef, GlobalSearchListProps>(({ items, keyword, onClick, busy }, ref) => {
    const firstItemRef = useRef<HTMLButtonElement | null>(null);
    useImperativeHandle(ref, () => ({ focusFirstItem: () => firstItemRef.current?.focus() }));

    if (items.length === 0) return <EmptyGlobalSearchResults busy={busy} keyword={keyword} />;
    return (
        <div className={styles.listContainer}>
            <MenuList accessibleName={"Global search"}>
                {items.map((item, index) => {
                    if (IsPageSearchResult(item)) {
                        return (
                            <MenuItemButton
                                key={item.page.Id}
                                ref={index === 0 ? firstItemRef : undefined}
                                onClick={() => {
                                    const redirectTo = getRedirectUrl(item);
                                    if (typeof redirectTo === "string") {
                                        const goTo = `#${resolveStringPathWithSpaceId(redirectTo, client.spaceId ?? "")}`;
                                        window.location.href = goTo;
                                        onClick(item);
                                    }
                                }}
                            >
                                <PageSearchResultListItem result={item} />
                            </MenuItemButton>
                        );
                    }
                    return (
                        <MenuItemButton
                            key={item.Id}
                            ref={index === 0 ? firstItemRef : undefined}
                            onClick={() => {
                                const redirectTo = getRedirectUrl(item);
                                if (typeof redirectTo === "string") {
                                    const goTo = `#${resolveStringPathWithSpaceId(redirectTo, client.spaceId ?? "")}`;
                                    window.location.href = goTo;
                                    onClick(item);
                                } else if (ShouldTrackErrors()) {
                                    TrackJS.track(`Attempted to link search result of non-linkable document type for '${item.Id}' in global search.`);
                                }
                            }}
                        >
                            <SpaceSearchResultListItem result={item} keyword={keyword} />
                        </MenuItemButton>
                    );
                })}
            </MenuList>
        </div>
    );
});

interface GlobalSearchProps {
    keyword: string;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
    handleSelect?: () => void;
    handleClose: () => void;
}

type Props = GlobalSearchProps;

const GlobalSearchInternal = forwardRef<GlobalSearchRef, Props>(({ keyword, doBusyTask, busy, errors, handleSelect, handleClose }, ref) => {
    const pageSearchResults = usePageSearch(keyword);
    const spaceSearchResults = useSpaceSearch(keyword, doBusyTask);
    const [showServerResultsOnly, setShowServerResultsOnly] = useLocalStorage("GlobalSearch.showServerResultsOnly", false);

    const allResults = React.useMemo(() => {
        if (showServerResultsOnly) {
            return [...spaceSearchResults];
        }

        // We put page search results first because they are in-memory searches and won't cause
        // jumping-of-the-UI (like the spaceSearchResults will if they go first).
        return [...pageSearchResults, ...spaceSearchResults];
    }, [pageSearchResults, spaceSearchResults, showServerResultsOnly]);

    return (
        <div className={styles.root}>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} scrollToPanel={false} />}
            <BusyFromPromise promise={busy}>{(isBusy: boolean) => <BusyIndicator show={isBusy} inline={false} />}</BusyFromPromise>
            {keyword ? <SearchItemCount items={allResults} showServerResultsOnly={showServerResultsOnly} setShowServerResultsOnly={setShowServerResultsOnly} /> : <NoKeywordGuidance />}
            <div className={styles.tabContainer}>
                <GlobalSearchList
                    ref={ref}
                    keyword={keyword}
                    items={allResults}
                    onClick={() => {
                        if (handleSelect) {
                            handleSelect();
                        }
                        handleClose();
                    }}
                    busy={busy}
                />
            </div>
        </div>
    );
});

const GlobalSearch = GlobalSearchInternal;

export default GlobalSearch;
