import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { useAnalyticActionDispatch, Action as AnalyticsAction } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import ActionButton, { ActionButtonType } from "~/components/Button";
import DataBaseComponent from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { Section } from "~/components/Section/Section";

interface GitProps {
    dispatchAction: AnalyticActionDispatcher;
}

export class GitInternal extends DataBaseComponent<GitProps, {}> {
    constructor(props: GitProps) {
        super(props);
    }

    private onClearCacheClick = async () => {
        this.props.dispatchAction("Clear Local Cache", { resource: "Features", action: AnalyticsAction.Clear });
        await this.doBusyTask(async () => {
            await repository.ServerConfiguration.clearCache();
        });

        return;
    };

    render() {
        return (
            <PaperLayout title="Git Configuration">
                <Section>
                    <ActionButton type={ActionButtonType.Secondary} title="Clear Local Git Cache" label="Clear Local Cache" onClick={() => this.onClearCacheClick()} />
                </Section>
            </PaperLayout>
        );
    }
}

// Wrap the component and inject the analytic dispatch action.
export default function Git(props: {}) {
    const dispatchAction = useAnalyticActionDispatch();
    return <GitInternal dispatchAction={dispatchAction} />;
}
