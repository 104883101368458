import * as React from "react";
import NavigationButton, { NavigationButtonType } from "~/components/Button/NavigationButton";
import { AzureVm } from "~/components/Images/MachineSettings/AzureVm";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import routeLinks from "~/routeLinks";
import AzureCategory from "./AzureCategoryDefinition";
import { InfoRegistrationCards } from "./RegistrationCards";
import WindowsCategory from "./WindowsCategoryDefinition";
import type { BuiltInEndpointRegistration } from "./endpointRegistry";
import { CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
const styles = require("./styles.less");

const AzureVmEndpointDialogView: React.SFC<{}> = () => (
    <div>
        <h3>Installation Instructions</h3>
        <p>
            The <ExternalLink href="AzureVmExtensionArmDsc">Octopus Deploy DSC module</ExternalLink> can automatically download the latest Tentacle MSI, install it and register the agent with your Octopus Server.
        </p>
    </div>
);

const AzureVmAdditionalActions: React.SFC<{}> = () => (
    <NavigationButton
        key="deployment-targets"
        type={NavigationButtonType.Secondary}
        label="View Deployment Targets"
        href={routeLinks.infrastructure.machines.filtered({ commStyles: [CommunicationStyle.TentacleActive, CommunicationStyle.TentaclePassive] })}
    />
);

export const azureVmRegistration: BuiltInEndpointRegistration = {
    key: EndpointRegistrationKey.AzureVmExtension,
    displayOrder: 100,
    categories: [AzureCategory, WindowsCategory],
    name: "Azure VM",
    communicationStyle: CommunicationStyle.None,
    renderCard: InfoRegistrationCards.basic(
        () => <AzureVmEndpointDialogView />,
        ({ registration }) => ({
            logo: <AzureVm className={styles.centreThumbnail} title={registration.name} />,
            registrationName: registration.name,
            description: "Easily connect an Azure VM to the Octopus Server with a VM Extension.",
        }),
        () => <AzureVmAdditionalActions />
    ),
};
