import React, { useState, useEffect } from "react";
import IconButton from "~/components/IconButton";
import { Icon } from "~/components/IconButton/IconButton";
import Tooltip from "~/primitiveComponents/dataDisplay/ToolTip";
const styles = require("./style.less");

interface CopyToClipboardProps {
    value: string;
}

const CopyToClipboardButton: React.FC<CopyToClipboardProps> = ({ value }) => {
    const [event, setEvent] = useState<React.MouseEvent | null>(null);

    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (event) {
            setShowConfirmation(true);

            const timerId = window.setTimeout(() => setShowConfirmation(false), 3500);

            return () => {
                window.clearTimeout(timerId);
            };
        }
    }, [event]);

    const onClick = (e: React.MouseEvent) => {
        const copyText = async () => {
            await navigator.clipboard.writeText(value);

            setEvent(e);
        };

        copyText();
    };

    const outerTooltipTrigger = showConfirmation ? "manual" : undefined;

    const outerTooltipOpen = showConfirmation ? false : undefined;

    const innerTooltipOpen = showConfirmation ? true : false;

    return (
        <span>
            <Tooltip content="Copy to clipboard" trigger={outerTooltipTrigger} open={outerTooltipOpen}>
                <Tooltip content="Copied" trigger="manual" open={innerTooltipOpen}>
                    <span>
                        <IconButton onClick={onClick} className={styles.defaultIconButton} icon={Icon.CopyToClipboard} />
                    </span>
                </Tooltip>
            </Tooltip>
        </span>
    );
};

export default CopyToClipboardButton;
