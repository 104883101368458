import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useProjectContext } from "~/areas/projects/context";
import type { DeploymentSettingsResource } from "~/client/resources";
import type { RenderProps } from "~/components/Dialog/CustomDialog";
import CustomDialog from "~/components/Dialog/CustomDialog";
import DialogTrigger from "~/components/Dialog/DialogTrigger";
import type { ProjectRouteParams } from "../../ProjectsRoutes/ProjectRouteParams";
import OclEditorDialogLayout from "../OclEditorDialogLayout/OclEditorDialogLayout";

interface DeploymentSettingsOclEditorDialogTriggerProps {
    onSave: () => void;
    resource: DeploymentSettingsResource;
}

const DeploymentSettingsOclEditorDialogTrigger: React.FC<DeploymentSettingsOclEditorDialogTriggerProps> = ({ resource: deploymentProcess, onSave }) => {
    const projectContext = useProjectContext();
    const { projectContextRepository } = projectContext.state;
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<ProjectRouteParams>();
    return (
        <DialogTrigger
            render={(renderProps) => {
                renderProps.openDialog();
                return (
                    <CustomDialog
                        open={renderProps.open}
                        close={renderProps.closeDialog}
                        render={(dialogProps: RenderProps) => (
                            <OclEditorDialogLayout
                                {...dialogProps}
                                getOcl={async () => {
                                    const oclResource = await projectContextRepository.DeploymentSettings.getOcl(deploymentProcess);
                                    return oclResource.Ocl;
                                }}
                                modifyOcl={async (ocl: string) => {
                                    const oclResource = await projectContextRepository.DeploymentSettings.modifyOcl(deploymentProcess, { Ocl: ocl });
                                    return oclResource.Ocl;
                                }}
                                resource={deploymentProcess}
                                onSave={async () => {
                                    renderProps.closeDialog();
                                    onSave();
                                }}
                                onClose={() => {
                                    renderProps.closeDialog();
                                }}
                                history={history}
                                location={location}
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                match={match!}
                            />
                        )}
                    />
                );
            }}
        />
    );
};

export default DeploymentSettingsOclEditorDialogTrigger;
